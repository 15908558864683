/** библиотеки */
import React, {
  FC,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

import {
  Button,
  H2,
  H3,
  Icon,
  Icons,
  Loader,
  TagButton,
} from 'cordis-core-ui-planeta';

/** компоненты библиотеки */
import Announcement from './Announcement';
import { RemoveScroll } from 'react-remove-scroll';

/** стилевые компоненты */
import {
  StyledAnnouncements,
  StyledAnnouncementsHeader,
  StyledAnnouncementsList,
  StyledAnnouncementsTags,
  StyledWrapperAnnouncementsList,
} from './Announcements.style';

/* Утилиты */
import useWindowSize from '~/utils/useWindowSize';
import {
  getAnnouncementsByCountOnPage,
  getAnnouncementsOnPage,
  getAnnouncementsTagsByCity,
} from './Announcements.utils';

/** константы */
import {
  desktop1100,
  desktop1280,
  desktop940,
  blockWidth400,
  blockWidth340,
  blockWidth280,
} from '~/components/Grid/constants';
import { NEWS_SLUG } from '~/constants/common';
import {
  INIT_ANNOUNCEMENT_PAGE,
  DEFAULT_TAG,
  DEFAULT_RESULT_COUNT,
} from './Announcements.constants';

/** типы */
import {
  AnnouncementContentProps,
  AnnouncementsContentProps,
  AnnouncementsTags,
  AnnouncementsType,
  TagsFields,
} from './Announcements.types';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок "Агрегатор публикаций"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=491371345
 * @param content
 */
const Announcements: FC<AnnouncementsContentProps> = ({
  content,
}: AnnouncementsContentProps) => {
  const [width] = useWindowSize();
  const {
    cityStore: { city },
  } = useRootStore();
  const { announcements, tags, type } = content.fields;
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  // Массив тегов
  const [tagsArray, setTagsArray] = useState<TagsFields[]>([]);

  // Генерация массива тегов
  const getArrayTags = async () => {
    const newTags = [DEFAULT_TAG];
    const tagsByCity = await getAnnouncementsTagsByCity();
    if (tags && city.id in tagsByCity) {
      const filteredTags = Object.keys(tags)
        .filter((key) => tagsByCity[city.id].includes(tags[key]))
        .map((key) => {
          return {
            tag: key,
            hash: tags[key],
          };
        });

      setTagsArray(newTags.concat(filteredTags));
    }
  };

  // Ссылка на основной блок компонента
  const announcementsRef: RefObject<HTMLDivElement> | null = useRef(null);
  // Флаг отсутствия новостей для выбранного тега
  const isEmptyLoadingData: MutableRefObject<boolean> = useRef(false);
  // Ширина блока с анонсом
  const [blockWidth, setBlockWidth] = useState<number>(blockWidth400);
  // Прелоадер на начальной загрузке новостей
  const [isLoaderStart, setIsLoaderStart] = useState<boolean>(false);
  // Прелоадер блока 'Публикации'
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Прелоадер блока 'Последние публикации'
  const [isLoadingLast, setIsLoadingLast] = useState<boolean>(false);
  // Список с анонсами
  const [announcementsList, setAnnouncementsList] = useState<
    AnnouncementContentProps[]
  >([]);
  // Список с анонсами для блока 'Последние публикации'
  const [announcementsListLast, setAnnouncementsListLast] = useState<
    AnnouncementContentProps[]
  >([]);
  // Текущий номер страницы с анонсами
  const [page, setPage] = useState<number>(INIT_ANNOUNCEMENT_PAGE);
  // Попытаемся ли ещё загрузить анонсы
  const [isTryDataLoading, setIsTryDataLoading] = useState<boolean>(false);
  // Вид блока с тегами в мобильной версии
  const [isThemesOpen, setIsThemesOpen] = useState<boolean>(false);
  // Активный тег
  const [activeTag, setActiveTag] = useState<string>('');
  // Выбираемый, но не подтверждённый тег в мобильной версии
  const [candidateForActiveTag, setCandidateForActiveTag] = useState<string>(
    '',
  );
  // Последний блок новостей
  const [isFinished, setIsFinished] = useState<boolean>(false);

  // Объект router
  const router = useRouter();

  // Урл страницы
  const url = new URL(document.location.href);

  // Вычислит ширину блока с анонсом
  useEffect(() => {
    switch (true) {
      case width >= desktop1280:
        if (blockWidth !== blockWidth400) setBlockWidth(blockWidth400);
        break;
      case width >= desktop1100 && width <= desktop1280:
        if (blockWidth !== blockWidth340) setBlockWidth(blockWidth340);
        break;
      case width >= desktop940 && width <= desktop1100:
        if (blockWidth !== blockWidth280) setBlockWidth(blockWidth280);
        break;
      default:
        setBlockWidth(null);
    }

    if (type !== AnnouncementsType.FULL) return;
    if (width < desktop940 && isThemesOpen) setIsThemesOpen(false);
  }, [width]);

  /**
   * Загружает анонсы
   * @param isNew Перезаписать или дозагрузить
   */
  const getData = async (isNew = false) => {
    if (!activeTag) return;
    setIsLoading(true);
    let nextPage = isNew ? INIT_ANNOUNCEMENT_PAGE : page + 1;
    let res;
    if (isDesktop940 && isNew) {
      const announcementsByCountOnPage = await getAnnouncementsByCountOnPage(
        activeTag && activeTag !== AnnouncementsTags.ALL ? [activeTag] : [],
        city.id,
        DEFAULT_RESULT_COUNT,
      );

      nextPage++;
      setIsFinished(announcementsByCountOnPage.isFinished);
      res = announcementsByCountOnPage.announcements;
    } else {
      const announcementsOnPage = await getAnnouncementsOnPage(
        activeTag && activeTag !== AnnouncementsTags.ALL ? [activeTag] : [],
        city.id,
        nextPage,
      );
      setIsFinished(announcementsOnPage.isFinished);
      res = announcementsOnPage.announcements;
    }

    if (!res?.length) {
      isEmptyLoadingData.current = true;
      setIsLoaderStart(false);
      setIsLoading(false);
      return;
    }

    setIsLoaderStart(false);
    setIsLoading(false);
    setPage(nextPage);
    setAnnouncementsList(isNew ? [...res] : [...announcementsList, ...res]);
    setIsTryDataLoading(false);
  };

  // Получение анонсов для блока 'Последние публикации' при изменении города или активного тега
  const getAnnouncementsLast = async () => {
    setIsLoadingLast(true);
    const res = await getAnnouncementsOnPage(
      activeTag && activeTag !== AnnouncementsTags.ALL ? [activeTag] : [],
      city.id,
      null,
      url.pathname.slice(1),
    );
    setAnnouncementsListLast(res.announcements);
    setIsLoadingLast(false);
  };

  // Подняться к началу страницы
  const liftUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Дозагрузка анонсов при достижении конца блока анонсов
  const getAnnouncements = () => {
    if (type !== AnnouncementsType.FULL || !announcementsRef?.current) return;
    const offsetTop = announcementsRef.current.offsetTop - window.pageYOffset;
    const offset = Math.round(
      ((window.innerHeight - offsetTop) * 100) /
        announcementsRef.current.clientHeight,
    );
    if (offset >= 99) setIsTryDataLoading(true);
  };

  // Обновление тегов при изменении города
  useEffect(() => {
    getArrayTags();
  }, [city.id]);

  // Получение анонсов при изменении города или активного тега
  useEffect(() => {
    if (type !== AnnouncementsType.FULL) {
      getAnnouncementsLast();
      return;
    }
    isEmptyLoadingData.current = false;
    getData(true);
    setIsLoading(false);
    setIsFinished(false);
  }, [city.id, activeTag, url.pathname]);

  // Изменение выбираемого, но не подтверждённого тега в мобильной версии
  useEffect(() => {
    if (type !== AnnouncementsType.FULL) return;
    setCandidateForActiveTag(activeTag);
  }, [activeTag]);

  // Дозагрузка анонсов
  useEffect(() => {
    if (type !== AnnouncementsType.FULL || isEmptyLoadingData.current) return;
    if (isTryDataLoading && !isFinished) {
      getData();
    }
  }, [isTryDataLoading]);

  // Изменение активного тега при переходе на страницу тега
  useEffect(() => {
    if (type !== AnnouncementsType.FULL || !tagsArray.length) return;
    const urlTag = tagsArray.find((item) => `#${item.hash}` === url.hash);
    if (!urlTag) {
      router.push(`/${NEWS_SLUG}`, undefined, {
        shallow: true,
      });
      setActiveTag(AnnouncementsTags.NEWS);
      liftUp();
      return;
    }
    setActiveTag(urlTag.tag);
    liftUp();
  }, [tagsArray, url.hash]);

  useEffect(() => {
    /** При начальной загрузке страницы показывать прелоадер */
    setIsLoaderStart(true);

    window.addEventListener('scroll', getAnnouncements);
    if (type !== AnnouncementsType.FULL) {
      return (): void => {
        window.removeEventListener('scroll', getAnnouncements);
      };
    }

    return (): void => {
      window.removeEventListener('scroll', getAnnouncements);
    };
  }, []);

  /**
   * Меняет Url
   * @param value значение активного тега
   * @param tagUrl url активного тега
   */
  const changeURL = (value: string, tagUrl?: string) => {
    const isFullType = type === AnnouncementsType.FULL;
    const currentTag = isFullType
      ? tagsArray.find((item: TagsFields) => item.tag === value).hash
      : tagUrl;
    const valueHash = value === AnnouncementsTags.NEWS ? '' : `#${currentTag}`;
    router.push(`/${NEWS_SLUG}${valueHash}`, `/${NEWS_SLUG}${valueHash}`, {
      shallow: isFullType,
    });
  };

  /**
   * Меняет значение активного тега
   * @param item значение тега
   * @param tagUrl url тега
   */
  const changeActiveTag = (item: string, tagUrl?: string) => {
    if (type !== AnnouncementsType.FULL || activeTag !== item) {
      changeURL(item, tagUrl);
      setActiveTag(item);
      if (type === AnnouncementsType.FULL) liftUp();
    }
  };

  /**
   * Обрабатывает клик по тегу в блоке тегов
   * @param item значение тега
   */
  const handleTagClick = (item: string) => {
    isEmptyLoadingData.current = false;
    if (width < desktop940) {
      setCandidateForActiveTag(item);
      return;
    }

    changeActiveTag(item);
  };

  /**
   * Обрабатывает клик по тегу в карточке
   * @param tag значение тега
   * @param tagUrl url тега
   * @param event событие
   */
  const handleCardTagClick = (
    tag: string,
    tagUrl: string,
    event: React.MouseEvent<HTMLSpanElement>,
  ): void => {
    event.stopPropagation();
    changeActiveTag(tag.toLowerCase(), tagUrl);
  };

  // Обрабатывает клик по кнопке "Показать"
  const handleTagButtonClick = () => {
    changeActiveTag(candidateForActiveTag);
    setIsThemesOpen(false);
  };

  // Вернёт компоненты анонсов
  const getAnnouncementsList = (announcementsData): JSX.Element[] => {
    return announcementsData.map((item: AnnouncementContentProps) => (
      <li key={item.fullSlug}>
        <Announcement
          publishedDt={item.publishedDt}
          title={item.title}
          subTitle={item.subTitle}
          imgLink={item.imgLink}
          tag={item.tag}
          tagClick={handleCardTagClick}
          blockWidth={blockWidth}
          fullSlug={item.fullSlug}
          tagUrl={item.tagUrl}
        />
      </li>
    ));
  };

  // Отображает прелоадер
  const getAnnouncementsLoader = (): JSX.Element => {
    return (
      <div
        className={`announcements__loading-wrapper ${
          isLoaderStart && 'announcements__loadingStart-wrapper'
        }`}
      >
        <Loader />
      </div>
    );
  };

  // Возвращает блок с заголовком
  const renderTitleBox = () => {
    return (
      <>
        {width >= desktop940 ? (
          <H2>Публикации</H2>
        ) : (
          <>
            <div className="announcements__title-wrap">
              <H3>{isThemesOpen ? 'Темы публикаций' : 'Публикации'}</H3>
              <Icon
                className="announcements__title-icon"
                icon={isThemesOpen ? <Icons.CloseIcon /> : <Icons.ListIcon />}
                onClick={() => setIsThemesOpen(!isThemesOpen)}
                highlight
              />
            </div>
          </>
        )}
      </>
    );
  };

  // Возвращает блок с тегами
  const renderTag = (item: string) => {
    return (
      <div className="announcements__tag">
        <TagButton
          className="announcements__tag-button"
          onChange={() => handleTagClick(item)}
          checked={
            width < desktop940
              ? item === candidateForActiveTag
              : item === activeTag
          }
        >
          {item}
        </TagButton>
      </div>
    );
  };

  // Возвращает блок с заголовком и тегами
  const renderHeader = () => {
    return (
      <StyledAnnouncementsHeader isVisible={isThemesOpen}>
        <div className="announcements__header">
          {renderTitleBox()}
          {tags && (width >= desktop940 || isThemesOpen) && !isLoaderStart && (
            <>
              <StyledAnnouncementsTags>
                {tagsArray.map((item) => {
                  return (
                    <React.Fragment key={item.tag}>
                      {renderTag(item.tag)}
                    </React.Fragment>
                  );
                })}
              </StyledAnnouncementsTags>
              {width < desktop940 && (
                <div className="button-wrapper">
                  <Button onClick={handleTagButtonClick}>Показать</Button>
                </div>
              )}
            </>
          )}
        </div>
      </StyledAnnouncementsHeader>
    );
  };

  return (
    <RemoveScroll removeScrollBar={false} enabled={isLoading || isLoadingLast}>
      <StyledAnnouncements ref={announcementsRef}>
        {type === AnnouncementsType.FULL && announcementsList && (
          <>
            {renderHeader()}
            <StyledWrapperAnnouncementsList>
              <StyledAnnouncementsList>
                {isLoaderStart
                  ? getAnnouncementsLoader()
                  : getAnnouncementsList(announcementsList)}
                {isLoading && getAnnouncementsLoader()}
              </StyledAnnouncementsList>
            </StyledWrapperAnnouncementsList>
          </>
        )}
        {(announcementsListLast || announcements) &&
          type === AnnouncementsType.SHORT && (
            <>
              <H3 className="lastAnnouncementHeader">Последние публикации</H3>
              <StyledWrapperAnnouncementsList>
                <StyledAnnouncementsList
                  isShort={type === AnnouncementsType.SHORT}
                >
                  {isLoadingLast
                    ? getAnnouncementsLoader()
                    : getAnnouncementsList(
                        announcementsListLast || announcements,
                      )}
                </StyledAnnouncementsList>
              </StyledWrapperAnnouncementsList>
            </>
          )}
      </StyledAnnouncements>
    </RemoveScroll>
  );
};

export default observer(Announcements);
