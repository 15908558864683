/** libraries */
import { types, Instance, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { FieldsProps } from '../interfaces';
import { PromoInfoPropsModel } from '~/stores/models/ConnectionTariffModel';
import { Pab2cDevicesStoreInstance } from '~/stores/Pab2cDevicesStore';
import DevicePurchasesModel, {
  DevicePurchasesStoreInstance,
} from './DevicePurchasesStore';
import { ResultPropsModel } from '~/stores/models/Pab2cVoiceModel';
import DeviceLeasePayModel, {
  DeviceLeasePayStoreInstance,
} from './DeviceLeasePayStore';
import DetailedDeviceModel, {
  DetailedDeviceStoreInstance,
} from './DetailedDeviceStore';
/** constants */
import {
  DEFAULT_RESULT,
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
} from '../constants';
import {
  DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION,
  DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION,
  DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION,
  ERROR_MESSAGES,
} from '../../../Payment/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
/** api */
import { devicePurchase } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { IDeviceAfterAuthStore } from './DeviceAfterAuthStore';

const RequestsStateModel = types.model('State', {
  devicePurchase: createApiPathModel(
    'GET /Device/ContractDevice/DevicePurchase',
  ),
});

const BannerPropsModel = types.model({
  url: types.string,
  link: types.string,
  header: types.string,
  mainText: types.string,
  buttonText: types.string,
  buttonTextMobile: types.string,
});

export const ImageSizeDataModel = types.model({
  width: types.number,
  height: types.number,
  type: types.string,
  mime: types.string,
  wUnits: types.string,
  hUnits: types.string,
  length: types.number,
  url: types.string,
  id: types.maybe(types.number),
});

export const ManualsPropsModel = types.model({
  name: types.string,
  link: types.string,
});

export const ColorModel = types.model({
  name: types.string,
  hex: types.string,
  image: types.string,
});

export const DevicesFieldsModel = types.model({
  annuity: types.maybeNull(types.number),
  archive: types.maybe(types.boolean),
  availabilityDt: types.string,
  connectionMethod: types.string,
  content: types.maybeNull(types.string),
  downPayment: types.maybeNull(types.number),
  gigabit: types.maybeNull(types.boolean),
  id: types.number,
  imageSize: types.maybeNull(ImageSizeDataModel),
  imageSizeInWizard: types.maybeNull(ImageSizeDataModel),
  images: types.maybe(types.string),
  isUsed: types.boolean,
  itemCode: types.string,
  leasePeriod: types.maybeNull(types.number),
  name: types.string,
  ownershipOneTimeCharge: types.maybeNull(types.number),
  ownershipPriceOn: types.maybeNull(types.number),
  ownershipPriceOnPaymentPeriodCode: types.string,
  price: types.number,
  promoPriceInfos: types.maybeNull(types.array(PromoInfoPropsModel)),
  sort: types.maybe(types.number),
  statusCode: types.string,
  type: types.string,
  typeName: types.string,
  vendorName: types.string,
  warrantyForNew: types.number,
  footer: types.maybe(types.string),
  manuals: types.maybe(types.array(ManualsPropsModel)),
  tag: types.maybe(types.string),
  colors: types.maybe(types.array(ColorModel)),
  isNotAvailable: types.maybe(types.boolean),
});

const FieldsPropsModel = types.model({
  allDevices: types.array(DevicesFieldsModel),
  manual: types.maybe(types.string),
  banner: types.maybe(BannerPropsModel),
  text: types.maybe(types.string),
});

const DevicePurchaseResultModel = types.model({
  status: types.model({
    code: types.string,
    description: types.string,
  }),
});

const DevicesModel = types
  .model({
    requestsState: RequestsStateModel,
    detailedDeviceStore: DetailedDeviceModel,
    devicePurchasesStore: DevicePurchasesModel,
    deviceLeasePayStore: DeviceLeasePayModel,
    fields: FieldsPropsModel,
    size: types.number,
    /** Флаг чекбокса "Гарантия+" */
    isWarrantyPlusAgreement: types.boolean,
    /** Флаг отображения результатов и успешности операции */
    result: ResultPropsModel,
    /** Статус покупки оборудования */
    devicePurchaseResult: DevicePurchaseResultModel,
    /** Флаг чекбокса рассрочки или покупки */
    isMainAgreement: types.boolean,
  })
  .views((self) => ({
    get text() {
      return self.fields.text;
    },
    get banner() {
      return self.fields.banner;
    },
    get contractDevices() {
      if (!Pab2cDevicesStoreInstance.pab2cDevicesList?.length) return [];
      const deviceList = Pab2cDevicesStoreInstance.pab2cDevicesList.map(
        (item) => {
          const addFields = self.fields.allDevices?.find(
            (device) => device.id === item.deviceModelId,
          );
          return {
            ...item,
            ...addFields,
            annuity:
              item.ownership === OWNERSHIP_CODES.BOUGHT_LEASING
                ? item?.annuity
                : addFields?.annuity,
          };
        },
      );
      return toJS(deviceList);
    },
    get purchaseError() {
      return self.devicePurchaseResult.status.description;
    },
    get purchaseErrorCode() {
      return self.devicePurchaseResult.status.code;
    },
    get isLoading() {
      return self.requestsState.devicePurchase.isLoading;
    },
    get isPurchaseSucceeded() {
      return self.requestsState.devicePurchase.isSucceeded;
    },
  }))
  .actions((self) => ({
    setIsWarrantyPlusAgreement: (isSet: boolean) => {
      self.isWarrantyPlusAgreement = isSet;
    },
    setIsMainAgreement: (isChecked: boolean) => {
      self.isMainAgreement = isChecked;
    },
    /** Метод для выкупа или аренды оборудования */
    devicePurchase: flow(function* (
      setOpenSPAfterAuthorizationState,
      deviceAfterAuthStore: IDeviceAfterAuthStore,
      isLease = false,
    ) {
      self.requestsState.devicePurchase.reset();
      self.requestsState.devicePurchase.setLoading();
      try {
        yield devicePurchase(
          self.detailedDeviceStore.detailedDevice.sim,
          isLease,
          self.isWarrantyPlusAgreement,
        );
        self.result = {
          isResult: true,
          isCorrect: true,
          text: `Ура! Вы приобрели в собственность ${DEVICE_TYPES_GENITIVE[
            self.detailedDeviceStore.detailedDevice?.deviceTypeCode
          ]?.toLowerCase()}${'\u00A0'}${
            self.detailedDeviceStore.detailedDevice.modelName
          }`,
        };
        self.requestsState.devicePurchase.setSuccess();
      } catch (error) {
        console.error('devicePurchase', error);
        self.requestsState.devicePurchase.setFail();
        if (error.statusCode === 401) {
          deviceAfterAuthStore.setDevicePurchaseState(
            self.isWarrantyPlusAgreement,
            isLease,
          );
          deviceAfterAuthStore.setDeviceId(
            self.detailedDeviceStore.detailedDevice.id,
          );
          setOpenSPAfterAuthorizationState(SIDE_PAGES.DEVICES_ACTION);
          self.devicePurchasesStore.isDevicePurchasesWizardShow = false;
          return;
        }
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        let description = '';
        switch (err.Type) {
          case DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION:
            description = ERROR_MESSAGES.BLOCKED_BY_VACATION;
            break;
          case DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION:
            description = ERROR_MESSAGES.DEVICE_NO_PRICES;
            break;
          case DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION:
            description = ERROR_MESSAGES.CONTRACT_NOT_AVAILABLE_FUNDS;
            break;
          default:
            description = ERROR_MESSAGES.ERROR;
        }
        const devicePurchaseResultData = {
          status: {
            description,
            code: err.Type,
          },
        };
        self.devicePurchaseResult = devicePurchaseResultData;
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
      }
    }),
    resetState: () => {
      self.result = DEFAULT_RESULT;
      self.isWarrantyPlusAgreement = false;
      self.isMainAgreement = false;
      self.detailedDeviceStore.isDetailedDeviceShow = false;
      self.detailedDeviceStore.detailedDeviceData = null;
    },
  }));

const createStore = (fields: FieldsProps, size: number): IDevicesStore => {
  return DevicesModel.create({
    requestsState: {
      devicePurchase: defaultModelState,
    },
    detailedDeviceStore: DetailedDeviceStoreInstance,
    devicePurchasesStore: DevicePurchasesStoreInstance,
    deviceLeasePayStore: DeviceLeasePayStoreInstance,
    fields,
    size,
    isWarrantyPlusAgreement: false,
    result: DEFAULT_RESULT,
    devicePurchaseResult: { status: { description: '', code: '' } },
    isMainAgreement: false,
  });
};

export type IDevicesStore = Instance<typeof DevicesModel>;
export default createStore;
