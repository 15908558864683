/** библиотеки */
import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import {
  Consent,
  defaultTheme,
  LeadingText,
  Text,
  Icon,
  Icons,
  H2,
  H3,
} from 'cordis-core-ui-planeta';

/** стили */
import { DeviceCardWizardStyled, DeviceCardWizardResultStyled } from './style';

/** константы */
import {
  DEFAULT_ERROR,
  DEVICE_TYPES,
  INTERNAL_ERROR,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';
import { desktop1100 } from '~/components/Grid/constants';

/** api */
import { warrantyAgreementGet } from '~/api/apiPab2c';

/** stores */
import { useDevicesStore } from '../../stores/useDevicesStore';

/**
 * Блок pab2cDevices. SidePage получения оборудования в собственность
 */
const DeviceCardWizardPurchase: FC = () => {
  const {
    setIsWarrantyPlusAgreement,
    purchaseError,
    purchaseErrorCode,
    result,
    detailedDeviceStore: { detailedDevice: device },
  } = useDevicesStore();

  /** Вычисление ширины экрана */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Текст "Гарантия+" */
  const [warrantyText, setWarrantyText] = useState<string | null>(null);

  /** Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string | null>(
    purchaseError,
  );

  /** Доступна услуга "Гарантия+" */
  const isAvailable = device?.warrantyPlusInfo?.isAvailable;
  /** Заказана услуга "Гарантия+" */
  const isOrdered = device?.warrantyPlusInfo?.isOrdered;

  useEffect(() => {
    setErrorMessage(purchaseError);
    if (purchaseErrorCode === INTERNAL_ERROR) {
      setErrorMessage(DEFAULT_ERROR);
    }
  }, [purchaseErrorCode]);

  const showSerial = () => {
    return (
      <>
        <div className="device-card-wizard__block-inner">
          <Text color={defaultTheme.colors.shadow} lineHeight="24px">
            Серийный&nbsp;номер
          </Text>
          <div>
            <LeadingText color={defaultTheme.colors.black}>
              {device?.serial}
            </LeadingText>
          </div>
        </div>
      </>
    );
  };

  /** Устанавливает текст "Гарантия+" */
  useEffect(() => {
    if (isAvailable) {
      (async () => {
        try {
          const warrantyTextResult = await warrantyAgreementGet(device.sim);
          setWarrantyText(warrantyTextResult);
        } catch (e) {
          console.error('warrantyAgreementGet', e);
        }
      })();
    }
  }, []);

  /** Текст на экране результата */
  const resultText = `Ура! ${DEVICE_TYPES[device.deviceTypeCode]}${'\u00A0'}${
    device.modelName
  } теперь в собственности`;

  const message = () => {
    let text = '';
    if (!isOrdered && !isAvailable)
      text = `Внимание! После перехода оборудования в${'\u00A0'}собственность расходы на
    поддержание работоспособности оборудования Клиент несёт
    самостоятельно.`;
    if (isOrdered)
      text = `Внимание! На${'\u00A0'}оборудование подключена услуга «Гарантия+» и
    действует расширенное гарантийное обслуживание. В${'\u00A0'}случае
    отключения Услуги, расходы на${'\u00A0'}поддержание работоспособности
    оборудования Клиент несёт самостоятельно.`;
    if (!isOrdered && isAvailable) {
      text = `Внимание! После перехода оборудования в${'\u00A0'}собственность расходы на
      поддержание работоспособности оборудования Клиент несёт
      самостоятельно. Если необходимо гарантийное обслуживание –
      подключите услугу «Гарантия+» или продолжите пользование
      оборудованием на${'\u00A0'}текущих условиях.`;
    }
    return text ? (
      <div className="device-card-wizard__warning">{text}</div>
    ) : (
      <></>
    );
  };

  return (
    <DeviceCardWizardStyled>
      <div className="device-card-wizard">
        {result.isResult && result.isCorrect && (
          <>
            <DeviceCardWizardResultStyled>
              <div className="result-icon">
                <Icon icon={<Icons.SuccessIconAnimated />} />
              </div>
              {isDesktop1100 ? <H2>{resultText}</H2> : <H3>{resultText}</H3>}
            </DeviceCardWizardResultStyled>
          </>
        )}

        {result.isResult && !result.isCorrect && (
          <>
            <DeviceCardWizardResultStyled>
              <div className="result-icon">
                <Icon icon={<Icons.NotOkBigIcon />} />
              </div>
              {isDesktop1100 ? (
                <H2>{errorMessage}</H2>
              ) : (
                <H3>{errorMessage}</H3>
              )}
            </DeviceCardWizardResultStyled>
          </>
        )}

        {!result.isResult && (
          <>
            <div className="device-card-wizard__serial-block device-card-wizard__block">
              {showSerial()}
            </div>
            {message()}
            {!isOrdered && warrantyText && (
              <div className="device-card-wizard__agreement">
                <Consent
                  header="Подключить услугу &laquo;Гарантия+&raquo;"
                  text={warrantyText}
                  onChangeCheckbox={(e) =>
                    setIsWarrantyPlusAgreement(e.target.checked)
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </DeviceCardWizardStyled>
  );
};

export default observer(DeviceCardWizardPurchase);
