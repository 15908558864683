import React, { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import {
  TagButton,
  Icon,
  Icons,
  TagButtonIconPosition,
  H2,
  H3,
  Button,
  Loader,
} from 'cordis-core-ui-planeta';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { SOFT_SLUG } from '~/constants/common';
import { DEFAULT_TAG, CASHBACK_TAG, MY_SUBSCRIPTIONS_TAG } from './constants';
/** store */
import { useRootStore } from '~/stores/RootStore';
import { useSoftlineStore } from './stores/mainStore/useMainSoftlineStore';
/** utils */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import useWindowSize from '~/utils/useWindowSize';
/** components */
import SoftlineCard from './SoftlineCards/SoftlineCard';
/** styles */
import {
  StyledSoftlineHeader,
  StyledSoftlineTags,
  StyledSoftline,
  StyledSoftLineList,
} from './styles';
/** interfaces */
import { TagsFields, SoftlineFields, SoftlineType } from './types';
import useSoftlineStateModelStore from './stores/useSoftlineStateStore';

const SoftlineContent = () => {
  const {
    authStore: { isAuth, isLoadingAuth },
    softlineStore: {
      softlineSubscriptions,
      softlineListAuthClient,
      addAdditionalProperties,
    },
  } = useRootStore();
  const {
    fields,
    getSoftlineSubscriptionsExtendedFields,
    type,
    singleCardId,
    tags,
    enhancedFields,
  } = useSoftlineStore();
  const { simIdFromBind } = useSoftlineStateModelStore();

  /** Подписки с дополненными полями из монго */
  const extendedSoftlineSubscriptions = useMemo(() => {
    if (!softlineSubscriptions.length) return [];
    return getSoftlineSubscriptionsExtendedFields(
      softlineSubscriptions,
      addAdditionalProperties,
      simIdFromBind,
    );
  }, [softlineSubscriptions, fields]);

  /** Активные подписки с дополненными полями из монго */
  const extendedSoftlineListAuthClient = useMemo(() => {
    if (!softlineListAuthClient.length) return [];
    return getSoftlineSubscriptionsExtendedFields(
      softlineListAuthClient,
      addAdditionalProperties,
      simIdFromBind,
    );
  }, [softlineListAuthClient, fields]);

  /** Урл страницы */
  const url = new URL(document.location.href);
  /** Ширина сайта */
  const [width] = useWindowSize();
  const router = useRouter();
  /** Массив тегов */
  const [tagsArray, setTagsArray]: HooksTyping<TagsFields[]> = useState<
    TagsFields[]
  >([]);
  /** Активный тег */
  const [
    activeTag,
    setActiveTag,
  ]: HooksTyping<TagsFields> = useState<TagsFields>(DEFAULT_TAG);
  /** Выбираемый, но не подтверждённый тег в мобильной версии */
  const [
    candidateForActiveTag,
    setCandidateForActiveTag,
  ]: HooksTyping<TagsFields> = useState<TagsFields>(DEFAULT_TAG);
  /** Вид блока с тегами в мобильной версии */
  const [
    isThemesOpen,
    setIsThemesOpen,
  ]: HooksTyping<boolean> = useState<boolean>(false);

  /** Отфильтрованный по features список антивирусов */
  const [
    filteredByFeaturesSoftlineList,
    setFilteredByFeaturesSoftlineList,
  ]: HooksTyping<SoftlineFields[]> = useState<SoftlineFields[]>(null);

  /** Отфильтрованный по тегам список */
  const [
    filteredByTagsSoftlineList,
    setFilteredByTagsSoftlineList,
  ]: HooksTyping<SoftlineFields[]> = useState<SoftlineFields[]>(null);

  /** Карточка подписки для мини витрины */
  const [
    singleCardMiniShowcase,
    setSingleCardMiniShowcase,
  ]: HooksTyping<SoftlineFields> = useState<SoftlineFields>(null);

  /** Флаг загрузки */
  const [
    isLoadingList,
    setIsLoadingList,
  ]: HooksTyping<boolean> = useState<boolean>(true);

  /** Генерирует массив тегов */
  const generateTags = () => {
    let newTags = [];
    Object.keys(tags).forEach((key) => {
      newTags.push({
        value: tags[key].name.replace(/"/g, ''),
        tag: tags[key].tag,
        types: [...tags[key].types],
      });
    });
    const subscriptions = extendedSoftlineSubscriptions.length
      ? extendedSoftlineSubscriptions
      : enhancedFields;
    const ids = subscriptions.map((item) => item.metricId);
    newTags = newTags.filter((item) => {
      return (
        item.types.filter((code) => ids.includes(code)).length &&
        item.types.length
      );
    });
    const cashback = subscriptions.find(
      (item) => item.chatlingCashback?.cashbackPercent,
    );
    if (cashback) newTags.push(CASHBACK_TAG);
    else newTags.filter((item) => item !== CASHBACK_TAG);
    newTags.unshift(DEFAULT_TAG);
    if (extendedSoftlineListAuthClient.length)
      newTags.unshift(MY_SUBSCRIPTIONS_TAG);
    else newTags.filter((item) => item !== MY_SUBSCRIPTIONS_TAG);
    setTagsArray(newTags);
  };

  useEffect(() => {
    if (type === SoftlineType.SHORT) return;
    generateTags();
  }, [tags, extendedSoftlineListAuthClient, extendedSoftlineSubscriptions]);

  /**
   * Меняет Url
   * @param item текущий тег
   */
  const changeURL = (item: TagsFields): void => {
    const valueHash = item.tag === DEFAULT_TAG.tag ? '' : `#${item.tag}`;
    router.push(`/${SOFT_SLUG}${valueHash}`, `/${SOFT_SLUG}${valueHash}`, {
      shallow: true,
    });
  };

  /**
   * Меняет значение активного тега
   * @param item текущий тег
   */
  const changeActiveTag = (item: TagsFields): void => {
    if (item.tag === activeTag.tag) return;
    setActiveTag(item);
    changeURL(item);
  };

  /** Изменение активного тега при переходе на страницу тега */
  useEffect(() => {
    if (!tagsArray.length || type === SoftlineType.SHORT) return;
    const urlTag = tagsArray.find(
      (item: TagsFields) => `#${item.tag}` === url.hash,
    );
    if (!urlTag) return;
    setActiveTag(urlTag);
  }, [tagsArray, url.hash]);

  /**
   * Обрабатывает клик по тегу в блоке тегов
   * @param item текущий тег
   */
  const handleTagClick = (item: TagsFields) => {
    if (width < desktop940) {
      setCandidateForActiveTag(item);
      return;
    }
    changeActiveTag(item);
  };

  useEffect(() => {
    if (width < desktop940 && isThemesOpen) setIsThemesOpen(false);
  }, [width]);

  /** Изменение выбираемого, но не подтверждённого тега в мобильной версии */
  useEffect(() => {
    if (type === SoftlineType.SHORT) return;
    setCandidateForActiveTag(activeTag);
  }, [activeTag]);

  /** Обрабатывает клик по кнопке "Показать" */
  const handleTagButtonClick = () => {
    setIsThemesOpen(false);
    changeActiveTag(candidateForActiveTag);
  };

  /** Наполняет список подписок */
  const getSoftlineList = (list) => {
    /** Карточка подписки для мини витрины */
    if (type === SoftlineType.SHORT) {
      if (singleCardId) {
        setSingleCardMiniShowcase(
          list.find((item) => singleCardId === item.metricId),
        );
      }
      setIsLoadingList(false);
      return;
    }

    /** Витрина */
    const unconnectedList = list.filter((item) => !item.statusString);
    if (unconnectedList.length > 0)
      setFilteredByFeaturesSoftlineList(unconnectedList);

    setIsLoadingList(false);
  };

  useEffect(() => {
    if (extendedSoftlineSubscriptions && type !== SoftlineType.SHORT)
      getSoftlineList(extendedSoftlineSubscriptions);
  }, [extendedSoftlineSubscriptions]);

  useEffect(() => {
    if (!isAuth) {
      getSoftlineList(
        getSoftlineSubscriptionsExtendedFields(
          enhancedFields,
          addAdditionalProperties,
          simIdFromBind,
        ),
      );
      if (activeTag === MY_SUBSCRIPTIONS_TAG) changeActiveTag(DEFAULT_TAG);
    }
  }, [isAuth]);

  /** Фильтрация подписок */
  useEffect(() => {
    if (filteredByFeaturesSoftlineList) {
      if (activeTag.tag === DEFAULT_TAG.tag) {
        setFilteredByTagsSoftlineList(filteredByFeaturesSoftlineList);
        return;
      }

      // тег "Мои подписки"
      if (activeTag.tag === MY_SUBSCRIPTIONS_TAG.tag) {
        setFilteredByTagsSoftlineList(
          (extendedSoftlineListAuthClient as unknown) as SoftlineFields[],
        );
        return;
      }

      // тег "Cashback"
      if (activeTag.tag === CASHBACK_TAG.tag) {
        setFilteredByTagsSoftlineList(
          filteredByFeaturesSoftlineList.filter(
            (item) => item.chatlingCashback?.cashbackPercent,
          ),
        );
        return;
      }

      const newFilteredSoftline = filteredByFeaturesSoftlineList.filter(
        (item: SoftlineFields) => activeTag.types.includes(item.metricId),
      );
      setFilteredByTagsSoftlineList(newFilteredSoftline);
    }
  }, [
    activeTag,
    filteredByFeaturesSoftlineList,
    extendedSoftlineListAuthClient,
  ]);

  /** Возвращает блок с тегами */
  const renderTag = (item: TagsFields) => {
    const { value, tag } = item;

    return (
      <div className="softline__tag">
        <TagButton
          className="softline__tag-button"
          onChange={() => handleTagClick(item)}
          checked={
            width < desktop940
              ? tag === candidateForActiveTag.tag
              : tag === activeTag.tag
          }
          icon={
            tag === CASHBACK_TAG.tag &&
            width > desktop940 && <Icon icon={<Icons.SaleIcon />} />
          }
          iconPosition={
            tag === CASHBACK_TAG.tag &&
            width > desktop940 &&
            TagButtonIconPosition.LEFT
          }
        >
          {value}
        </TagButton>
      </div>
    );
  };

  /** Возвращает блок с заголовком */
  const renderTitleBox = () => {
    return (
      <>
        {width >= desktop940 ? (
          <H2 className="softline__title-desktop">Антивирусы</H2>
        ) : (
          <>
            <div className="softline__title-wrap">
              <H3>Антивирусы</H3>
              <Icon
                className="softline__title-icon"
                icon={isThemesOpen ? <Icons.CloseIcon /> : <Icons.ListIcon />}
                onClick={() => setIsThemesOpen(!isThemesOpen)}
                highlight
              />
            </div>
          </>
        )}
      </>
    );
  };

  /** Возвращает блок с заголовком и тегами */
  const renderHeader = () => {
    return (
      <StyledSoftlineHeader isVisible={isThemesOpen}>
        <div className="softline__header">
          {renderTitleBox()}
          {tagsArray.length > 0 && (width >= desktop940 || isThemesOpen) && (
            <>
              <StyledSoftlineTags>
                {tagsArray.map((item: TagsFields) => (
                  <React.Fragment key={item.tag}>
                    {renderTag(item)}
                  </React.Fragment>
                ))}
              </StyledSoftlineTags>
              {width < desktop940 && (
                <div className="button-wrapper">
                  <Button onClick={handleTagButtonClick}>Показать</Button>
                </div>
              )}
            </>
          )}
        </div>
      </StyledSoftlineHeader>
    );
  };
  return (
    <StyledSoftline type={type}>
      {type !== SoftlineType.SHORT && renderHeader()}
      {isLoadingList || isLoadingAuth ? (
        <div className="softline__loader">
          <Loader />
        </div>
      ) : (
        <>
          {filteredByTagsSoftlineList && type !== SoftlineType.SHORT && (
            <StyledSoftLineList>
              {filteredByTagsSoftlineList.map((item) => (
                <React.Fragment key={item.key}>
                  <SoftlineCard item={item} />
                </React.Fragment>
              ))}
            </StyledSoftLineList>
          )}
          {/* Мини витрина */}
          {singleCardMiniShowcase && type === SoftlineType.SHORT && (
            <StyledSoftLineList>
              <SoftlineCard item={singleCardMiniShowcase} />
            </StyledSoftLineList>
          )}
        </>
      )}
    </StyledSoftline>
  );
};

export default observer(SoftlineContent);
