/** libraries */
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import {
  Icon,
  Icons,
  Select,
  Text,
  defaultTheme,
  Loader,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledCardAndSbpPaymentSelect } from './styles';
/**  constants */
import { desktop940 } from '~/components/Grid/constants';
import {
  CARD_ICONS,
  ERROR_MESSAGES,
  NEW_CARD,
} from '../../Templates/Payment/constants';
import { NEW_ACCOUNT } from '../../Templates/PaymentResult/constants';
/** api */
import { unbindPaymentCard } from '~/api/api';
/** interfaces */
import { PaymentTypeSelectProps, SbpTypeSelect } from './interfaces';
/* utils */
import { chooseIconForSelect } from '~/components/Blocks/Shared/Shared.utils';
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { banksListImageConverter } from '../../Templates/Payment/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface CardAndSbpPaymentSelectProps {
  paymentSelect: PaymentTypeSelectProps | null;
  setPaymentSelect: Dispatch<SetStateAction<PaymentTypeSelectProps | null>>;
  isHideBindings?: boolean;
  className?: string;
  setError?: Dispatch<SetStateAction<string>>;
}

const CardAndSbpPaymentSelect: FC<CardAndSbpPaymentSelectProps> = ({
  paymentSelect,
  setPaymentSelect,
  isHideBindings,
  className,
  setError,
}: CardAndSbpPaymentSelectProps) => {
  const {
    paymentInfoStore: {
      paymentCards,
      getPaymentCards,
      sbpBindings,
      getSbpBindings,
      isCardsError,
      isSbpError,
      isLoading,
    },
    authStore: { isTemporaryTokenAuth, isAuth },
    sbpBanksStore: { sbpBanks, isSbpBanksLoading },
  } = useRootStore();

  const [sbpBindingsWithIcons, setSbpBindingsWithIcons]: HooksTyping<
    SbpTypeSelect[]
  > = useState<SbpTypeSelect[]>([]);

  /** id отвязываемого счёта */
  const [untieAccountId, setUntieAccountId] = useState<number>(null);
  /** Ошибка */
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<number>(null);

  const selectRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  // Вычисление ширины экрана
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  // Отвязывает и удаляет счёт
  const onDeleteAccountClick = async (
    id: number,
    onError: () => void,
    isCard?: boolean,
  ) => {
    setErrorMessage('');
    setError('');
    if (isTemporaryTokenAuth) {
      setError(ERROR_MESSAGES.NOT_AVAILABLE_TO_EMPLOYEE);
      return;
    }
    try {
      setShowLoader(id);
      const unbind = await unbindPaymentCard(id);
      if (unbind === 200) {
        if (isCard) {
          getPaymentCards();
        } else {
          getSbpBindings();
        }
        if (typeof setError === 'function') setError('');
      }
      setUntieAccountId(null);
      setShowLoader(null);
    } catch (error) {
      setUntieAccountId(null);
      setShowLoader(null);
      onError();
      if (typeof setError === 'function') {
        setError(
          isCard
            ? ERROR_MESSAGES.ERROR_UNBIND_CARD
            : ERROR_MESSAGES.ERROR_UNBIND_SBP,
        );
        return;
      }
      setErrorMessage(
        isCard
          ? ERROR_MESSAGES.ERROR_UNBIND_CARD
          : ERROR_MESSAGES.ERROR_UNBIND_SBP,
      );
    }
  };

  /** Обработка ошибки загрузки привязанных карт/счетов СБП */
  useEffect(() => {
    if (isCardsError) setErrorMessage(ERROR_MESSAGES.ERROR_LINKED_CARD);
    if (isSbpError) setErrorMessage(ERROR_MESSAGES.ERROR_LINKED_ACCOUNTS);
  }, [isCardsError, isSbpError]);

  /** Добавление иконок к привязанным СБП-счетам */
  const getSbpBindingsWithIcons = async () => {
    if (!sbpBanks.length || !sbpBindings.length) {
      setSbpBindingsWithIcons([]);
      return;
    }
    const data = sbpBindings.map((binding) => {
      const bankInfo = sbpBanks.find(
        (bank) => bank.bankName === binding.bankName,
      );
      return { ...binding, ...bankInfo };
    });
    const res = await banksListImageConverter(data);
    setSbpBindingsWithIcons(res);
  };

  useEffect(() => {
    getSbpBindingsWithIcons();
  }, [sbpBanks.length, sbpBindings.length]);

  const forceSelectClosing = () => {
    if (!selectRef?.current) return;
    selectRef.current.click();
    setUntieAccountId(null);
  };

  const getRightIcon = (item, isCard?: boolean) => {
    if (showLoader === item.id && untieAccountId) return <Loader small />;
    if (untieAccountId === item.id)
      return (
        <>
          <Icon
            icon={<Icons.OkIcon />}
            onClick={(e) => {
              onDeleteAccountClick(untieAccountId, forceSelectClosing, isCard);
              e.stopPropagation();
            }}
          />
          <Icon
            icon={<Icons.CancelPlanetaIcon />}
            onClick={(e) => {
              setUntieAccountId(null);
              e.stopPropagation();
            }}
          />
        </>
      );

    return (
      <Icon
        icon={<Icons.DeleteIcon />}
        onClick={(e) => {
          setUntieAccountId(item.id);
          e.stopPropagation();
        }}
      />
    );
  };

  /** Привязанные счета и другие способы оплаты */
  const accountsData: PaymentTypeSelectProps[] = useMemo(() => {
    const newSbpAccount = {
      label: NEW_ACCOUNT,
      value: 1,
      isCard: false,
      leftIcon: chooseIconForSelect(CARD_ICONS.SBP),
    };

    const newCardAccount = {
      label: NEW_CARD,
      value: 2,
      isCard: true,
      leftIcon: chooseIconForSelect(CARD_ICONS.NONAME_CARD),
    };

    if (!isAuth) return [newSbpAccount, newCardAccount];

    if (isLoading || isSbpBanksLoading) return [];

    const data = [];
    if (sbpBindingsWithIcons.length && !isHideBindings) {
      sbpBindingsWithIcons.forEach((item) => {
        data.push({
          label:
            untieAccountId === item.id
              ? `Отвязать СБП${'\u000A'}${item.bankName}?`
              : item.bankName,
          value: item.id,
          leftIcon: item.logo ? (
            <div>
              <Image
                src={`data:image/png;base64, ${item.logo}`}
                alt="logo"
                width={item.width}
                height={item.height}
                quality={100}
              />
            </div>
          ) : (
            <Icons.SbpIcon />
          ),
          rightIcon: getRightIcon(item),
          isCard: false,
        });
      });
    }

    /** Добавляем способ оплаты новым счётом СБП */
    data.push(newSbpAccount);

    /** Добавляем привязанные банковские карты */
    if (paymentCards.length && !isHideBindings) {
      const expiredText = isMinDesktop940
        ? 'Срок действия карты истёк'
        : 'Срок действия истёк';

      paymentCards.forEach((item) => {
        // const expired = isAfter(now, new Date(item.expirationDt));
        // https://ctms.itmh.ru/browse/DEPIT3-2855
        // @todo: вернуть логику когда всё в мире нормализуется
        const expired = false;
        data.push({
          label:
            selectRef?.current?.clientWidth > 300 && untieAccountId !== item.id
              ? `•••• •••• •••• ${item.maskedPan.substring(12)}`
              : `${
                  untieAccountId === item.id ? 'Отвязать карту ••' : ''
                }•• ${item.maskedPan.substring(12)}${
                  untieAccountId === item.id ? '?' : ''
                }`,
          value: item.id,
          leftIcon: chooseIconForSelect(item.paymentSystem),
          rightIcon: getRightIcon(item, true),
          bottomText: expired ? expiredText : null,
          className: expired ? 'expired' : '',
          isCard: true,
        });
      });
    }

    /** Добавляем способ оплаты новой банковской картой */
    data.push(newCardAccount);

    return data;
  }, [
    isLoading,
    isSbpBanksLoading,
    paymentCards,
    sbpBindingsWithIcons,
    untieAccountId,
    isHideBindings,
    isAuth,
  ]);

  /** Устанавливаем значение селекта по-умолчанию - СБП */
  useEffect(() => {
    setPaymentSelect(accountsData[0]);
  }, [
    paymentCards,
    sbpBindingsWithIcons,
    isHideBindings,
    isAuth,
    isLoading,
    isSbpBanksLoading,
  ]);
  return (
    <StyledCardAndSbpPaymentSelect ref={selectRef} className={className}>
      <Select
        className={className}
        placeholder="Ищем привязанные счета..."
        onOptionClick={(option) => {
          if (option.value !== untieAccountId)
            setPaymentSelect(option as PaymentTypeSelectProps);
        }}
        visibleOptionCount={4}
        value={
          paymentSelect ? ((paymentSelect.value as unknown) as string) : ''
        }
        data={accountsData}
        loading={isAuth && (isLoading || isSbpBanksLoading)}
        disabled={isLoading || isSbpBanksLoading}
      />
      {errorMessage && (
        <Text
          className="error"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
        >
          {errorMessage}
        </Text>
      )}
    </StyledCardAndSbpPaymentSelect>
  );
};

export default observer(CardAndSbpPaymentSelect);
