/** Библиотеки */
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
/** Компоненты */
import Feed from './index';
import FeedInformation from '../FeedInformation/FeedInformation';
import Header from '~/components/Header/Header';
import GlobalPreloader from '../GlobalPreloader';
import { BlocksRow } from './BlocksRow';
import { PreviewBanner } from '../PreviewBanner';
import AgreementPopup from '../Agreement/AgreementPopup';
import NotFound from './NotFound';
import { FooterProvider } from '../Footer/store/useFooterStore';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '../ConnectionWizard/store/useConnectionStore';
import useMakeAuthStore from '../AuthWizard/store/useMakeAuthStore';
/** Константы */
import { desktop940 } from '~/components/Grid/constants';
import {
  CONNECTION_TYPE,
  TYPE_OF_APPLICATION,
} from '../ConnectionWizard/constants';
import { BLOCK_NAMES } from '~/constants/common';
/** интерфейсы */
import { FeedPageProps } from './FeedPage.types';
/** SidePages */
import Autopayment from '~/components/Blocks/Shared/Autopayment';
import { SidePages } from './SidePages';
/** utils */
import { filterBlocks } from './utils';
import { scrollToBlockById } from '../Blocks/Shared/Shared.utils';
/** hooks */
import { useBlockDataFetching } from './useBlockDataFetching';

/** добавляет для typescript функцию создания чата в window */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SherlockChat: any;
  }
}

/** Страница ленты */
const FeedPage: React.FC<FeedPageProps> = ({
  feed,
  menu,
  preview,
}: FeedPageProps) => {
  const router = useRouter();
  const {
    cityStore: { city },
    pab2cDevicesStore: { pab2cDevicesList },
    authStore: { isAuth, isLoadingAuth },
    vacationStore: { isRemoveBlock },
  } = useRootStore();
  const {
    typeOfApplication,
    setTypeOfApplication,
    setIsVisible,
    setConnectionType,
  } = useConnectionStore();
  const {
    isVisible: isAuthOpen,
    serverExtensionsError,
    setServerExtensionsError,
    setServerError,
  } = useMakeAuthStore();

  /** Темплейты блоков */
  const blockTemplates = useMemo(() => {
    const menuBlocks = menu && menu?.blocks?.length ? menu?.blocks : [];
    return [...feed.blocks, ...menuBlocks].map((item) => item.template);
  }, [feed.blocks, menu.blocks]);

  useBlockDataFetching(blockTemplates);

  /** Блок "Баннер" */
  const isBannerBlock = useMemo(() => {
    return blockTemplates.includes(BLOCK_NAMES.banner);
  }, [blockTemplates]);
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  /** Заблокирован ли вход */
  const isBlockedPage = !!serverExtensionsError?.BlockMinutes;
  /** Урл страницы */
  const url = new URL(document.location.href);

  const filteredBlocks = useMemo(() => {
    return filterBlocks(
      feed.blocks,
      city,
      pab2cDevicesList,
      isLoadingAuth,
      isRemoveBlock,
      isBannerBlock,
      isDesktop940,
    );
  }, [city.id, feed, isDesktop940, pab2cDevicesList, isRemoveBlock]);

  /** Фильтрация блоков */
  const blocks =
    (feed.cities === null || feed.cities.includes(city.id)) && filteredBlocks;

  /** Скролл до блока с идентификатором */
  useEffect(() => {
    if (url.hash) {
      const hashes = url.hash.split('#');
      const existingBlock = hashes.find((item) => {
        return document.getElementById(`#${item}`);
      });
      if (existingBlock) scrollToBlockById(`#${existingBlock}`);
    }
  }, [url.hash]);

  const chatButton = document.getElementsByClassName(
    'sherlock-widget',
  )[0] as HTMLElement;

  /** Сайдпедж подключения с типом подключения */
  useEffect(() => {
    if (!router.isReady) return;
    if (!typeOfApplication)
      setTypeOfApplication(
        TYPE_OF_APPLICATION[
          Object.keys(TYPE_OF_APPLICATION).find((item) => {
            return router.query[item] !== undefined;
          })
        ],
      );
    if (router.query.openConnectionWizard) {
      setIsVisible(true);
      const hash = router.asPath.split('#')[1] || '';
      if (hash && isAuth) setConnectionType(CONNECTION_TYPE[hash]);
    }
    if (router.query.openChat && chatButton) {
      chatButton.click();
    }
  }, [router.isReady, router.query, chatButton]);

  /** Сбросить отображение СП блокировки, при повторном вызове визарда авторизации */
  useEffect(() => {
    if (!isBlockedPage) return;
    setServerExtensionsError(null);
    setServerError(null);
  }, [isAuthOpen]);

  return (
    <>
      {preview && <PreviewBanner />}
      <GlobalPreloader />
      <Header menu={menu} />
      <Feed>
        <div className="content">
          {feed.announcement && (
            <FeedInformation
              publishedDt={feed.publishedDt}
              announcementTag={feed.announcement.tag}
            />
          )}
          {blocks ? (
            <BlocksRow blocks={blocks} rowsColors={feed?.fields?.rowsColors} />
          ) : (
            <NotFound />
          )}
          <FooterProvider content={menu.footer} />
        </div>
        <Autopayment />
        <SidePages />
        <div className="agreement">
          <AgreementPopup />
        </div>
        <div id="chat" />
      </Feed>
    </>
  );
};

export default observer(FeedPage);
