import { createContext, useContext } from 'react';
import { defaultModelState } from '~/stores/models/createApiPathModel';
import ProductSwitcherWizardModel, {
  IProductSwitcherWizardStore,
} from './ProductSwitcherWizardStore';
import { DEFAULT_RESULT } from '../constants';

const store = ProductSwitcherWizardModel.create({
  requestsState: {
    getAgreement: defaultModelState,
    changeTariff: defaultModelState,
  },
  result: DEFAULT_RESULT,
  agreement: '',
  productChangeError: '',
});

const ProductSwitcherWizardStoreContext = createContext<IProductSwitcherWizardStore>(
  store,
);

const useProductSwitcherWizardStore = (): IProductSwitcherWizardStore => {
  return useContext(ProductSwitcherWizardStoreContext);
};

export default useProductSwitcherWizardStore;
