/* Библиотеки */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
/* Цвета */
import { defaultTheme } from 'cordis-core-ui-planeta';

/* Стилизованный блок с карточкой продукта */
export const StyledProductSelectorItem = styled.div<{
  isBusiness: boolean;
  withoutFilters?: boolean;
  stripColor?: string;
}>`
  ${({ isBusiness, withoutFilters, stripColor }) => {
    const getStripColor = () => {
      if (stripColor) return stripColor;
      if (isBusiness) return defaultTheme.colors.greyGraphite;
      return defaultTheme.colors.planeta;
    };
    return css`
      display: grid;
      grid-template-rows: 28px 25px 47px 30px 30px 64px 96px 72px 54px;
      width: 288px;
      height: 509px;
      margin: ${withoutFilters ? '0 16px 0 0' : '32px 16px 0 0'};
      padding: 20px 32px 32px;
      border-top: 12px solid ${getStripColor()};
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;
      border-right: 1px solid ${defaultTheme.colors.disable};
      border-bottom: 1px solid ${defaultTheme.colors.disable};
      border-left: 1px solid ${defaultTheme.colors.disable};

      .mobile-text {
        display: flex;
        align-items: center;
        > svg {
          height: 24px;
          margin-right: 8px;
        }
      }
      .sausages {
        margin-left: -16px;
      }
      .price {
        &__annual {
          display: flex;
        }
        > div[data-test-tabs] {
          height: 24px;
          span {
            line-height: 24px;
          }
        }
      }
      .action-discount {
        display: flex;
        flex-direction: column;
        margin: 12px 7px 0 0;
        > span {
          font-size: 11px;
          line-height: 12px;
          &:first-of-type {
            font-weight: 700;
          }
          &:last-of-type {
            font-weight: 400;
            text-decoration: line-through;
          }
        }
      }

      button {
        width: 224px;
        padding: 14px 38px;
      }

      h3 {
        margin: 0;
      }
    `;
  }};
`;

export const StyledTags = styled.span`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 20px;
  > div {
    margin-right: 8px;
    height: 20px;
    & > div {
      display: flex;
      > span {
        letter-spacing: -0.01em;
      }
    }
  }
`;
