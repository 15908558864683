/* eslint-disable camelcase */
/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Snoska, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** components */
import ToggleTabs from '../../common/ToggleTabs';
import OptionBlock from '../../common/OptionBlock';
import SidePageResult from '../../common/SidePageResult';

/** interfaces */
import { Channel } from '../../../interfaces';
import { ResultProps } from '../../common/SidePageResult/types';

/** styles */
import { ChannelConnectionWizardStyled } from './styles';
import { StyledFooter } from '../../../styles';

/** api */
import {
  bindITvChannelPack,
  getITvChannelPackBindAgreement,
  getITvChannelPackUnbindAgreement,
  unbindITvChannelPack,
} from '~/api/apiPab2c';

/** constants */
import { MOUNTHS } from '~/components/Blocks/Templates/MyStoriesContest/constants';

/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';

interface ChannelConnectionWizard {
  isConnection?: boolean;
  channel: Channel;
  result: ResultProps;
  setResult: Dispatch<SetStateAction<ResultProps>>;
}

/** Сайдпейдж подключения/отключения канала */
const ChannelConnectionWizard: FC<ChannelConnectionWizard> = ({
  isConnection,
  channel,
  result,
  setResult,
}: ChannelConnectionWizard) => {
  const {
    name,
    price,
    channelPackId,
    channelPackName,
    banTrimDate,
    singleChannelPacks,
  } = channel;
  const {
    summaryDataStore: { tariffId },
    tvStore: { updateChannelsAndPackages },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  /** Время отключения */
  const shutdownDate = useMemo(() => {
    const date = banTrimDate ? new Date(parseISO(banTrimDate)) : null;
    return date
      ? `${date.getDate().toString()} ${MOUNTHS[date.getMonth()]} в ${format(
          date,
          'HH:mm',
        )}`
      : '';
  }, [channel]);

  /** Jsx времени отключения */
  const shutdownTime = () => (
    <div className="channel-connection__shutdown-time">
      <OptionBlock title="Отключение произойдёт" body={shutdownDate} />
    </div>
  );

  /** disable кнопки */
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  /** Загрузка кнопки */
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(true);

  /** Соглашение */
  const [agreement, setAgreement] = useState<string>(null);

  /** Возвращает текст соглашения об услугах при подключении телеканала */
  const getBindAgreement = async () => {
    try {
      const res = await getITvChannelPackBindAgreement(
        channelPackId ?? singleChannelPacks[0].id,
      );
      setAgreement(res);
      setIsLoadingButton(false);
    } catch (e) {
      setIsLoadingButton(false);
      setIsDisableButton(true);
    }
  };

  /** Возвращает текст соглашения об услугах при отключении телеканала */
  const getUnbindAgreement = async () => {
    try {
      const res = await getITvChannelPackUnbindAgreement(
        channelPackId ?? singleChannelPacks[0].id,
      );
      setAgreement(res);
      setIsLoadingButton(false);
    } catch (e) {
      setIsLoadingButton(false);
      setIsDisableButton(true);
    }
  };

  useEffect(() => {
    if (isConnection) {
      getBindAgreement();
    } else {
      getUnbindAgreement();
    }
  }, [isConnection]);

  /** Прикрепляет телеканал к договору */
  const bindChannel = async () => {
    setIsLoadingButton(true);
    try {
      await bindITvChannelPack(channelPackId ?? singleChannelPacks[0].id);
      updateChannelsAndPackages(tariffId);
      setResult({
        isResult: true,
        isCorrect: true,
        title: `Телеканал ${channelPackName ?? name} подключён`,
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'}так...`,
      });
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Открепляет телеканал от договора */
  const unbindChannel = async () => {
    setIsLoadingButton(true);
    try {
      await unbindITvChannelPack(channelPackId ?? singleChannelPacks[0].id);
      updateChannelsAndPackages(tariffId);
      setResult({
        isResult: true,
        isCorrect: true,
        title: `Телеканал ${channelPackName ?? name} будет отключён`,
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'}так...`,
      });
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Событие подключения/отключения канала */
  const connectChannel = () => {
    if (isConnection) {
      bindChannel();
    } else {
      unbindChannel();
    }
  };

  return (
    <ChannelConnectionWizardStyled>
      {!result.isResult ? (
        <>
          {isConnection ? (
            <>
              <div className="channel-connection__wrapper">
                <div className="channel-connection__wrapper__toggle-tabs">
                  <ToggleTabs cost={price ?? singleChannelPacks[0].price} />
                </div>
              </div>
              <div className="channel-connection__agreement">
                <Text lineHeight="24px" fontWeightBold>
                  Согласие на&nbsp;подключение телеканала «
                  {name ?? channelPackName}»
                </Text>
                <Text lineHeight="24px">{parseHtml(agreement)}</Text>
              </div>
              <StyledFooter>
                <Button
                  onClick={connectChannel}
                  disabled={isDisableButton || isTemporaryTokenAuth}
                  loading={isLoadingButton}
                >
                  Подключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            </>
          ) : (
            <>
              {shutdownTime()}
              <div className="channel-connection__agreement">
                <Text lineHeight="24px" fontWeightBold>
                  Согласие на&nbsp;отключение телеканала «
                  {name ?? channelPackName}»
                </Text>
                <Text lineHeight="24px">{parseHtml(agreement)}</Text>
              </div>
              <StyledFooter>
                <Button
                  onClick={connectChannel}
                  disabled={isDisableButton || isTemporaryTokenAuth}
                  loading={isLoadingButton}
                >
                  Отключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            </>
          )}
        </>
      ) : (
        <SidePageResult result={result}>
          {isConnection && result.isCorrect ? (
            <Text lineHeight="24px">
              Канал доступен к&nbsp;просмотру на&nbsp;компьютере
              и&nbsp;телеприставке.
            </Text>
          ) : (
            result.isCorrect && shutdownTime()
          )}
        </SidePageResult>
      )}
    </ChannelConnectionWizardStyled>
  );
};

export default observer(ChannelConnectionWizard);
