/** libraries */
import { flow, getParent, types } from 'mobx-state-tree';
/** api */
import { checkLeasePay, leasePay } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { formatNumber } from '~/utils/utils';
/** constants */
import {
  DEFAULT_RESULT,
  DEVICE_TYPES_GENITIVE,
  initialLeasingPayment,
} from '../constants';
import { ERROR_MESSAGES, EXCEPTIONS } from '../../../Payment/constants';
/** interfaces */
import { IDevicesStore } from './DevicesStore';
import { ContractDevice, LeasePayInputParams } from '../interfaces';
import { ResultPropsModel } from '~/stores/models/Pab2cVoiceModel';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';

const RequestsStateModel = types.model('State', {
  leasePay: createApiPathModel('GET /Device/ContractDevice/LeasePay'),
  checkLeasePay: createApiPathModel('GET /Device/ContractDevice/CheckLeasePay'),
});

const LeasePayInputParamsModel = types.model({
  value: types.string,
  forSend: types.number,
});

const DevicePurchaseResultModel = types.model({
  status: types.model({
    code: types.string,
    description: types.string,
  }),
});

const DeviceLeasePayModel = types
  .model({
    requestsState: RequestsStateModel,
    /** Флаг сайдпейджа погашения рассрочки */
    isDeviceLeasePayWizardShow: types.boolean,
    /** Сумма пополнения в счёт выкупа оборудования */
    leasePayAmount: LeasePayInputParamsModel,
    /** Флаг отображения результатов и успешности операции погашения рассрочки */
    resultLeasePay: ResultPropsModel,
    /** Статус аренды оборудования */
    deviceLeasePayResult: DevicePurchaseResultModel,
    /** Ошибка ввода суммы при погашении рассрочки */
    isErrorAmount: types.boolean,
    /** Флаг возможности погашения рассрочки */
    canLeasePay: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.leasePay.isLoading;
    },
    get leaseError() {
      return self.deviceLeasePayResult.status.description;
    },
    get leaseErrorCode() {
      return self.deviceLeasePayResult.status.code;
    },
    get leasePurchaseIsSucceeded() {
      return self.requestsState.leasePay.isSucceeded;
    },
  }))
  .actions((self) => ({
    setIsDeviceLeasePayWizardShow: (isShow: boolean) => {
      self.isDeviceLeasePayWizardShow = isShow;
    },
    setLeasePayAmount: (amount: LeasePayInputParams) => {
      self.leasePayAmount = amount;
    },
    setIsErrorAmount: (isError: boolean) => {
      self.isErrorAmount = isError;
    },
    /** Проверка возможности оформления рассрочки на оборудование */
    leasePay: flow(function* () {
      self.requestsState.leasePay.reset();
      self.requestsState.leasePay.setLoading();
      const {
        detailedDeviceStore: { detailedDevice },
      }: IDevicesStore = getParent(self);
      const { sim }: ContractDevice = detailedDevice;
      try {
        yield leasePay(sim, self.leasePayAmount.forSend);
        self.resultLeasePay = {
          isResult: true,
          isCorrect: true,
          text:
            self.leasePayAmount.forSend === detailedDevice.leaseSaldo
              ? `Ура! Вы погасили рассрочку на ${DEVICE_TYPES_GENITIVE[
                  detailedDevice?.deviceTypeCode
                ]?.toLowerCase()}${'\u00A0'}${detailedDevice.modelName}`
              : `Ура! Вы погасили часть рассрочки на ${formatNumber(
                  self.leasePayAmount.forSend,
                )} ₽`,
        };
        self.requestsState.leasePay.setSuccess();
      } catch (error) {
        self.requestsState.leasePay.setFail();
        console.error('leasePay', error);
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        const deviceLeasePayResultData = {
          status: {
            description: EXCEPTIONS[err.Type] ?? ERROR_MESSAGES.ERROR,
            code: err.Type,
          },
        };
        self.deviceLeasePayResult = deviceLeasePayResultData;
        self.resultLeasePay = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
      }
    }),
    /** Проверка возможности погасить рассрочку */
    checkLeasePay: flow(function* (
      setOpenSPAfterAuthorizationState,
      setDeviceId,
    ) {
      self.requestsState.checkLeasePay.reset();
      self.requestsState.checkLeasePay.setLoading();
      try {
        const res = yield checkLeasePay();
        self.canLeasePay = res;
        self.requestsState.checkLeasePay.setSuccess();
      } catch (error) {
        console.error('checkLeasePay', error);
        if (error.statusCode === 401) {
          const {
            detailedDeviceStore: { detailedDevice },
          }: IDevicesStore = getParent(self);
          setDeviceId(detailedDevice.id);
          setOpenSPAfterAuthorizationState(SIDE_PAGES.DEVICES);
        }
        self.requestsState.checkLeasePay.setFail();
      }
    }),
    resetLeasePayState: () => {
      self.resultLeasePay = DEFAULT_RESULT;
      self.leasePayAmount = initialLeasingPayment;
      self.isDeviceLeasePayWizardShow = false;
    },
  }));

export const DeviceLeasePayStoreInstance = {
  requestsState: {
    leasePay: defaultModelState,
    checkLeasePay: defaultModelState,
  },
  isDeviceLeasePayWizardShow: false,
  leasePayAmount: initialLeasingPayment,
  resultLeasePay: DEFAULT_RESULT,
  deviceLeasePayResult: { status: { description: '', code: '' } },
  isErrorAmount: false,
  canLeasePay: null,
};

export default DeviceLeasePayModel;
