/** libraries */
import { Provider, MobXProviderContext, useLocalObservable } from 'mobx-react';
import { useContext } from 'react';
/** components */
import { IFooterStore, createStore } from './FooterModel';
import Footer from '../Footer';
/** interfaces */
import { FooterInfo } from '~/components/Feed/FeedPage.types';

interface FooterProviderProps {
  content: FooterInfo;
}

export const FooterProvider = ({ content }: FooterProviderProps) => {
  const store = useLocalObservable(() => createStore(content));
  return (
    <Provider footerStore={store}>
      <Footer />
    </Provider>
  );
};

const useFooterStore = (): IFooterStore => {
  const { footerStore } = useContext(MobXProviderContext);
  return footerStore;
};

export default useFooterStore;
