import { BLOCK_NAMES } from '~/constants/common';
import { Block } from '~/interfaces/BlockInterface';
import { DISPLAY_OPTIONS } from '../Blocks/Templates/Banner/Banner.types';
import { City } from '~/interfaces/CityInterface';
import { Pab2cDevice } from '../Blocks/Templates/Pab2c/Devices/interfaces';

/** Фильтрует блоки для текущего города */
export const filterBlocks = (
  blockItem: Block[],
  city: City,
  pab2cDevices: Pab2cDevice[],
  isLoadingData: boolean,
  isRemoveBlock: boolean,
  isBannerBlock: boolean,
  isDesktop940: boolean,
): Block[] => {
  // Во избежание мутации, производим фильтрацию добавляя результаты в аккумулятор
  let blocks = blockItem?.reduce((acc: Block[], element: Block): Block[] => {
    // Скопируем значение поступившего элемента, тем самым избавившись от ссылки на данный объект
    const currentElement = { ...element };
    if (currentElement.blocks)
      currentElement.blocks = filterBlocks(
        currentElement.blocks,
        city,
        pab2cDevices,
        isLoadingData,
        isRemoveBlock,
        isBannerBlock,
        isDesktop940,
      );
    if (
      // eslint-disable-next-line no-prototype-builtins
      !currentElement.fields?.hasOwnProperty('cities') ||
      currentElement.fields?.cities.includes(city.id)
    ) {
      acc.push(currentElement);
    }
    return acc;
  }, []);

  const removeBlocks: { template: string; size?: number }[] = [];

  /** Убирает блок "Оборудование", если устройства отсутствуют */
  if (!pab2cDevices.length || isLoadingData)
    removeBlocks.push({ template: BLOCK_NAMES.pab2cDevices, size: 3 });

  /** Убирает блок "Каникулы", если в контексте нет vacationInfo или услуга недоступна  */
  if (isRemoveBlock) removeBlocks.push({ template: BLOCK_NAMES.vacation });

  blocks = blocks.filter((block) => {
    return !removeBlocks.find(
      (rBlock) =>
        rBlock.template === block.template &&
        (!rBlock.size || rBlock.size === block.size),
    );
  });

  /** Видимость блока "Баннер" в зависимости от флага platform */
  if (isBannerBlock) {
    blocks = blocks.filter((item) => {
      if (
        item.template === BLOCK_NAMES.banner &&
        ((item.fields?.platform === DISPLAY_OPTIONS.DESKTOP && !isDesktop940) ||
          (item.fields?.platform === DISPLAY_OPTIONS.MOBILE && isDesktop940))
      )
        return item.template !== BLOCK_NAMES.banner;
      return item;
    });
  }

  return blocks;
};
