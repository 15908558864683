/** libraries */
import React, { useState, ReactNode, useContext, useEffect } from 'react';
import { Provider, MobXProviderContext } from 'mobx-react';
/** interfaces */
import { MyTVFields } from '../interfaces';
/** constants */
import { STORE_TYPE } from '../constants';
/** components */
import createStore, { IMyTVModelStore } from './MyTvStore';
import useMyTVX3Store from './MyTvX3Store';

interface MyTvProviderProps {
  children: ReactNode;
  fields: MyTVFields;
  storeType: STORE_TYPE;
}

export const MyTvProvider = ({
  children,
  fields,
  storeType,
}: MyTvProviderProps): JSX.Element => {
  const { resetMyTvX3Store } = useMyTVX3Store();

  const [store] = useState<IMyTVModelStore>(() =>
    createStore(fields, storeType),
  );

  if (storeType === STORE_TYPE.MY_TV_X3) {
    resetMyTvX3Store();
  }

  useEffect(() => {
    store.setStoreType(storeType);
  }, [storeType]);

  return <Provider MyTvStore={store}>{children}</Provider>;
};

export const useMyTvStore = (): IMyTVModelStore => {
  const { MyTvStore } = useContext(MobXProviderContext);
  return MyTvStore;
};
