/** библиотеки */
import React, { FC, useEffect, useState } from 'react';
import { add, format, isAfter, isBefore } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import {
  Consent,
  defaultTheme,
  LeadingText,
  Text,
  Icon,
  Icons,
} from 'cordis-core-ui-planeta';
import DevicePriceTabs from '~/components/Blocks/Templates/Pab2c/Devices/Components/DevicePriceTabs';

/** стили */
import {
  WarrantyPlusWizardResultStyled,
  WarrantyPlusWizardStyled,
} from './style';

/** константы */
import { WARRANTY_WIZARD_STATES } from '~/components/Blocks/Templates/Pab2c/Devices/Components/WarrantyPlusWizard/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';

/** типы */
import { WarrantyPlusWizardProps } from './types';

/** api */
import { warrantyAgreementGet } from '~/api/apiPab2c';

/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useDeviceAfterAuthStore from '../../stores/useDeviceAfterAuthStore';

/**
 * Блок pab2cDevices. SidePage включения-отключения Гарантия+
 */
const WarrantyPlusWizard: FC<WarrantyPlusWizardProps> = ({
  device,
  warrantyPlusResult,
  handleAgreementState,
  viewState,
  setIsWarrantyPlusWizardShow,
}: WarrantyPlusWizardProps) => {
  const { setOpenSPAfterAuthorizationState } = useMakeAuthStore();
  const { setDeviceId } = useDeviceAfterAuthStore();
  /** Текст соглашения */
  const [agreementText, setAgreementText] = useState<string>(null);

  const showSerial = () => {
    return (
      <>
        <div className="warranty-plus-wizard__block-inner">
          <Text color={defaultTheme.colors.shadow} lineHeight="24px">
            Серийный&nbsp;номер
          </Text>
          <div>
            <LeadingText color={defaultTheme.colors.black}>
              {device?.serial}
            </LeadingText>
          </div>
        </div>
      </>
    );
  };

  const showPrice = () => {
    return (
      <>
        <div className="device-card-wizard__block-inner">
          <DevicePriceTabs cost={device.warrantyPlusInfo.price} />
        </div>
      </>
    );
  };

  const dateFormat = (
    options = {},
    date: string = null,
    formatString = 'd MMMM Y года',
  ): string => {
    return format(
      add(date ? new Date(date) : new Date(), options),
      formatString,
      {
        locale: ru,
      },
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const warrantyTextResult = await warrantyAgreementGet(device.sim);
        setAgreementText(warrantyTextResult);
      } catch (e) {
        if (e.statusCode === 401) {
          setDeviceId(device.id);
          setOpenSPAfterAuthorizationState(SIDE_PAGES.WARRANTY_AGREEMENT);
          setIsWarrantyPlusWizardShow(false);
        }
        console.error('warrantyAgreementGet', e);
      }
    })();
  }, []);

  return (
    <WarrantyPlusWizardStyled>
      <div className="warranty-plus-wizard">
        {viewState === WARRANTY_WIZARD_STATES.ERROR && warrantyPlusResult && (
          <>
            <WarrantyPlusWizardResultStyled>
              <div className="error-icon">
                <Icon icon={<Icons.NotOkBigIcon />} />
              </div>
              <h2>{warrantyPlusResult?.status?.description}</h2>
            </WarrantyPlusWizardResultStyled>
          </>
        )}

        {(viewState === WARRANTY_WIZARD_STATES.SUCCESS_ON ||
          viewState === WARRANTY_WIZARD_STATES.SUCCESS_OFF) && (
          <>
            <WarrantyPlusWizardResultStyled>
              <div className="success-icon">
                <Icon icon={<Icons.SuccessIconAnimated />} />
              </div>
              <h2>
                Услуга Гарантия+{' '}
                {viewState === WARRANTY_WIZARD_STATES.SUCCESS_OFF
                  ? 'отключена'
                  : 'подключена'}
                !
              </h2>
            </WarrantyPlusWizardResultStyled>
          </>
        )}

        {viewState === WARRANTY_WIZARD_STATES.ON && (
          <>
            <div className="warranty-plus-wizard__serial-block warranty-plus-wizard__block">
              {device && showSerial()}
            </div>
            <div className="warranty-plus-wizard__price-block warranty-plus-wizard__block">
              {device && showPrice()}
            </div>

            <div className="consent">
              <Consent
                header="Я ознакомлен(а) и согласен(на) с условиями предоставления и оплаты услуги «Гарантия+» "
                text={agreementText}
                onChangeCheckbox={handleAgreementState}
              />
            </div>
          </>
        )}
        {viewState === WARRANTY_WIZARD_STATES.OFF && (
          <>
            <div className="warranty-plus-wizard__serial-block warranty-plus-wizard__block">
              {device && showSerial()}
            </div>
            {isAfter(
              new Date(device.warrantyPlusInfo?.banTrimDate),
              new Date(),
            ) && (
              <div className="warranty-plus-wizard__warning">
                Отключить услугу «Гарантия+» для данного устройства можно после{' '}
                {dateFormat({}, device.warrantyPlusInfo.banTrimDate)}.
              </div>
            )}

            {isBefore(
              new Date(device.warrantyPlusInfo?.banTrimDate),
              new Date(),
            ) && (
              <div className="consent">
                <Consent
                  header="Я подтверждаю отключение услуги «Гарантия+»"
                  text={agreementText}
                  onChangeCheckbox={handleAgreementState}
                />
              </div>
            )}
          </>
        )}
      </div>
    </WarrantyPlusWizardStyled>
  );
};

export default observer(WarrantyPlusWizard);
