/** библиотеки */
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** стили */
import { StyledDevicePurchasesWizard } from './style';
/** компоненты библиотеки */
import {
  Text,
  defaultTheme,
  LeadingText,
  PriceTag,
  Consent,
  Button,
  PriceTagBackgroundColor,
  ButtonStyleTypes,
  Icon,
  Icons,
  H2,
  H3,
} from 'cordis-core-ui-planeta';
/** интерфейсы */
import { ContractDevice } from '../../interfaces';
/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';
/** константы */
import { desktop1100 } from '~/components/Grid/constants';
import {
  DEFAULT_ERROR,
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
} from '../../constants';
import { DEVICE_PURCHASE_EXCEPTION } from '~/components/Blocks/Templates/Payment/constants';
import { DAYS } from '~/components/Blocks/Templates/Pab2c/Devices/Components/DeviceCardWizard/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
/** утилиты */
import { formatNumber, pluralizeAll, removeLeadingZero } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** api */
import { warrantyAgreementGet } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useDevicesStore } from '../../stores/useDevicesStore';
import { PLANETA_URL } from '~/constants/common';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useDeviceAfterAuthStore from '../../stores/useDeviceAfterAuthStore';

/**
 * Блок pab2cDevices. Компонент сайдпейджа покупки оборудования за полную стоимость/в рассрочку
 */
const DevicePurchasesWizard: FC = () => {
  const {
    authStore: { auth },
  } = useRootStore();
  const {
    setIsWarrantyPlusAgreement,
    result,
    purchaseError,
    purchaseErrorCode,
    setIsMainAgreement,
    detailedDeviceStore: { detailedDevice: device, deviceOwnership },
    devicePurchasesStore: {
      isLease,
      devicePurchaseCheckResult,
      deviceLeasePurchaseCheckResult,
      setIsDevicePurchasesWizardShow,
    },
  } = useDevicesStore();
  const { setOpenSPAfterAuthorizationState } = useMakeAuthStore();
  const { setDeviceId } = useDeviceAfterAuthStore();

  /** Вычисление ширины экрана */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Разница между балансом и ценой оборудования */
  const differenceBetweenBalanceAndLeasePrice =
    auth.balance - (deviceLeasePurchaseCheckResult?.leaseCredit ?? 0);

  /** Разница между балансом и ценой оборудования */
  const differenceBetweenBalanceAndPrice = auth.balance - device?.purchasePrice;

  /** Баланс больше чем цена оборудования */
  const isBalanceMoreThanPrice = isLease
    ? differenceBetweenBalanceAndLeasePrice >= 0
    : differenceBetweenBalanceAndPrice >= 0;

  /** Доступна услуга "Гарантия+" */
  const isAvailable = device?.warrantyPlusInfo?.isAvailable;

  /** Подключена услуга "Гарантия+" */
  const isOrdered = device?.warrantyPlusInfo?.isOrdered;

  /** Текстовое сообщение с предупреждением  */
  const warningText = () => {
    if (isAvailable && !isOrdered)
      return `Внимание! После перехода оборудования в${'\u00A0'}собственность расходы на${'\u00A0'}поддержание работоспособности оборудования Клиент несёт самостоятельно. Если необходимо гарантийное обслуживание${'\u00A0'}${'\u2010'} подключите услугу «Гарантия+» или продолжите пользование оборудованием на${'\u00A0'}текущих условиях.`;
    if (isOrdered)
      return `Внимание! На${'\u00A0'}оборудование подключена услуга «Гарантия+» и${'\u00A0'}действует расширенное гарантийное обслуживание. В${'\u00A0'}случае отключения Услуги, расходы на${'\u00A0'}поддержание работоспособности оборудования Клиент несёт самостоятельно.`;
    if (!isAvailable && !isOrdered)
      return `Внимание! После перехода оборудования в${'\u00A0'}собственность расходы на${'\u00A0'}поддержание работоспособности оборудования Клиент несёт самостоятельно.`;
    return '';
  };

  /** Текст "Гарантия+" */
  const [warrantyText, setWarrantyText] = useState<string | null>(null);

  /** Устанавливает текст "Гарантия+" */
  useEffect(() => {
    (async () => {
      try {
        const warrantyTextResult = await warrantyAgreementGet(device.sim);
        setWarrantyText(warrantyTextResult);
      } catch (e) {
        if (e.statusCode === 401) {
          setDeviceId(device.id);
          setOpenSPAfterAuthorizationState(SIDE_PAGES.DEVICES_AGREEMENT);
          setIsDevicePurchasesWizardShow(false);
        }
        console.error('warrantyAgreementGet', e);
      }
    })();
  }, []);

  /** Текст соглашения */
  const consentAgreementText = `Я, ${
    auth.clientName
  }, Договор &#8470;&nbsp;${removeLeadingZero(auth.contractName)}, ${
    isLease
      ? `настоящим извещаю, что намерен(а) купить в рассрочку предоставленное в&nbsp;пользование с&nbsp;передачей в&nbsp;собственность вышеуказанное оборудование на&nbsp;условиях, предусмотренных <a target="_blank" href="${PLANETA_URL}/static/upload-files/documents/topicals/ktc.dogovor.pdf">Договором</a>, и даю согласие на&nbsp;списание с&nbsp;моего Лицевого Счета стоимости оборудования в порядке, предусмотренном Договором. Я&nbsp;ознакомлен(а) с&nbsp;характеристиками оборудования и ограничениями по его применению.`
      : `настоящим извещаю, что намерен(а) купить предоставленное в&nbsp;пользование с&nbsp;передачей в&nbsp;собственность вышеуказанное оборудование на&nbsp;условиях, предусмотренных <a target="_blank" href="${PLANETA_URL}/static/upload-files/documents/topicals/ktc.dogovor.pdf">Договором</a>, и&nbsp;даю согласие на&nbsp;списание с&nbsp;моего Лицевого Счета стоимости оборудования. Я&nbsp;ознакомлен(а) с&nbsp;характеристиками оборудования и&nbsp;ограничениями по&nbsp;его применению.`
  }`;

  /** Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string | null>(
    purchaseError,
  );

  // Обработка ошибок при оплате
  useEffect(() => {
    setErrorMessage(purchaseError);
    if (purchaseErrorCode === DEVICE_PURCHASE_EXCEPTION) {
      setErrorMessage(DEFAULT_ERROR);
    }
  }, [purchaseErrorCode]);

  /** Текст на экране результата */
  const resultText = `Ура! Вы ${
    deviceOwnership === OWNERSHIP_CODES.LEASING && !isLease
      ? 'приобрели в собственность '
      : ''
  }
  ${
    deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING && !isLease
      ? 'погасили рассрочку на '
      : ''
  }

  ${isLease ? 'приобрели в рассрочку ' : ''}
  ${DEVICE_TYPES_GENITIVE[device?.deviceTypeCode]?.toLowerCase()}${'\u00A0'}${
    device?.modelName
  }`;

  return (
    <StyledDevicePurchasesWizard>
      {result.isResult && result.isCorrect && (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? <H2>{resultText}</H2> : <H3>{resultText}</H3>}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{errorMessage}</H2> : <H3>{errorMessage}</H3>}
        </>
      )}

      {!result.isResult && (
        <>
          <div className="device-purchases__block-wrapper">
            <div className="device-purchases__block-wrapper__price-tag">
              <PriceTag
                header={isLease ? 'Ежедневный платёж' : 'Сумма списания'}
                value={`${formatNumber(
                  isLease
                    ? deviceLeasePurchaseCheckResult?.leaseAnnuity
                    : device?.purchasePrice,
                )} ₽`}
                backgroundColor={
                  isBalanceMoreThanPrice
                    ? PriceTagBackgroundColor.OK
                    : PriceTagBackgroundColor.WARNING
                }
                subscription={`На вашем${'\u000A'}счету ${formatNumber(
                  auth.balance,
                )} ₽`}
                fontColor={
                  isBalanceMoreThanPrice
                    ? defaultTheme.colors.black
                    : defaultTheme.colors.warning
                }
                headerType="H2"
              />
              {!isBalanceMoreThanPrice && (
                <LinkWrapper href="/payment">
                  <Button styleType={ButtonStyleTypes.SECONDARY}>
                    {!isDesktop1100 ? 'Пополнить' : 'Пополнить баланс'}
                  </Button>
                </LinkWrapper>
              )}
            </div>
          </div>
          {isLease && devicePurchaseCheckResult && (
            <div className="device-purchases__block-list">
              <div className="device-purchases__block-wrapper">
                <Text
                  className="device-purchases__block-wrapper__header"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Первоначальный взнос
                </Text>
                <LeadingText color={defaultTheme.colors.black}>
                  {(device as ContractDevice).downPayment} ₽
                </LeadingText>
              </div>

              <div className="device-purchases__block-wrapper">
                <Text
                  className="device-purchases__block-wrapper__header"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Срок рассрочки
                </Text>
                <LeadingText color={defaultTheme.colors.black}>
                  {pluralizeAll(
                    deviceLeasePurchaseCheckResult.leasePeriod,
                    DAYS,
                  )}
                </LeadingText>
              </div>
            </div>
          )}
          <div className="device-purchases__block-list">
            <div className="device-purchases__block-wrapper">
              <Text
                className="device-purchases__block-wrapper__header"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Серийный номер
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {device?.serial}
              </LeadingText>
            </div>
            <div className="device-purchases__block-wrapper">
              <Text
                className="device-purchases__block-wrapper__header"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Полная стоимость
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {device?.purchasePrice} ₽
              </LeadingText>
            </div>
          </div>

          {isBalanceMoreThanPrice && (
            <>
              <div className="device-purchases__warning-text">
                <Text lineHeight="24px">{warningText()}</Text>
              </div>
              {isAvailable && !isOrdered && warrantyText && (
                <div className="device-purchases__guarantee">
                  <Consent
                    header="Подключить услугу «Гарантия+»"
                    text={warrantyText}
                    onChangeCheckbox={(e) =>
                      setIsWarrantyPlusAgreement(e.target.checked)
                    }
                  />
                </div>
              )}
              <div className="device-purchases__agreement">
                <Consent
                  header={`Я${'\u00A0'}намерен(а) купить ${
                    isLease ? `в${'\u00A0'}рассрочку` : ''
                  } предоставленное в${'\u00A0'}пользование
                  с${'\u00A0'}передачей в${'\u00A0'}собственность оборудование`}
                  text={parseHtml(consentAgreementText)}
                  onChangeCheckbox={(e) => setIsMainAgreement(e.target.checked)}
                />
              </div>
            </>
          )}
        </>
      )}
    </StyledDevicePurchasesWizard>
  );
};

export default observer(DevicePurchasesWizard);
