/** libraries */
import { createContext, useContext } from 'react';
/** interfaces */
import SettingsModel, { ISettingsModel } from './SettingsModel';
import { ChangePasswordStoreInstance } from './ChangePasswordModel';
import { PinStoreInstance } from './PinModel';
import { EmailConfirmStoreInstance } from './EmailConfirmModel';
import { AllowAccessWithoutPasswordStoreInstance } from './AllowAccessWithoutPasswordModel';

const store = SettingsModel.create({
  changePasswordStore: ChangePasswordStoreInstance,
  pinStore: PinStoreInstance,
  emailConfirmStore: EmailConfirmStoreInstance,
  allowAccessWithoutPasswordStore: AllowAccessWithoutPasswordStoreInstance,
});

const StoreContext = createContext<ISettingsModel>(store);

const useSettingsStore = (): ISettingsModel => {
  return useContext(StoreContext);
};

export default useSettingsStore;
