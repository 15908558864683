import { mobileVendor, isAndroid, isMobile } from 'react-device-detect';
import { STORES } from './constants';

export const getStoresByDeviceType = (): string[] => {
  if (!isMobile) return Object.values(STORES);

  /** Вернуть, когда появится приложение в Apple Store
   * if (isIOS) return [STORES.APP_STORE];
   */

  // https://github.com/duskload/react-device-detect/blob/master/src/constants/typesMaps.js#L133
  if (mobileVendor === 'Huawei' && isAndroid) return [STORES.APP_GALLERY];
  if (isAndroid) return [STORES.GOOGLE_PLAY, STORES.RU_STORE];

  return Object.values(STORES);
};
