/** libraries */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  LeadingText,
  Tabs,
  TabsStyleTypes,
  Text,
} from 'cordis-core-ui-planeta';

/** components */
import { StyledSummaryInfo, StyledTabs } from '../Summary.style';
import SummarySausages from '../../../Shared/SummarySausages/SummarySausages';

/** interfaces */
import {
  CHANNELS_CATEGORY,
  PRODUCT_ABBREVIATION,
  StorageTypes,
  UnitTypes,
} from '../Summary.types';
import { SummaryInfoProps } from './SummaryInfo.types';

/** constants */
import { ConnectionMethod, SERIES_CODE } from '~/constants/common';
import { desktop1280, desktop940 } from '~/components/Grid/constants';

/** utils */
import { pluralizeAll } from '~/utils/utils';

/**
 * компонент Информация о тарифе
 * @param {ConnectionMethod} connectionMethod
 * @param {string} speedBaseText
 * @param {ChannelsInfo} channelsInfo
 * @param {boolean} isTransformer
 */
const SummaryInfo: React.FC<SummaryInfoProps> = ({
  connectionMethod,
  speedBaseText,
  channelsInfo,
  isTransformer,
  seriesCode,
  features,
  mobilesInfo,
  isBusiness,
}: SummaryInfoProps) => {
  const internetType: string =
    connectionMethod === ConnectionMethod.FTTH
      ? 'Оптический Гигабит'
      : 'Интернет';

  // Вкладка табов телеканалов
  const [channelIndex, setChannelIndex] = useState<number>(0);

  // Сбрасываем вкладку табов телеканалов при изменении вкладки рубрик
  useEffect(() => {
    setChannelIndex(0);
  }, [channelsInfo]);

  const summaryChannelInfo = { ...channelsInfo };

  // Скроем раздел 4K, если в продукте их нет
  if (channelsInfo?.uhdChannelCount === 0) {
    delete summaryChannelInfo.uhdChannelCount;
  }
  // Если на продукте отсутствует телевидение, уберём приставку из списка оборудования
  if (!channelsInfo?.channelCount) {
    delete summaryChannelInfo.hdChannelCount;
    summaryChannelInfo.channelCount = 0;
  }

  const channelsCategoryValue: number[] = summaryChannelInfo
    ? Object.values(summaryChannelInfo)
    : [];
  const channelsCategoryNames: string[] = summaryChannelInfo
    ? Object.keys(summaryChannelInfo).map((name) => CHANNELS_CATEGORY[name])
    : [];

  /** Закрытый договор ЧК */
  const isClosedCHK = seriesCode === SERIES_CODE.CLOSE_CHK;

  /** вычисляем склонение для количества каналов */
  const channelCount = React.useMemo(() => {
    if (isClosedCHK) return '0 телеканалов';
    return pluralizeAll(channelsCategoryValue[channelIndex], [
      'телеканал',
      'телеканала',
      'телеканалов',
    ]);
  }, [channelsCategoryValue, channelIndex]);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  const mobileText = useMemo(() => {
    if (!mobilesInfo?.storageBalances) return null;
    if (isBusiness) {
      const minutes = mobilesInfo.storageBalances.find(
        (item) => item.storageType === StorageTypes.OutGoingCall,
      );
      if (!minutes) return null;
      return `${minutes.maxQuantity} ${PRODUCT_ABBREVIATION.MINUTES}`;
    }
    return mobilesInfo.storageBalances
      .filter((item) =>
        [StorageTypes.MobileInternet, StorageTypes.OutGoingCall].includes(
          item.storageType,
        ),
      )
      .map((item) => {
        const name = () => {
          switch (item.unitType) {
            case UnitTypes.gigabyte:
              return PRODUCT_ABBREVIATION.GB;
            case UnitTypes.minute:
              return PRODUCT_ABBREVIATION.MINUTES;
            default:
              return '';
          }
        };
        return `${item.maxQuantity} ${name()}`;
      })
      .sort((a, b) => {
        return a === PRODUCT_ABBREVIATION.GB ? 1 : -1;
      })
      .join(' и ');
  }, [mobilesInfo]);

  const internetTabs: React.ReactNode = (
    <StyledTabs className="internet">
      <Text
        className="internet__name"
        color={
          isDesktop940 ? defaultTheme.colors.shadow : defaultTheme.colors.black
        }
        lineHeight="24px"
      >
        {internetType}
      </Text>
      {isDesktop1280 ? (
        <LeadingText
          className="value value__margin"
          color={defaultTheme.colors.black}
        >
          {!isClosedCHK ? speedBaseText : '0 Мбит/c'}
        </LeadingText>
      ) : (
        <Text className="value value__margin" lineHeight="24px">
          {!isClosedCHK ? speedBaseText : '0 Мбит/c'}
        </Text>
      )}
    </StyledTabs>
  );

  /** обычные табы каналов табы */
  const channelTabs: React.ReactNode = (
    <StyledTabs>
      {isDesktop940 ? (
        <>
          <Tabs
            value={!isClosedCHK ? channelsCategoryNames : ['Всего']}
            onChange={setChannelIndex}
            activeTabIndex={channelIndex}
            styleType={TabsStyleTypes.SECONDARY}
          />
          {isDesktop1280 ? (
            <LeadingText
              className="value value__margin"
              color={defaultTheme.colors.black}
            >
              {channelCount}
            </LeadingText>
          ) : (
            <Text className="value value__margin" lineHeight="24px">
              {channelCount}
            </Text>
          )}
        </>
      ) : (
        <Text className="internet__name" lineHeight="24px">
          Любые из&nbsp;{channelsCategoryValue[channelIndex]}&nbsp;каналов
        </Text>
      )}
    </StyledTabs>
  );

  const mobileConnection = () => {
    return (
      <StyledTabs className="internet">
        <Text
          className="internet__name"
          color={
            isDesktop940
              ? defaultTheme.colors.shadow
              : defaultTheme.colors.black
          }
          lineHeight="24px"
        >
          {isBusiness ? 'Телефон' : 'Мобильная связь'}
        </Text>
        {isDesktop1280 ? (
          <LeadingText
            className="value value__margin"
            color={defaultTheme.colors.black}
          >
            {mobileText}
          </LeadingText>
        ) : (
          <Text className="value value__margin" lineHeight="24px">
            {mobileText}
          </Text>
        )}
      </StyledTabs>
    );
  };

  return (
    <StyledSummaryInfo>
      <div className="info-statistics">
        {internetTabs}
        {(summaryChannelInfo.channelCount > 0 || isClosedCHK) && channelTabs}
        {mobileText && mobileConnection()}
      </div>
      {features?.length > 0 && <SummarySausages features={features} />}
    </StyledSummaryInfo>
  );
};

export default React.memo(SummaryInfo);
