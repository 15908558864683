import { Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { VacationContentProps } from '../../interfaces';

const VacationContent = types.model('', {
  size: types.number,
});

const VacationModel = types
  .model('VacationModel', {
    content: VacationContent,
  })
  .views((self) => ({
    get size() {
      return toJS(self.content.size);
    },
  }));

const createStore = (content: VacationContentProps): IMainVacationStore => {
  return VacationModel.create({
    content,
  });
};
export type IMainVacationStore = Instance<typeof VacationModel>;
export default createStore;
