import { createPortal } from 'react-dom';

interface PortalProps {
  children: JSX.Element[] | JSX.Element;
  wrapperId?: string;
}

function Portal({ children, wrapperId = 'portal' }: PortalProps): JSX.Element {
  const mount = document.getElementById(wrapperId);

  return createPortal(children, mount);
}
export default Portal;
