/* eslint-disable camelcase */
/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Snoska, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { format, isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** interfaces */
import { ResultProps } from '../../common/SidePageResult/types';
import { Channel } from '../../../interfaces';

/** components */
import ToggleTabs from '../../common/ToggleTabs';
import OptionBlock from '../../common/OptionBlock';
import SidePageResult from '../../common/SidePageResult';

/** styles */
import { StyledPackageConnectionWizard } from './styles';
import { StyledFooter } from '../../../styles';

/** api */
import {
  bindITvChannelPack,
  getITvChannelPackBindAgreement,
  getITvChannelPackUnbindAgreement,
  unbindITvChannelPack,
} from '~/api/apiPab2c';

/** constants */
import { MOUNTHS } from '~/components/Blocks/Templates/MyStoriesContest/constants';

/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber } from '~/utils/utils';
import { getStore } from '../../../utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';

/** props */
interface PackageConnectionWizardProps {
  isConnection?: boolean;
  result: ResultProps;
  setResult: Dispatch<SetStateAction<ResultProps>>;
  connectedChannelsInPackage: Channel[];
  connectedChannels: Channel[];
}

/** Сайдпейдж подключения/отключения пакета */
const PackageConnectionWizard: FC<PackageConnectionWizardProps> = ({
  isConnection,
  result,
  setResult,
  connectedChannelsInPackage,
  connectedChannels,
}: PackageConnectionWizardProps) => {
  const {
    summaryDataStore: { tariffId },
    tvStore: { updateChannelsAndPackages, viewControlInfo },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const { storeType } = useMyTvStore();
  const { activePackageCard } = getStore(storeType);

  const {
    channelPackName,
    price,
    channelPackId,
    id,
    banTrimDate,
  } = activePackageCard;

  /** Время отключения */
  const shutdownDate = useMemo(() => {
    const isAfter = banTrimDate && !isBefore(parseISO(banTrimDate), new Date());
    const date = isAfter ? new Date(parseISO(banTrimDate)) : new Date();
    return date
      ? `${date.getDate().toString()} ${MOUNTHS[date.getMonth()]} ${
          !isAfter ? '' : `в ${format(date, 'HH:mm')}`
        }`
      : '';
  }, [activePackageCard]);

  /** JSX времени отключения */
  const shutdownTime = () => (
    <div className="package-connection__shutdown-time">
      <OptionBlock
        title={
          result.isResult ? 'Отключение произойдёт' : 'Пакет будет отключён'
        }
        body={shutdownDate}
      />
    </div>
  );

  /** Есть каналы и пакеты, которые восстановятся после отключения пакета */
  const isRecovery = false;

  /** disable кнопки */
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  /** Загрузка кнопки */
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(true);

  /** Соглашение */
  const [agreement, setAgreement] = useState<string>(null);

  /** Возвращает текст соглашения об услугах при подключении пакета телеканалов */
  const getBindAgreement = async () => {
    try {
      const res = await getITvChannelPackBindAgreement(id);
      setAgreement(res);
      setIsLoadingButton(false);
    } catch (e) {
      setIsLoadingButton(false);
      setIsDisableButton(true);
    }
  };

  /** Возвращает текст соглашения об услугах при отключении пакета телеканалов */
  const getUnbindAgreement = async () => {
    try {
      const res = await getITvChannelPackUnbindAgreement(channelPackId);
      setAgreement(res);
      setIsLoadingButton(false);
    } catch (e) {
      setIsLoadingButton(false);
      setIsDisableButton(true);
    }
  };

  useEffect(() => {
    if (isConnection) {
      getBindAgreement();
    } else {
      getUnbindAgreement();
    }
  }, [isConnection]);

  /** Прикрепляет пакет телеканалов к договору */
  const bindChannelPack = async () => {
    setIsLoadingButton(true);
    try {
      await bindITvChannelPack(id);
      updateChannelsAndPackages(tariffId);
      setResult({
        isResult: true,
        isCorrect: true,
        title: `Пакет ${channelPackName} подключён`,
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'} так...`,
      });
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Открепляет пакет телеканалов от договора (отказ от телеканала) */
  const unbindChannelPack = async () => {
    setIsLoadingButton(true);
    try {
      await unbindITvChannelPack(channelPackId);
      updateChannelsAndPackages(tariffId);
      setResult({
        isResult: true,
        isCorrect: true,
        title: `Пакет телеканалов ${channelPackName} будет отключён`,
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'}так...`,
      });
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Событие подключения/отключения пакета */
  const connectChannelPack = () => {
    if (isConnection) {
      bindChannelPack();
    } else {
      unbindChannelPack();
    }
  };

  return (
    <StyledPackageConnectionWizard>
      {!result.isResult ? (
        <>
          {isConnection ? (
            <>
              <div className="package-connection__wrapper">
                <div className="package-connection__wrapper__toggle-tabs">
                  <ToggleTabs cost={price} />
                </div>
              </div>
              <div className="package-connection__agreement">
                <Text lineHeight="24px" fontWeightBold>
                  Согласие на&nbsp;подключение пакета телеканалов «
                  {channelPackName}»
                </Text>
                <Text lineHeight="24px">{parseHtml(agreement)}</Text>
              </div>
              <StyledFooter>
                <Button
                  onClick={connectChannelPack}
                  disabled={isDisableButton || isTemporaryTokenAuth}
                  loading={isLoadingButton}
                >
                  Подключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            </>
          ) : (
            <>
              {shutdownTime()}
              <div className="package-connection__warning">
                <div className="package-connection__warning__block">
                  <Text lineHeight="24px" fontWeightBold>
                    Станут недоступными телеканалы{' '}
                  </Text>
                  <Text lineHeight="24px">
                    {connectedChannelsInPackage?.length > 0 &&
                      connectedChannelsInPackage
                        .map((channel) => channel.name)
                        .join(', ')}
                    .
                  </Text>
                </div>
                {isRecovery && (
                  <div className="package-connection__warning__block">
                    <Text lineHeight="24px" fontWeightBold>
                      Восстановится подписка на&nbsp;пакет и&nbsp;каналы
                    </Text>
                    <Text lineHeight="24px">
                      {connectedChannelsInPackage?.length > 0 &&
                        connectedChannelsInPackage
                          .map((channel) => channel.name)
                          .join(', ')}
                      .
                    </Text>
                  </div>
                )}
              </div>
              <div className="package-connection__agreement">
                <Text lineHeight="24px" fontWeightBold>
                  Согласие на отключение пакета телеканалов «{channelPackName}»
                </Text>
                <Text lineHeight="24px">{parseHtml(agreement)}</Text>
              </div>
              <div className="package-connection__agreement">
                <Text lineHeight="24px" fontWeightBold>
                  Будет восстановлен доступ к&nbsp;ранее заказанным Телеканалам,
                  Пакетам телеканалов, к&nbsp;сервису «Управление просмотром»
                </Text>
                <Text lineHeight="24px">
                  Я&nbsp;ознакомлен(а) и&nbsp;согласен(на), что после отказа
                  от&nbsp;доступа к&nbsp;пакету телеканалов &laquo;
                  {channelPackName}&raquo; будет восстановлен доступ
                  к&nbsp;ранее заказанным Телеканалам, Пакетам телеканалов
                  и&nbsp;к&nbsp;сервису &laquo;Управление просмотром&raquo;
                  в&nbsp;размере {formatNumber(viewControlInfo?.price)}{' '}
                  ₽&nbsp;в&nbsp;день. Информация о&nbsp;заказах доступна
                  в&nbsp;интерфейсе ЦТВ в&nbsp;Личном Кабинете в&nbsp;разделе
                  &laquo;Управление заказами&raquo; и&nbsp;на&nbsp;сайте
                  planeta.tc в&nbsp;разделе &laquo;Моя Планета&raquo;.
                </Text>
              </div>
              <StyledFooter>
                <Button
                  onClick={connectChannelPack}
                  disabled={isDisableButton || isTemporaryTokenAuth}
                  loading={isLoadingButton}
                >
                  Отключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            </>
          )}
        </>
      ) : (
        <SidePageResult result={result}>
          {isConnection && result.isCorrect ? (
            <Text className="package-connection__result-text" lineHeight="24px">
              Пакет заказан. Каналы доступны к&nbsp;просмотру на&nbsp;компьютере
              и&nbsp;телеприставке.
            </Text>
          ) : (
            result.isCorrect && shutdownTime()
          )}
        </SidePageResult>
      )}
    </StyledPackageConnectionWizard>
  );
};

export default observer(PackageConnectionWizard);
