/* eslint-disable no-empty-pattern */
/** libraries */
import { useMemo } from 'react';
import NextImage from 'next/image';
import { Tag, defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import { ru } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
import {
  ANNUAL_SUBSCRIPTION,
  SOFTLINE_PROMO_TAGS,
} from '../../../Softline/constants';
/** store */
import { useRootStore } from '~/stores/RootStore';
/** interfaces */
import { SoftlineFields } from '../../../Softline/types';
/** styles */
import { StyledSoftlineCard } from './styles';
/** utils */
import { formatNumber } from '~/utils/utils';

interface SoftlineCardProps {
  item: SoftlineFields;
  onDeviceCardMouseDown?: React.MouseEventHandler<HTMLLIElement>;
  onDeviceCardMouseUp?: React.MouseEventHandler<HTMLLIElement>;
  itemWidth: number;
  isHide?: boolean;
}

const SoftlineCard = ({
  item,
  onDeviceCardMouseUp,
  onDeviceCardMouseDown,
  itemWidth,
  isHide,
}: SoftlineCardProps): JSX.Element => {
  const {
    contractStateStore: { contractState },
  } = useRootStore();

  const {
    durationDays,
    name,
    trialInfo,
    deviceCount,
    pendingUnbindDate,
    price,
    imageInfo,
  } = item;

  const { url, width: imgWidth, height: imgHeight } = imageInfo ?? {};

  /** Годовая подписка */
  const isAnnual = durationDays === ANNUAL_SUBSCRIPTION;

  /** Подписка подключена и активна */
  const isActiveSubscription = useMemo(
    () => contractState === OPERATING_STATE.ON,
    [contractState],
  );

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Тег для подписки */
  const softlineTag = useMemo((): SOFTLINE_PROMO_TAGS[] => {
    const tags = [];
    if (trialInfo?.isEnabled ?? trialInfo?.trialDays > 0)
      tags.push(SOFTLINE_PROMO_TAGS.PROMO);
    if (durationDays === ANNUAL_SUBSCRIPTION)
      tags.push(SOFTLINE_PROMO_TAGS.ANNUAL);
    return tags.length ? tags : null;
  }, [item]);

  /** Подпись для подключённых подписок */
  const infoText = useMemo(() => {
    const unbindDate = pendingUnbindDate
      ? format(parseISO(pendingUnbindDate), 'dd.MM.yyyy', {
          locale: ru,
        })
      : null;
    if (isAnnual) {
      return `Подписка\nдо ${unbindDate}`;
    }
    if (!isActiveSubscription) return 'Подписка приостановлена';
    if (pendingUnbindDate) return `Отключение\nс ${unbindDate}`;
    return 'Подписка';
  }, [item, isActiveSubscription]);

  /** Текст числа устройств для подписки */
  const deviceCountText = useMemo(() => {
    switch (deviceCount) {
      case 1:
        return 'для 1 устройства';
      case null:
        return 'для всех устройств';
      default:
        return `для ${deviceCount} устройств`;
    }
  }, [deviceCount]);

  return (
    <StyledSoftlineCard
      itemWidth={itemWidth}
      isHide={isHide}
      onMouseDown={onDeviceCardMouseDown}
      onMouseUp={onDeviceCardMouseUp}
    >
      <div className="logo">
        <NextImage
          loader={imageLoader}
          src={url}
          alt={name}
          width={imgWidth}
          height={imgHeight}
          quality={100}
        />
      </div>
      <H3 className="name" dangerouslySetInnerHTML={{ __html: name }} />
      {softlineTag && (
        <div className="tags">
          {softlineTag.map((tag) => (
            <Tag
              key={tag}
              color={defaultTheme.colors.planeta}
              colorTag={defaultTheme.colors.pink}
            >
              {tag}
            </Tag>
          ))}
        </div>
      )}
      {deviceCountText && (
        <Text className="count" lineHeight="24px">
          {deviceCountText}
        </Text>
      )}
      {infoText && (
        <Text
          className="info"
          lineHeight="24px"
          color={defaultTheme.colors.shadow}
        >
          {infoText}
        </Text>
      )}
      {price && (
        <Text
          className="count"
          lineHeight="24px"
          color={
            !isActiveSubscription
              ? defaultTheme.colors.shadow
              : defaultTheme.colors.black
          }
        >
          {formatNumber(price)} ₽ {isAnnual ? 'за год' : 'в день'}
        </Text>
      )}
    </StyledSoftlineCard>
  );
};

export default SoftlineCard;
