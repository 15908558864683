/** libraries */
import { memo, useMemo } from 'react';
import { Sausage, SwipeIndicator } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** components */
import { StyledSummarySausage } from './styles';
/** interfaces */
import { SausageProps } from './types';
/** utils */
import { isExternal } from '~/utils/utils';
import { desktop1280, desktop940 } from '~/components/Grid/constants';
import { parseHtml } from '../Shared.utils';
import { nanoid } from 'nanoid';

interface SummarySausagesProps {
  features: SausageProps[];
  className?: string;
}

/** Список фич */
const SummarySausages: React.FC<SummarySausagesProps> = ({
  features,
  className,
}: SummarySausagesProps) => {
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktopBetween940And1280 = useMediaQuery({
    query: `(min-width: ${desktop940}px) and (max-width: ${desktop1280}px)`,
  });

  const keyIds = useMemo(
    () => (features.length ? features.map(() => nanoid(5)) : []),
    [features],
  );

  const getImageSrc = (imageSrc: string) => {
    if (!imageSrc) return undefined;
    return isExternal(imageSrc)
      ? imageSrc
      : `${process.env.STATIC_SERVER}/${imageSrc}`;
  };

  const getFeatureList = (): JSX.Element[] => {
    const items = features.map((feature, index) => (
      <Sausage
        tagText={feature.tagText}
        tagColor={feature.tagColor}
        text={parseHtml(feature.text) as string}
        textColor={feature.textColor}
        image={getImageSrc(feature.image)}
        backgroundColor={feature.backgroundColor}
        className="sausage"
        key={keyIds[index]}
      />
    ));
    return isDesktopBetween940And1280 ? [items[0]] : items;
  };

  return (
    <StyledSummarySausage className={className}>
      {isDesktop940 || getFeatureList().length === 1 ? (
        getFeatureList()
      ) : (
        <div className="swipe-indicator">
          <SwipeIndicator swipeItems={getFeatureList()} loop auto />
        </div>
      )}
    </StyledSummarySausage>
  );
};

export default memo(SummarySausages);
