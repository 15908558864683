/* eslint-disable camelcase */
/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icon,
  Icons,
  SidePage,
  Snoska,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** interfaces */
import { PackageConnectionStates } from '../ChannelPackageCard/interfaces';
import { ConnectionStates as ServiceConnectionStates } from '../ServiceCards/interfaces';
import { ServiceCode, ServiceInfoProps } from '../../../interfaces';
import { ResultProps } from '../../common/SidePageResult/types';

/** styles */
import { StyledPackageDescriptionCard } from './styles';

/** constants */
import {
  CardStates,
  CHANNEL,
  CONNECTION,
  PACKAGE_DESCRIPTION_ID,
  SERVICE,
  ServiceNames,
  STORE_TYPE,
  TEXT,
  TRANSFORMER_TEXT,
} from '../../../constants';
import {
  desktop500,
  desktop700,
  desktop940,
} from '~/components/Grid/constants';
import { INITIAL_RESULT } from '../../common/SidePageResult/constants';

/** components */
import Row from '../../common/RowContent';
import ToggleTabs from '../../common/ToggleTabs';
import ServiceCardWrapper from '../ServiceCards/ServiceCardWrapper';
import ChannelCard from '../ChannelCard/ChannelCard';
import PackageConnectionWizard from '../../SidePages/PackageConnectionWizard/PackageConnectionWizard';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';
import Portal from '~/components/Portal/Portal';
import { getDate, getStore } from '../../../utils';
import LinkWrapper from '~/components/LinkWrapper';

/** api */
import {
  checkITvChannelPackBindCondition,
  checkITvChannelPackUnbindCondition,
} from '~/api/apiPab2c';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../../stores/useMyTvStore';
import { IMyTVX3ModelStore } from '../../../stores/MyTvX3Store';

/** props */
interface PackageDescriptionCardProps {
  onClickServiceCard: (service: ServiceInfoProps) => void;
}

const PackageDescriptionCard: FC<PackageDescriptionCardProps> = ({
  onClickServiceCard,
}: PackageDescriptionCardProps) => {
  const {
    summaryDataStore: { transformationLimit },
    tvStore: {
      connectedChannelsInPackages,
      connectedChannelsOutsidePackages,
      connectedTVPackages,
      transformerInfo,
      tvChannelPackages,
      editOrderSum,
      setEditOrderSum,
      idsAddedPacketsToTransformer,
      setIdsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
      setIdsAddedChannelsToTransformer,
      channelsList,
    },
  } = useRootStore();
  const { storeType } = useMyTvStore();
  const {
    openCardBlockId,
    setCardState,
    onClickChannelCard,
    activePackageCard,
    setIsEditTransformer,
  } = getStore(storeType) as IMyTVX3ModelStore;

  const isLightbox = storeType === STORE_TYPE.MY_TV_X2;

  const {
    channelPackName,
    price,
    channelPackChannels,
    channelIds,
    banTrimDate,
    unbindDt,
    channelPackId,
    id,
    isTimeshiftIncluded,
  } = activePackageCard;

  /** Статус подключения */
  const connection = useMemo(() => {
    /** Подключённый пакет */
    const connectedPacket = connectedTVPackages.find((item) => {
      if (activePackageCard?.channelPackId)
        return item.channelPackId === activePackageCard.channelPackId;
      return item.channelPackId === activePackageCard.id;
    });
    /** Добавлен в трансформер */
    if (
      transformerInfo &&
      idsAddedPacketsToTransformer?.length &&
      idsAddedPacketsToTransformer.find((item) => item === id) &&
      !connectedPacket
    )
      return PackageConnectionStates.AddedToTransformer;
    /** Убран из трансформера */
    if (
      transformerInfo &&
      connectedPacket &&
      !idsAddedPacketsToTransformer?.find((item) => item === channelPackId)
    )
      return PackageConnectionStates.RemovedFromTheTransformer;
    if (!connectedPacket) return PackageConnectionStates.NotConnected;
    if (unbindDt) return PackageConnectionStates.RefusalIssued;
    return connectedPacket.isFree
      ? PackageConnectionStates.IncludedInTheProduct
      : PackageConnectionStates.Connected;
  }, [connectedTVPackages, activePackageCard, idsAddedPacketsToTransformer]);

  /** Подключённые сервисы */
  const services = [];

  /** Каналы в пакете */
  const connectedChannelsInPackage = useMemo(() => {
    if (channelsList?.length && channelIds?.length) {
      return channelIds
        ?.map((item) => {
          return channelsList.find((channel) =>
            channel?.weburgId
              ? channel.weburgId === item
              : channel?.id === item,
          );
        })
        .filter((item) => item !== undefined);
    }
    if (channelPackChannels?.length)
      return channelPackChannels.filter((item) => item !== undefined);
    return [];
  }, [channelsList]);

  /** Подключённые каналы */
  const connectedChannels = useMemo(() => {
    if (channelsList?.length && channelIds?.length) {
      return channelIds?.map((item) => {
        return channelsList.find((channel) =>
          channel?.weburgId ? channel.weburgId === item : channel?.id === item,
        );
      });
    }
    if (channelsList?.length && channelPackChannels?.length) {
      return channelPackChannels.filter((item) => {
        return item
          ? [
              ...connectedChannelsInPackages,
              ...connectedChannelsOutsidePackages,
            ].includes(item.weburgId ?? item.id)
          : null;
      });
    }
    return [];
  }, [channelsList]);

  /** Подключённые сервисы */
  const connectedServices = useMemo(
    () =>
      services?.length
        ? services?.filter((service) =>
            [
              ServiceConnectionStates.Connected,
              ServiceConnectionStates.ConnectedInAPackage,
            ].includes(service.connection),
          )
        : [],
    [services],
  );

  /** Отключение доступно сразу */
  const isRejectionAvailable = useMemo(
    () => isBefore(new Date(parseISO(banTrimDate)), new Date()),
    [banTrimDate],
  );

  /** Дата, после которой возможен отказ от пакета */
  const refusalDate = useMemo(() => {
    if (!banTrimDate) return null;
    if (transformerInfo) {
      return `${getDate(banTrimDate)} года`;
    }
    if (!isRejectionAvailable) return getDate(banTrimDate, 'dd.MM.yyyy');
    return '';
  }, [banTrimDate]);

  /** Отказ от пакета */
  const isRejectionOfThePackage =
    connection === PackageConnectionStates.RefusalIssued;

  /** Дата отключения пакета, если оформлен отказ */
  const shutdownDate = useMemo(() => {
    if (!unbindDt) return null;
    if (transformerInfo) {
      return `${getDate(unbindDt)} года`;
    }
    return getDate(unbindDt, 'dd.MM.yyyy');
  }, [unbindDt]);

  /** Компонент Tag */
  const customTag = (text) => {
    const color = () => {
      if (connection === PackageConnectionStates.AddedToTransformer)
        return defaultTheme.colors.planeta;
      if (connection === PackageConnectionStates.RemovedFromTheTransformer)
        return defaultTheme.colors.black;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.white;
    };

    const colorTag = () => {
      if (connection === PackageConnectionStates.AddedToTransformer)
        return defaultTheme.colors.pink;
      if (connection === PackageConnectionStates.RemovedFromTheTransformer)
        return defaultTheme.colors.disable;
      if (isRejectionOfThePackage) return defaultTheme.colors.smokeGreen;
      return defaultTheme.colors.green;
    };

    const hint = () => {
      if (!isDesktop940) return undefined;
      if (shutdownDate) return `Оформлен отказ с ${shutdownDate}`;
      if (connection === PackageConnectionStates.RemovedFromTheTransformer) {
        return isRejectionAvailable
          ? 'Будет отключён при применении трансформера'
          : `Будет оформлен отказ с ${refusalDate} года при применении трансформера`;
      }
      return undefined;
    };
    return (
      <Tag
        color={color()}
        colorTag={colorTag()}
        backgroundColor={
          !isRejectionOfThePackage &&
          ![
            PackageConnectionStates.AddedToTransformer,
            PackageConnectionStates.RemovedFromTheTransformer,
          ].includes(connection) &&
          colorTag()
        }
        hint={hint()}
      >
        {text}
      </Tag>
    );
  };

  /** Текст статуса подключения */
  const connectionStatusText = (): string | boolean => {
    switch (connection) {
      case PackageConnectionStates.AddedToTransformer:
        return TEXT.ADDED;
      case PackageConnectionStates.RemovedFromTheTransformer:
        return TEXT.REMOVED;
      case PackageConnectionStates.Connected:
        return TEXT.CONNECTED;
      case PackageConnectionStates.IncludedInTheProduct:
        return TEXT.INCLUDED_IN_PRODUCT;
      case PackageConnectionStates.RefusalIssued:
        return TEXT.CANCELLATION;
      default:
        return false;
    }
  };

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop700 = useMediaQuery({
    query: `(min-width: ${desktop700}px)`,
  });
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  /** Заголовок каналов */
  const rowTitle = () => {
    if (connectedChannelsInPackage?.length && services?.length) {
      return `В пакете ${pluralizeAll(
        connectedChannelsInPackage.length,
        CHANNEL,
      )} и ${pluralizeAll(services.length, SERVICE)}`;
    }
    if (connectedChannelsInPackage?.length)
      return `В пакете ${pluralizeAll(
        connectedChannelsInPackage.length,
        CHANNEL,
      )}`;
    if (services?.length)
      return `В пакете ${pluralizeAll(services.length, SERVICE)}`;
    return undefined;
  };

  /** Описание подключённых каналов */
  const rowDescription = (): string => {
    if (connectedChannels?.length && connectedServices?.length) {
      return `${pluralizeAll(
        connectedChannels?.length,
        CHANNEL,
        CONNECTION,
      )} и ${pluralizeAll(connectedServices?.length, SERVICE)}`;
    }
    if (connectedChannels?.length)
      return pluralizeAll(connectedChannels?.length, CHANNEL, CONNECTION);
    if (connectedServices?.length)
      return pluralizeAll(connectedServices?.length, CHANNEL, CONNECTION);
    return undefined;
  };

  /** Все каналы и сервисы подключены */
  const isAllConnected =
    connectedChannelsInPackage?.length === connectedChannels?.length &&
    connectedServices?.length === services?.length;

  /** Нельзя подключить пакет */
  const isCannotBeConnected = false;

  /** Сайдпейдж подключения/отключения пакета */
  const [isOpenConnectionSP, setIsOpenConnectionSP] = useState<boolean>(false);

  /** Результат подключения/отключения пакета */
  const [result, setResult] = useState<ResultProps>(INITIAL_RESULT);

  /** true - подключение пакета, false - отключение пакета */
  const [isConnection, setIsConnection] = useState<boolean>(true);

  /** Событие отключения пакета */
  const disablePackage = () => {
    setIsConnection(false);
    setIsOpenConnectionSP(true);
  };

  /** disable кнопки */
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  /** Загрузка кнопки */
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(true);

  /** Проверяет предусловия подключения пакета телеканалов к договору */
  const proofITvChannelPackBindCondition = async () => {
    if (!id) return;
    try {
      await checkITvChannelPackBindCondition(id);
      setIsDisableButton(false);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Проверяет предусловия открепления пакета телеканалов от договора (отказ от телеканала) */
  const proofITvChannelPackUnbindCondition = async () => {
    if (!channelPackId) return;
    try {
      await checkITvChannelPackUnbindCondition(channelPackId);
      setIsDisableButton(false);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    if (connection === PackageConnectionStates.Connected) {
      proofITvChannelPackUnbindCondition();
    }
    if (connection === PackageConnectionStates.NotConnected) {
      proofITvChannelPackBindCondition();
    }
  }, [connection, activePackageCard]);

  /** Текст кнопки трансформера */
  const transformerButtonText = useMemo(() => {
    switch (connection) {
      case PackageConnectionStates.NotConnected:
        return isDesktop940
          ? TRANSFORMER_TEXT.ADD_TO_TRANSFORMER
          : TRANSFORMER_TEXT.ADD;
      case PackageConnectionStates.AddedToTransformer:
        return TRANSFORMER_TEXT.REMOVE_PACKAGE;
      case PackageConnectionStates.Connected:
        return TRANSFORMER_TEXT.REMOVE_PACKAGE;
      case PackageConnectionStates.RemovedFromTheTransformer:
        return TRANSFORMER_TEXT.RETURN_TO_TRANSFORMER;
      case PackageConnectionStates.RefusalIssued:
        return isDesktop940
          ? TRANSFORMER_TEXT.ADD_TO_TRANSFORMER
          : TRANSFORMER_TEXT.ADD;
      default:
        return null;
    }
  }, [connection, isDesktop940]);

  /** Дополнительная цена за трансформер */
  const additionalPriceForTransformer = useMemo(() => {
    if (!transformerInfo) return undefined;
    /** Остаток лимита трансформера */
    const residue = transformationLimit - editOrderSum;
    if (residue > price) {
      return null;
    }
    if (transformationLimit > editOrderSum && residue < price) {
      return price - residue;
    }
    return transformerInfo ? price : undefined;
  }, []);

  /** Событие клика трансформера */
  const transformerClick = () => {
    setIsEditTransformer(true);

    /** Сумма подключённых и добавленных в трансформер каналов, которые находятся в составе этого пакета */
    const sum = idsAddedChannelsToTransformer?.reduce((acc, item) => {
      const canal = channelPackChannels?.find((channel) =>
        channel?.singleChannelPacks?.length
          ? item === channel.singleChannelPacks[0].id
          : null,
      );
      return canal ? acc + canal.singleChannelPacks[0].price : acc;
    }, 0);

    switch (connection) {
      case PackageConnectionStates.NotConnected:
        setIdsAddedPacketsToTransformer(
          idsAddedPacketsToTransformer?.length
            ? [...idsAddedPacketsToTransformer, id]
            : [id],
        );
        setEditOrderSum(editOrderSum + price - sum);
        break;
      case PackageConnectionStates.AddedToTransformer:
        setIdsAddedPacketsToTransformer(
          idsAddedPacketsToTransformer.filter((item) => item !== id),
        );
        setEditOrderSum(editOrderSum - price + sum);
        break;

      case PackageConnectionStates.Connected:
        setIdsAddedPacketsToTransformer(
          idsAddedPacketsToTransformer.filter((item) => item !== channelPackId),
        );
        setEditOrderSum(editOrderSum - price);
        break;

      case PackageConnectionStates.RemovedFromTheTransformer:
        setIdsAddedPacketsToTransformer(
          idsAddedPacketsToTransformer?.length
            ? [...idsAddedPacketsToTransformer, channelPackId]
            : [channelPackId],
        );
        setEditOrderSum(editOrderSum + price);
        break;
      default:
        break;
    }
  };

  /** Добавить все каналы в объединяющих пакетах */
  const addAllChannels = () => {
    setIsEditTransformer(true);
    const ids = channelPackChannels.map((item) =>
      item?.singleChannelPacks?.length ? item.singleChannelPacks[0].id : null,
    );
    const sum = channelPackChannels.reduce((acc, item) => {
      return item?.singleChannelPacks?.length
        ? acc + item?.singleChannelPacks[0].price
        : acc;
    }, 0);
    setIdsAddedChannelsToTransformer(
      idsAddedChannelsToTransformer?.length
        ? [...idsAddedChannelsToTransformer, ...ids]
        : [...ids],
    );
    setEditOrderSum(editOrderSum + sum);
  };

  /** Все каналы использованы в объединяющем пакете */
  const allChannelsUsed = useMemo(() => {
    if (price !== null) return undefined;

    /** Добавленные в трансформер пакеты (без подключённых) */
    const addedPackages = idsAddedPacketsToTransformer?.length
      ? tvChannelPackages.filter((item) =>
          idsAddedPacketsToTransformer.includes(item.id),
        )
      : [];

    /** Все пакеты "используются" */
    return channelPackChannels?.length
      ? channelPackChannels.every((item) => {
          /** Канал подключён в пакете */
          const connectedChannelId = connectedChannelsInPackages.find((pack) =>
            item?.weburgId ? pack === item.weburgId : pack === item.id,
          );

          /** Пакеты, в которых подключён канал */
          const connectedPackets = connectedChannelId
            ? connectedTVPackages.filter((pack) => {
                return pack.channelIds.includes(connectedChannelId);
              })
            : [];

          /** Канал добавлен в трансформер */
          const channelAddedToTransformer = idsAddedChannelsToTransformer.find(
            (canal) =>
              item?.singleChannelPacks?.length
                ? canal === item?.singleChannelPacks[0].id
                : null,
          );

          /** Добавлен */
          return (
            idsAddedChannelsToTransformer.includes(
              item?.singleChannelPacks[0]?.id,
            ) ||
            /** Подключён и не убран из трансформера */
            (item?.isOrdered && channelAddedToTransformer) ||
            /** Отказ */
            item.unbindDt ||
            /** Включён в продукт */
            item.includedToProduct ||
            /** Подключён в пакетах и пакеты не убраны из трансформера */
            (connectedChannelId &&
              !(
                connectedPackets.length &&
                !connectedPackets.every((pack) =>
                  idsAddedPacketsToTransformer.includes(pack.channelPackId),
                )
              )) ||
            /** Добавлен в пакете */
            addedPackages.filter((pack) =>
              pack.channelPackChannels.find((canal) =>
                canal?.weburgId && item?.weburgId
                  ? canal.weburgId === item.weburgId
                  : canal.id === item.id,
              ),
            ).length
          );
        })
      : [];
  }, [
    idsAddedPacketsToTransformer,
    idsAddedChannelsToTransformer,
    tvChannelPackages,
    connectedChannelsInPackages,
  ]);

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    if (transformationLimit > editOrderSum) {
      return `доступно ${formatNumber(
        transformationLimit - editOrderSum,
      )} ₽ для трансформации`;
    }
    return `${formatNumber(
      editOrderSum - transformationLimit,
    )} ₽ в день за доп. каналы`;
  }, [editOrderSum, transformationLimit]);

  return (
    <StyledPackageDescriptionCard
      id={PACKAGE_DESCRIPTION_ID}
      isLightbox={isLightbox}
    >
      {isLightbox ? (
        <div className="package-description__lightbox__tag">
          {customTag(connectionStatusText())}
        </div>
      ) : (
        <div className="package-description__header">
          <div className="package-description__header__name-tag">
            <H3>Пакет каналов {channelPackName}</H3>
            <div className="package-description__header__name-tag__time">
              {![PackageConnectionStates.NotConnected].includes(connection) &&
                customTag(connectionStatusText())}
              {connection ===
                PackageConnectionStates.RemovedFromTheTransformer &&
                !isRejectionAvailable && <Icons.WaitIcon />}
            </div>
          </div>
          <div
            className="package-description__header__close-icon"
            onClick={() => {
              setCardState(CardStates.MAIN);
              if (!isDesktop940)
                setTimeout(() => {
                  scrollToBlockById(openCardBlockId, 'auto', 'center');
                }, 0);
            }}
          >
            <Icons.CloseIcon />
          </div>
        </div>
      )}
      {connection !== PackageConnectionStates.IncludedInTheProduct &&
        price !== null && (
          <Row title="Стоимость подписки">
            <div className="package-description__subcription-cost">
              <ToggleTabs
                cost={price}
                additionally={
                  ![
                    PackageConnectionStates.RefusalIssued,
                    PackageConnectionStates.Connected,
                    PackageConnectionStates.RemovedFromTheTransformer,
                  ].includes(connection)
                    ? additionalPriceForTransformer
                    : null
                }
              />
              {connection === PackageConnectionStates.RefusalIssued && (
                <Text
                  className="package-description__subcription-cost__refusal"
                  lineHeight="24px"
                  color={defaultTheme.colors.gray}
                >
                  Списания прекратятся с&nbsp;{shutdownDate}
                </Text>
              )}
              {connection ===
                PackageConnectionStates.RemovedFromTheTransformer && (
                <>
                  {isRejectionAvailable ? (
                    <Text
                      className="package-description__transformer__refusal-date"
                      lineHeight="24px"
                      color={defaultTheme.colors.gray}
                    >
                      Отключение доступно сразу после применения Трансформера
                    </Text>
                  ) : (
                    <Text
                      className="package-description__transformer__refusal-date"
                      lineHeight="24px"
                      color={defaultTheme.colors.gray}
                    >
                      Отключение доступно
                      <br />
                      с&nbsp;{refusalDate}
                    </Text>
                  )}
                </>
              )}
              {transformerInfo && (
                <div className="package-description__transformer__button-block">
                  <FillableScale
                    value={editOrderSum}
                    maxValue={transformationLimit}
                  >
                    <Text className="text" lineHeight="24px">
                      {textUnderScale}
                    </Text>
                  </FillableScale>
                  {[
                    PackageConnectionStates.NotConnected,
                    PackageConnectionStates.AddedToTransformer,
                    PackageConnectionStates.Connected,
                    PackageConnectionStates.RemovedFromTheTransformer,
                    PackageConnectionStates.RefusalIssued,
                  ].includes(connection) &&
                    !(
                      isLightbox &&
                      connection === PackageConnectionStates.RefusalIssued
                    ) && (
                      <div className="package-description__transformer__button-block__refusal-block">
                        {isLightbox ? (
                          <LinkWrapper href="/tv/channels">
                            <Button
                              className="package-description__transformer__button-block__refusal-block__button"
                              styleType={ButtonStyleTypes.SECONDARY}
                            >
                              {isDesktop700
                                ? 'Управлять Трансформером'
                                : 'Управлять'}
                            </Button>
                          </LinkWrapper>
                        ) : (
                          <Button
                            onClick={() => transformerClick()}
                            icon={
                              [
                                PackageConnectionStates.NotConnected,
                                PackageConnectionStates.RemovedFromTheTransformer,
                              ].includes(connection) && (
                                <Icon icon={<Icons.PlusIcon />} />
                              )
                            }
                            styleType={
                              [
                                PackageConnectionStates.AddedToTransformer,
                                PackageConnectionStates.Connected,
                              ].includes(connection)
                                ? ButtonStyleTypes.SECONDARY
                                : undefined
                            }
                            disabled={
                              connection ===
                              PackageConnectionStates.RefusalIssued
                            }
                          >
                            {transformerButtonText}
                          </Button>
                        )}
                        {connection === PackageConnectionStates.Connected && (
                          <>
                            {isRejectionAvailable ? (
                              <Snoska>
                                Отключение доступно сразу после применения
                                Трансформера
                              </Snoska>
                            ) : (
                              <Snoska>
                                Отключение доступно
                                {isDesktop500 && <br />}
                                с&nbsp;{refusalDate}
                              </Snoska>
                            )}
                          </>
                        )}
                        {connection ===
                          PackageConnectionStates.RefusalIssued && (
                          <Snoska>
                            Заново добавить пакет вы сможете
                            <br />
                            после {shutdownDate}
                          </Snoska>
                        )}
                      </div>
                    )}
                </div>
              )}
              {connection === PackageConnectionStates.NotConnected &&
                !transformerInfo &&
                !isCannotBeConnected && (
                  <div className="package-description__subcription-cost__button-block">
                    <Button
                      onClick={() => setIsOpenConnectionSP(true)}
                      disabled={isAllConnected || isDisableButton}
                      loading={isLoadingButton}
                    >
                      Подключить пакет
                    </Button>
                    {isAllConnected && (
                      <Snoska>
                        Все каналы из&nbsp;пакета уже доступны для просмотра
                      </Snoska>
                    )}
                  </div>
                )}
            </div>
          </Row>
        )}

      {transformerInfo && price === null && !allChannelsUsed && (
        <Row title="Управление подпиской">
          <div className="package-description__add-all-channels">
            <FillableScale value={editOrderSum} maxValue={transformationLimit}>
              <Text className="text" lineHeight="24px">
                {textUnderScale}
              </Text>
            </FillableScale>
            <Button
              icon={<Icon icon={<Icons.PlusIcon />} />}
              onClick={addAllChannels}
            >
              Добавить каналы
            </Button>
          </div>
        </Row>
      )}

      <Row
        title={rowTitle()}
        description={rowDescription()}
        removeLine={
          connection !== PackageConnectionStates.Connected ||
          (transformationLimit &&
            isLightbox &&
            connection === PackageConnectionStates.Connected)
        }
      >
        <div className="package-description__channels-n-services">
          {isTimeshiftIncluded && (
            <ServiceCardWrapper
              service={{
                name: ServiceNames.VIEW_CONTROL,
                code: ServiceCode.viewControl,
              }}
              onClick={() =>
                onClickServiceCard({
                  name: ServiceNames.VIEW_CONTROL,
                  code: ServiceCode.viewControl,
                })
              }
            />
          )}
          {services?.length > 0 &&
            services.map((service) => {
              return (
                <ServiceCardWrapper
                  key={service.name}
                  service={service}
                  onClick={() => onClickServiceCard(service)}
                />
              );
            })}
          {connectedChannelsInPackage?.length > 0 &&
            connectedChannelsInPackage.map((channel) => {
              return (
                <ChannelCard
                  key={channel.name}
                  channel={channel}
                  onClick={() => onClickChannelCard(channel)}
                />
              );
            })}
        </div>
      </Row>

      {connection === PackageConnectionStates.Connected && !transformerInfo && (
        <Row title="Управление подпиской" removeLine>
          <div className="package-description__subscription-management">
            <Button
              styleType={ButtonStyleTypes.SECONDARY}
              onClick={disablePackage}
              disabled={isDisableButton}
              loading={isLoadingButton}
            >
              {isDesktop940 ? 'Отключить пакет' : 'Отключить'}
            </Button>
            {isRejectionAvailable ? (
              <Snoska>Станет недоступен сразу после отключения</Snoska>
            ) : (
              <Snoska>Отключение доступно с&nbsp;{refusalDate}</Snoska>
            )}
          </div>
        </Row>
      )}
      <Portal>
        <SidePage
          show={isOpenConnectionSP}
          headerText={
            !result.isResult &&
            `${
              isConnection ? 'Подключение' : 'Отключение'
            } пакета ${channelPackName}`
          }
          onCloseClick={() => {
            setIsOpenConnectionSP(false);
            setResult(INITIAL_RESULT);
          }}
        >
          <PackageConnectionWizard
            isConnection={isConnection}
            result={result}
            setResult={setResult}
            connectedChannelsInPackage={connectedChannelsInPackage}
            connectedChannels={connectedChannels}
          />
        </SidePage>
      </Portal>
    </StyledPackageDescriptionCard>
  );
};

export default observer(PackageDescriptionCard);
