import React from 'react';

interface GetDerivedStateFromErrorReturn {
  hasError: boolean;
}

/**
 * hoc для ошибок рендера блока
 * https://reactjs.org/docs/error-boundaries.html
 * - на классе, так как жизненный цикл componentDidCatch ещё не появился в FC
 */
export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<unknown>,
  { hasError: boolean }
> {
  constructor(props: React.PropsWithChildren<unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): GetDerivedStateFromErrorReturn {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <></>;
    }

    return children;
  }
}
