/** libraries */
import { FC } from 'react';
import {
  defaultTheme,
  Icon,
  Icons,
  LeadingText,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
/** components */
import StoresList from './StoresList';
import { Col } from '../Grid/Col';
import { Row } from '../Grid/Row';
/** styles */
import {
  ImageWrapper,
  StyledAppBlock,
  StyledFooter,
  StyledFooterContainer,
  StyledLegal,
  StyledMenu,
  StyledSocial,
  StyledStores,
  StyledTitle,
} from './style';
/** utils */
import { parseHtml } from '../Blocks/Shared/Shared.utils';
import { isExternal } from '~/utils/utils';
import LinkWrapper from '../LinkWrapper';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useFooterStore from './store/useFooterStore';
/** constants */
import { desktop940 } from '../Grid/constants';
import { iconLinks, SOCIAL } from './constants';

/**
 * Компонент Футер портала
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=804432413
 */
const Footer: FC = () => {
  const {
    legal,
    qrCodeMobileApp,
    currentYear,
    menuLinks,
    storeLinks,
    qrLink,
  } = useFooterStore();
  const {
    authStore: { isMenuOpen, setIsMenuOpen },
  } = useRootStore();

  const socialIcons = storeLinks.filter((item) =>
    Object.values(SOCIAL).includes(item.name as SOCIAL),
  );

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const imageLoader = ({ src, width, quality }) => {
    const clientSrc = `${src}?w=${width}&q=${quality || 100}`;
    return isExternal(src)
      ? clientSrc
      : `${process.env.STATIC_SERVER}/${clientSrc}`;
  };

  const title = isDesktop940 ? (
    <LeadingText color={defaultTheme.colors.black}>
      Скачайте
      <LinkWrapper href={qrLink.slug} target="_blank">
        <LeadingText color={defaultTheme.colors.planeta}>Планета</LeadingText>
      </LinkWrapper>
      в&nbsp;магазине приложений
    </LeadingText>
  ) : (
    <Text color={defaultTheme.colors.black}>
      Скачайте
      <LinkWrapper href={qrLink.slug} target="_blank">
        <Text color={defaultTheme.colors.planeta}>Планета</Text>
      </LinkWrapper>
      в&nbsp;магазине приложений
    </Text>
  );

  return (
    <StyledFooter>
      <Row key="footer" backgroundColor={defaultTheme.colors.light}>
        <Col size={3}>
          <StyledFooterContainer>
            <StyledAppBlock>
              <Tag
                color={defaultTheme.colors.white}
                colorTag={defaultTheme.colors.planeta}
                backgroundColor={defaultTheme.colors.planeta}
              >
                НОВОЕ
              </Tag>
              <StyledTitle>{title}</StyledTitle>
              <StyledStores>
                {isDesktop940 && (
                  <ImageWrapper>
                    <Image
                      loader={imageLoader}
                      src={qrCodeMobileApp}
                      alt="qrCode"
                      width={112}
                      height={112}
                    />
                  </ImageWrapper>
                )}
                <StoresList />
              </StyledStores>
            </StyledAppBlock>
            {isDesktop940 && (
              <StyledMenu>
                <Icon
                  icon={<Icons.HamburgerIcon />}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  onKeyPress={() => setIsMenuOpen(!isMenuOpen)}
                  role="button"
                  tabIndex={0}
                />
                <div>
                  {menuLinks.map((item) => {
                    return (
                      <LinkWrapper href={item.slug} target="_blank">
                        <Text lineHeight="24px">{item.name}</Text>
                      </LinkWrapper>
                    );
                  })}
                  <StyledSocial>
                    {socialIcons.map((item) => {
                      return (
                        <LinkWrapper href={item.slug} target="_blank">
                          <Image
                            loader={imageLoader}
                            src={iconLinks[item.name]}
                            alt={item.name}
                            width={48}
                            height={48}
                          />
                        </LinkWrapper>
                      );
                    })}
                  </StyledSocial>
                </div>
              </StyledMenu>
            )}
            <StyledLegal>{parseHtml(legal)}</StyledLegal>
            <StyledLegal>
              {parseHtml(
                `&copy;&nbsp;${currentYear}&nbsp;ООО &laquo;Комтехцентр&raquo; Все права защищены.`,
              )}
            </StyledLegal>
          </StyledFooterContainer>
        </Col>
      </Row>
    </StyledFooter>
  );
};

export default observer(Footer);
