/** libraries */
import { useEffect } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** styles */
import { StyledVacationShutdown } from './styles';

/** library components */
import {
  defaultTheme,
  Icon,
  Icons,
  LeadingText,
  Text,
  H2,
  H3,
  Loader,
} from 'cordis-core-ui-planeta';

/** constants */
import { desktop1280, desktop940 } from '~/components/Grid/constants';

/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { useRootStore } from '~/stores/RootStore';

/** store */

const VacationShutdown = () => {
  const {
    vacationStore: {
      isOrdered,
      isResult,
      isCorrect,
      agreement,
      getVacationAgreement,
    },
  } = useRootStore();

  /** Дата начала действия услуги и отключения услуги */
  const todayDate = format(new Date(), 'd MMMM', {
    locale: ru,
  });

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  useEffect(() => {
    getVacationAgreement();
  }, []);

  return (
    <StyledVacationShutdown isSuccessShutdown={isResult && isCorrect}>
      {isResult && isCorrect && (
        <>
          <Icon icon={<Icons.OkBigIcon />} />
          {isDesktop1280 && (
            <H2 className="shutdown__success__header">
              Услуга Каникулы
              <br />
              отключена
            </H2>
          )}
          {isDesktop940 && !isDesktop1280 && (
            <H3 className="shutdown__success__header">
              Услуга Каникулы
              <br />
              отключена
            </H3>
          )}
          {!isDesktop940 && (
            <LeadingText
              className="shutdown__success__header"
              color={defaultTheme.colors.black}
            >
              Услуга Каникулы
              <br />
              отключена
            </LeadingText>
          )}
        </>
      )}
      {isResult && !isCorrect && (
        <>
          <Icon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1280 && (
            <H2 className="shutdown__success__header">
              Что-то пошло не так...
            </H2>
          )}
          {isDesktop940 && !isDesktop1280 && (
            <H3 className="shutdown__success__header">
              Что-то пошло не так...
            </H3>
          )}
          {!isDesktop940 && (
            <LeadingText
              className="shutdown__success__header"
              color={defaultTheme.colors.black}
            >
              Что-то пошло не так...
            </LeadingText>
          )}
        </>
      )}
      {!isResult && (
        <>
          <div className="shutdown__block">
            <Text color={defaultTheme.colors.shadow} lineHeight="24px">
              {isOrdered
                ? 'Подключение Услуги будет возможно'
                : 'Отключение Услуги'}
            </Text>
            <LeadingText color={defaultTheme.colors.black}>
              {todayDate}
            </LeadingText>
          </div>
          <div className="shutdown__agreement">
            <Text lineHeight="24px" fontWeightBold>
              Я&nbsp;ознакомлен(а) и&nbsp;согласен(на) с&nbsp;условиями
              предоставления и&nbsp;оплаты услуги &laquo;Каникулы&raquo;
            </Text>
            {agreement ? (
              <Text lineHeight="24px">{parseHtml(agreement)}</Text>
            ) : (
              <div>
                <Loader small />
              </div>
            )}
          </div>
        </>
      )}
    </StyledVacationShutdown>
  );
};

export default observer(VacationShutdown);
