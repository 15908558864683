/** библиотеки */
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme, Icon, Link } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop1280,
  desktop1100,
  desktop940,
  desktop440,
} from '~/components/Grid/constants';

/** типы */
import { StyledSummaryDescriptionProps } from './Summary.types';

/**
 * Styled-component для отображения блока summary
 */
export const StyledSummary = styled.div<{
  $isBusiness?: boolean;
  stripColor?: string;
}>`
  ${({ $isBusiness, stripColor }): string => {
    const getStripColor = () => {
      if (stripColor) return stripColor;
      if ($isBusiness) return defaultTheme.colors.greyGraphite;
      return defaultTheme.colors.planeta;
    };
    return `
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 16px solid ${getStripColor()};
  border-right: 1px solid ${defaultTheme.colors.disable};
  border-bottom: 1px solid ${defaultTheme.colors.disable};
  border-left: 1px solid ${defaultTheme.colors.disable};
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  div[data-test-tabs] {
    span {
      line-height: 24px;
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    border-left: 16px solid ${getStripColor()};
    border-top: 1px solid ${defaultTheme.colors.disable};
    border-right: 1px solid ${defaultTheme.colors.disable};
    border-bottom: 1px solid ${defaultTheme.colors.disable};
    border-bottom-left-radius: 1em;
    border-top-right-radius: initial;
  }
  .change-connection-success {
    margin: -51px 0 26.5px;
  }
    `;
  }}
`;

/**
 * Styled-component для отображения описания
 */
export const StyledSummaryDescription = styled.div<StyledSummaryDescriptionProps>`
  ${({ $isBusiness, $action }): string => {
    return `
    ${
      $action?.background
        ? `
        background: url(${process.env.STATIC_SERVER}/${$action.background});
        background-size: cover;
        background-repeat: no-repeat;`
        : `background: ${defaultTheme.colors.white};`
    }
    width: 100%;
    margin-bottom: 16px;

    > div {
      height: 100%;
    }

    .padding {
      padding: 20px 32px 0;
    }

    .container {
      height: 100%;
      
      > a {
        text-decoration: none;
      }
    }

    .title {
      display: block;
      margin: 0;
    }

    .tags {
      display: flex;
      margin: 0 0 8px;
      > div {
        & > div {
          display: flex;
        }
      }
    }

    .tags > div {
      margin-right: 8px;
    }

    a:hover {
      text-decoration: none;
    }

    @media (min-width: ${desktop940}px) {
      min-width: 200px;
      width: 200px;
      margin: 0 32px 0 0;
      .padding {
        padding: 31px 0 31px 16px;
      }
      .subtitle {
        margin-top: 8px;
      }
      .tags {
        margin: 0 0 16px;
      }
    }
    
    @media (min-width: ${desktop1100}px) {
      min-width: 244px;
      width: 244px;
    }
    @media (min-width: ${desktop1280}px) {
      min-width: 320px;
      width: 320px;
    }
  `;
  }}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

/**
 * Styled-component для отображения информации
 */
export const StyledSummaryInfo = styled.div`
  display: flex;
  padding: 0 32px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 0;

  .info-statistics {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    > div {
      margin-bottom: 6px;
    }
    > div:last-of-type {
      margin-bottom: 0;
    }
  }
  .internet {
    display: flex;
    flex-direction: row;
    &__name-wrapper {
      display: flex;
    }
  }
  .icon {
    margin-right: 16px;
  }
  .value {
    display: block;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 32px 0;
    width: 440px;

    .info-statistics {
      display: grid;
      gap: 16px;
      grid-template-columns: 136px 136px 136px;
      grid-template-rows: none;
      margin-bottom: 36px;
    }
    .internet {
      flex-direction: column;
    }
  }

  @media (min-width: ${desktop1100}px) {
    width: 498px;
    .info-statistics {
      grid-template-columns: 155px 155px 155px;
    }
    .internet {
      &__name {
        width: auto;
      }
    }
  }
  @media (min-width: ${desktop1280}px) {
    width: 560px;
    .info-statistics {
      grid-template-columns: 176px 176px 176px;
    }
  }
`;

/**
 * Styled-component для отображения табов
 */
export const StyledTabs = styled.div`
  width: max-content;

  > span:first-of-type {
    display: flex;
    align-items: center;

    .action-discount ~ .value {
      margin-top: 0;
      margin-left: 15px;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  .value {
    margin: 0 0 0 8px;
  }

  @media (min-width: ${desktop940}px) {
    max-width: 136px;
    .value {
      margin: 4px 0 0 0;
    }
  }

  @media (min-width: ${desktop1100}px) {
    max-width: 155px;
    .value__margin {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${desktop1280}px) {
    max-width: 176px;
  }
`;

/**
 * Styled-component для отображения цен
 */
export const StyledSummaryCost = styled.div<{ $hasSecondIcon: boolean }>`
  padding: 0 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  align-items: unset;
  width: 100%;
  margin-top: 32px;

  > span:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  .value {
    margin-bottom: ${({ $hasSecondIcon }) => ($hasSecondIcon ? '6px' : '0')};
  }
  .action-secondIcon {
    width: 141px;
    height: 79px;
    margin: 9px 0 24px 0;
  }

  .action-discount {
    display: flex;
    flex-direction: column;

    > span {
      font-size: 11px;
      line-height: 13px;

      &:first-of-type {
        font-weight: 700;
      }

      &:last-of-type {
        font-weight: 400;
        text-decoration: line-through;
      }
    }
  }

  .connected-text {
    width: max-content;
  }

  .ftth-text {
    width: 100%;
  }

  button {
    width: 100%;
    padding: 14px 20px;
    span {
      letter-spacing: -0.03em;
    }
  }

  @media (min-width: ${desktop440}px) {
    > span:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .action-secondIcon {
      margin: 0 0 24px 30px;
    }
  }

  @media (min-width: ${desktop940}px) {
    align-items: ${({ $hasSecondIcon }) =>
      $hasSecondIcon ? 'center' : 'flex-start'};
    flex-direction: column;
    margin: 0 0 0 32px;
    padding: 32px 32px 32px 0;
    width: 184px;

    > span:first-of-type {
      margin-bottom: 32px;
    }

    h3 {
      margin-top: 4px;
    }

    .action-secondIcon {
      margin: 0 0 15px 30px;
    }

    .value {
      margin-bottom: 0;
    }

    button {
      width: auto;
    }

    .ftth-text {
      width: 152px;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: -0.01em;
      font-weight: 400;
      a {
        font-size: 11px;
      }
    }
  }

  @media (min-width: ${desktop1100}px) {
    align-items: ${({ $hasSecondIcon }) =>
      $hasSecondIcon ? 'center' : 'flex-start'};
    flex-direction: column;
    width: 242px;

    .action-secondIcon {
      display: none;
    }

    button {
      padding: 14px 40px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    align-items: baseline;
    width: 304px;
    .button {
      &__content {
        white-space: nowrap;
      }
    }
    .ftth-text {
      width: 222px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.03em;
      font-weight: 400;
      a {
        font-size: 16px;
      }
    }
    .connected-text {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
  }
`;

export const StyledIcon = styled(Icon)`
  svg {
    margin: -40px 0 20px 0;
  }
`;

export const StyledConnect = styled.div`
  > span:first-of-type {
    display: block;
    /* margin-bottom: 8px; */
  }

  > div {
    > a {
      cursor: default;
      > span {
        & > span {
          cursor: pointer;
        }
      }
    }
  }

  a:hover {
    text-decoration: none;
  }
`;
