import { createContext, useContext } from 'react';
import vacationStateModel, { IVacationStateStore } from './VacationModel';

const store = vacationStateModel.create({
  isChecked: false,
  /** SidePage подключения услуги */
  isVacationConnectionOpen: false,
  /** SidePage отключения услуги */
  isVacationShutdownOpen: false,
});

const VacationStoreContext = createContext<IVacationStateStore>(store);

const useVacationStateStore = (): IVacationStateStore => {
  return useContext(VacationStoreContext);
};

export default useVacationStateStore;
