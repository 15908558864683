/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Consent, defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import { isToday, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** styles */
import { ApplyTransformerWizardStyled } from './styles';

/** interface */
import { ResultProps } from '../../common/SidePageResult/types';
import { Channel, ChannelPackage } from '../../../interfaces';

/** components */
import SidePageResult from '../../common/SidePageResult';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';

/** api */
import {
  checkITvTimeshiftBindCondition,
  getTransformerAgreement,
} from '~/api/apiPab2c';

/** utils */
import { formatNumber } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { getDate } from '../../../utils';

/** constants */
import { channelFormats } from '../../../constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';

export interface ApplyTransformerWizardProps {
  result: ResultProps;
  setIsDisableConnection: Dispatch<SetStateAction<boolean>>;
  setIsLoadingConnection: Dispatch<SetStateAction<boolean>>;
  /** Галочка чекбокса управления просмотром */
  isViewControlCheckbox: boolean;
  setIsViewControlCheckbox: Dispatch<SetStateAction<boolean>>;
}

const ApplyTransformerWizard: FC<ApplyTransformerWizardProps> = ({
  result,
  setIsDisableConnection,
  setIsLoadingConnection,
  isViewControlCheckbox,
  setIsViewControlCheckbox,
}: ApplyTransformerWizardProps) => {
  const {
    summaryDataStore: { transformationLimit },
    tvStore: {
      tvChannelPackages,
      transformerInfo,
      connectedChannelsOutsidePackages,
      connectedTVPackages,
      viewControlInfo,
      editOrderSum,
      idsAddedPacketsToTransformer,
      idsAddedChannelsToTransformer,
      channelsList,
    },
  } = useRootStore();

  /** Пакеты */
  const addedPackets = useMemo(() => {
    return idsAddedPacketsToTransformer?.length
      ? tvChannelPackages.filter((packet) => {
          return idsAddedPacketsToTransformer.find((id) => packet.id === id);
        })
      : [];
  }, [idsAddedPacketsToTransformer, tvChannelPackages]);

  /** Удалённые пакеты */
  const removedPackets = useMemo(() => {
    return connectedTVPackages.filter((item) => {
      return !idsAddedPacketsToTransformer.includes(item.channelPackId);
    });
  }, []);

  /** Управление просмотром добавлен  */
  const [addViewControl, setAddViewControl] = useState<boolean>(false);

  /** Галочка Управления просмотром */
  const isAddViewControl = useMemo(() => {
    /** В удалённых пакетах было управление просмотром */
    let wasViewControl = false;
    /** Оставшиеся пакеты */
    const remainingPackets = idsAddedPacketsToTransformer.filter(
      (item) =>
        !removedPackets.find((pack) => {
          if (pack.isTimeshiftIncluded) wasViewControl = true;
          return item === pack.channelPackId;
        }),
    );
    /** В оставшихся пакетах есть Управление просмотром */
    const isViewControlInPacks = connectedTVPackages
      .filter(
        (item) =>
          remainingPackets.includes(item.channelPackId) && !item.unbindDt,
      )
      .some((item) => item.isTimeshiftIncluded);

    /** Добавлен пакет, в котором есть Управление просмотром */
    if (
      addedPackets.find((item) => item.isTimeshiftIncluded) &&
      !isViewControlInPacks &&
      !viewControlInfo?.isOrdered
    ) {
      setAddViewControl(true);
    }
    return wasViewControl && !isViewControlInPacks;
  }, [
    removedPackets,
    idsAddedPacketsToTransformer,
    connectedTVPackages,
    addedPackets,
  ]);

  /** Каналы из трансформера */
  const allChannels = useMemo(() => {
    return channelsList.filter((canal) => {
      const added = idsAddedChannelsToTransformer.find((id) =>
        canal.singleChannelPacks?.length
          ? canal.singleChannelPacks[0].id === id
          : null,
      );
      const removed = connectedChannelsOutsidePackages.find((id) => {
        return canal?.weburgId ? canal.weburgId === id : canal.id === id;
      });
      return added || removed;
    });
  }, [idsAddedChannelsToTransformer, channelsList]);

  /** Добавленные каналы */
  const addedChannels = useMemo(() => {
    return allChannels.filter(
      (item) =>
        !connectedChannelsOutsidePackages.includes(item.weburgId ?? item.id) &&
        !addedPackets.find((pack) =>
          pack.channelPackChannels.find((canal) =>
            canal?.weburgId && item?.weburgId
              ? canal.weburgId === item.weburgId
              : canal.id === item.id,
          ),
        ),
    );
  }, [allChannels, connectedChannelsOutsidePackages, addedPackets]);

  /** Удалённые каналы */
  const removedChannels = useMemo(() => {
    return allChannels.filter(
      (item) =>
        !idsAddedChannelsToTransformer.includes(
          item?.singleChannelPacks[0]?.id,
        ) &&
        connectedChannelsOutsidePackages.includes(item.weburgId ?? item.id),
    );
  }, []);

  /** Даты отключения каналов и пакетов */
  const banTrimDates = useMemo(() => {
    return [
      ...new Set(
        [...removedPackets, ...removedChannels].map((item) => item.banTrimDate),
      ),
    ];
  }, []);

  /** Две шкалы */
  const isTwoScales =
    banTrimDates.length > 1 ||
    (banTrimDates.length === 1 &&
      !isToday(parseISO(banTrimDates[0])) &&
      [...addedPackets, ...addedChannels].length);

  /** Есть 4K каналы */
  const is4K = addedChannels.some(
    (item) => item.channelDefinition === channelFormats[2].label,
  );

  /** Названия подключённых пакетов и каналов */
  const addedNames = useMemo(() => {
    const packNames = addedPackets.map((item) => item.channelPackName);
    const channelNames = addedChannels.map((item) => item.name);
    return [...packNames, ...channelNames]
      .join(', ')
      .concat(addViewControl ? ' и сервису «Управление просмотром»' : '');
  }, [addedPackets, addedChannels, addViewControl]);

  /** Названия отключённых пакетов и каналов */
  const removedNames = (date?: string) => {
    const packNames = removedPackets
      .filter((item) => (date ? item.banTrimDate === date : item))
      .map((item) => item.channelPackName);
    const channelNames = removedChannels
      .filter((item) => (date ? item.banTrimDate === date : item))
      .map((item) => item.name);
    return [...packNames, ...channelNames].join(', ');
  };

  /** Сумма за доп. каналы */
  const additionalChannelsInValue = (sum: number) => {
    if (sum > transformationLimit)
      return formatNumber(sum - transformationLimit);
    return 0;
  };

  /** Текст соглашения */
  const [agreement, setAgreement] = useState<string>('');
  /** Получает соглашение */
  const getAgreement = async () => {
    try {
      const res = await getTransformerAgreement([
        ...idsAddedPacketsToTransformer,
        ...idsAddedChannelsToTransformer,
      ]);
      setAgreement(res);
    } catch (e) {
      setIsDisableConnection(true);
    } finally {
      setIsLoadingConnection(false);
    }
  };
  useEffect(() => {
    getAgreement();
  }, []);

  /** Дата */
  const stringDate = (newDate?: string) => {
    return newDate ? getDate(newDate) : getDate(new Date().toISOString());
  };

  /** Сумма добавленных пакетов */
  const addedSum = () => {
    const sum = (([...addedPackets, ...addedChannels] as unknown) as (
      | ChannelPackage
      | Channel
    )[]).reduce((acc, item) => {
      if ((item as Channel)?.singleChannelPacks?.length) {
        return acc + (item as Channel).singleChannelPacks[0].price;
      }
      if ((item as ChannelPackage)?.price) {
        return acc + item.price;
      }
      return acc;
    }, 0);
    return transformerInfo?.orderSum + sum;
  };

  /** Сумма удалённых пакетов */
  const removedSum = (date: string) => {
    const packets = removedPackets.filter((item) => item.banTrimDate === date);
    const channels = removedChannels.filter(
      (item) => item.banTrimDate === date,
    );

    const sum = (([...packets, ...channels] as unknown) as (
      | ChannelPackage
      | Channel
    )[]).reduce((acc, item) => {
      if ((item as Channel)?.singleChannelPacks?.length) {
        return acc - (item as Channel).singleChannelPacks[0].price;
      }
      if ((item as ChannelPackage)?.price) {
        return acc - item.price;
      }
      return acc;
    }, transformerInfo?.orderSum);
    return sum;
  };

  /** Можно подключить Управление просмотром */
  const [canConnectViewControl, setCanConnectViewControl] = useState<boolean>(
    false,
  );

  /** Проверяет, можно ли для договора подключить услугу "Управление просмотром" */
  const proofITvTimeshiftBindCondition = async () => {
    try {
      await checkITvTimeshiftBindCondition();
      setCanConnectViewControl(true);
    } catch (e) {
      setCanConnectViewControl(false);
    }
  };

  useEffect(() => {
    if (isAddViewControl) {
      setIsViewControlCheckbox(true);
      proofITvTimeshiftBindCondition();
    }
  }, [isAddViewControl]);

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    if (transformationLimit > editOrderSum) {
      return (
        <Text className="text" lineHeight="24px">
          {formatNumber(editOrderSum)} ₽ за счёт трансформации
        </Text>
      );
    }
    return (
      <Text className="text" lineHeight="24px">
        <Text lineHeight="24px" fontWeightBold>
          лимит превышен:{'\u00A0'}
        </Text>
        {formatNumber(editOrderSum - transformationLimit)} ₽ в день за доп.
        каналы
      </Text>
    );
  }, [editOrderSum, transformationLimit]);

  return (
    <ApplyTransformerWizardStyled>
      {!result.isResult ? (
        <div className="apply">
          {!isTwoScales ? (
            <>
              {[...removedPackets, ...removedChannels].length > 0 && (
                <div className="apply__block">
                  <Text
                    className="apply__block__header"
                    lineHeight="24px"
                    fontWeightBold
                  >
                    С&nbsp;{stringDate(banTrimDates[0])} г. будет ограничен
                    доступ к&nbsp;пакетам и&nbsp;телеканалам
                  </Text>
                  <Text lineHeight="24px">{removedNames()}</Text>
                </div>
              )}
              {[...addedPackets, ...addedChannels].length > 0 && (
                <div className="apply__block">
                  <Text
                    className="apply__block__header"
                    lineHeight="24px"
                    fontWeightBold
                  >
                    С&nbsp;{stringDate()} г. будет предоставлен доступ
                    к&nbsp;пакетам и&nbsp;телеканалам
                  </Text>
                  <Text lineHeight="24px">{addedNames}</Text>
                </div>
              )}
              <div className="apply__block">
                <FillableScale
                  value={editOrderSum}
                  maxValue={transformationLimit}
                >
                  {textUnderScale}
                </FillableScale>
              </div>
              <div className="apply__price">
                <div className="apply__price__block">
                  <Text lineHeight="24px">Лимит трансформации, в день</Text>
                  <H3>{formatNumber(transformationLimit)} ₽</H3>
                </div>
                <div className="apply__price__block">
                  <Text lineHeight="24px">За доп. каналы в день</Text>
                  <H3 color={defaultTheme.colors.planeta}>
                    {additionalChannelsInValue(editOrderSum)} ₽
                  </H3>
                </div>
                {editOrderSum - transformationLimit > 0 && (
                  <div className="apply__price__block">
                    <Text lineHeight="24px">Минимальный срок подключения</Text>
                    <H3>1 день</H3>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {[...addedPackets, ...addedChannels].length > 0 && (
                <>
                  <div className="apply__block">
                    <Text
                      className="apply__block__header"
                      lineHeight="24px"
                      fontWeightBold
                    >
                      С&nbsp;{stringDate()} г. будет предоставлен доступ
                      к&nbsp;пакетам и&nbsp;телеканалам
                    </Text>
                    <Text lineHeight="24px">{addedNames}</Text>
                  </div>
                  <div className="apply__block">
                    <FillableScale
                      value={addedSum()}
                      maxValue={transformationLimit}
                    >
                      {textUnderScale}
                    </FillableScale>
                  </div>
                  <div className="apply__price">
                    <div className="apply__price__block">
                      <Text lineHeight="24px">Лимит трансформации, в день</Text>
                      <H3>{formatNumber(transformationLimit)} ₽</H3>
                    </div>
                    <div className="apply__price__block">
                      <Text lineHeight="24px">За доп. каналы в день</Text>
                      <H3 color={defaultTheme.colors.planeta}>
                        {additionalChannelsInValue(addedSum())} ₽
                      </H3>
                    </div>
                    {addedSum() - transformationLimit > 0 && (
                      <div className="apply__price__block">
                        <Text lineHeight="24px">
                          Минимальный срок подключения
                        </Text>
                        <H3>1 день</H3>
                      </div>
                    )}
                  </div>
                </>
              )}
              {banTrimDates.map((item) => (
                <>
                  <div className="apply__block">
                    <Text
                      className="apply__block__header"
                      lineHeight="24px"
                      fontWeightBold
                    >
                      С&nbsp;{stringDate(item)} г. будет ограничен доступ
                      к&nbsp;пакетами&nbsp;телеканалам
                    </Text>
                    <Text lineHeight="24px">{removedNames(item)}</Text>
                  </div>
                  <div className="apply__block">
                    <FillableScale
                      value={removedSum(item)}
                      maxValue={transformationLimit}
                    >
                      {textUnderScale}
                    </FillableScale>
                  </div>
                  <div className="apply__price">
                    <div className="apply__price__block">
                      <Text lineHeight="24px">
                        Лимит трансформации, в&nbsp;день
                      </Text>
                      <H3>{formatNumber(transformationLimit)} ₽</H3>
                    </div>
                    <div className="apply__price__block">
                      <Text lineHeight="24px">
                        За&nbsp;доп. каналы в&nbsp;день
                      </Text>
                      <H3 color={defaultTheme.colors.planeta}>
                        {additionalChannelsInValue(removedSum(item))} ₽
                      </H3>
                    </div>
                    {removedSum(item) - transformationLimit > 0 && (
                      <div className="apply__price__block">
                        <Text lineHeight="24px">
                          Минимальный срок отключения
                        </Text>
                        <H3>1 день</H3>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </>
          )}
          {is4K && (
            <div className="apply__yellow-block">
              <Text lineHeight="24px" fontWeightBold>
                Формат вещания 4К UHD
              </Text>
              <Text>
                Телеканалы в&nbsp;формате изображения сверхвысокой чёткости
                (Ultra High-Definition TV&nbsp;4K) доступны исключительно
                на&nbsp;телеприставках с&nbsp;предустановленным
                ПО&nbsp;Оператора и&nbsp;с&nbsp;поддержкой воспроизведения
                изображения в&nbsp;данном формате.
              </Text>
            </div>
          )}
          {canConnectViewControl && (
            <div className="apply__view-control">
              <Consent
                header="Подключить Управление просмотром"
                text={`С ${stringDate()} года с моего Лицевого Счёта будет списываться плата за доступ к cервису «Управление просмотром» в размере ${
                  viewControlInfo.price
                } ₽ в день.`}
                onChangeCheckbox={(e) =>
                  setIsViewControlCheckbox(e.target.checked)
                }
                checked={isViewControlCheckbox}
              />
            </div>
          )}
          <div className="apply__agreement">
            <Text lineHeight="24px" fontWeightBold>
              Согласие на получение услуги «Трансформация»
            </Text>
            <Text>{parseHtml(agreement)}</Text>
          </div>
        </div>
      ) : (
        <SidePageResult result={result} />
      )}
    </ApplyTransformerWizardStyled>
  );
};

export default observer(ApplyTransformerWizard);
