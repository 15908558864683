/** libraries */
import { getYear } from 'date-fns';
import { types, Instance } from 'mobx-state-tree';
/** interfaces */
import { FooterInfo } from '~/components/Feed/FeedPage.types';
/** utils */
import { getStoresByDeviceType } from '../utils';

const StoreLinkModel = types.model({
  name: types.string,
  slug: types.string,
});

const MenuLinkModel = types.model({
  name: types.string,
  slug: types.maybeNull(types.string),
  widget: types.maybeNull(types.string),
});

const FooterContentModel = types.model({
  storeLinks: types.array(StoreLinkModel),
  menuLinks: types.array(MenuLinkModel),
  qrCodeMobileApp: types.string,
  legal: types.string,
});

const FooterModel = types
  .model({
    content: FooterContentModel,
  })
  .views((self) => ({
    get storeLinks() {
      return self.content.storeLinks;
    },
    get menuLinks() {
      return self.content.menuLinks;
    },
    get qrCodeMobileApp() {
      return self.content.qrCodeMobileApp;
    },
    get qrLink() {
      return self.content.storeLinks.find((item) => item.name === 'planeta');
    },
    get legal() {
      return self.content.legal;
    },
    get currentYear() {
      return getYear(new Date());
    },
    get stores() {
      return getStoresByDeviceType();
    },
  }));

export const createStore = (content: FooterInfo) =>
  FooterModel.create({ content });

export type IFooterStore = Instance<typeof FooterModel>;
