/* Библиотеки */
import styled from '@emotion/styled';
/* Стили */
import { defaultTheme } from 'cordis-core-ui-planeta';
/** размеры экрана */
import { desktop500 } from '~/components/Grid/constants';
/* Интерфейсы */
import {
  IconProps,
  StyledProductSelectorArrowProps,
  StyledProductSelectorProps,
} from './ProductTemplate.interfaces';

/* Стилизованный блок выбора продукта */
export const StyledProductSelector = styled.div`
  ${
    ({ xPosition, withoutFilters }: StyledProductSelectorProps) =>
      // Считает, что отступы в padding - повторяющееся слово...
      /* yaspeller ignore:start */
      `
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: flex-start;
      justify-content: center;
      padding: 32px;
      position: relative;
      background: ${defaultTheme.colors.white};
      overflow-x: hidden;
      
      &:before {
        content: '';
        width: 32px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: white;
        z-index: 1;
      }
      
      &:after {
        content: '';
        width: 32px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        z-index: 1;
      }
      
      > span: last-of-type {
        display: flex;
        flex-direction: row;
        position: relative;
        left: ${xPosition}px;
        transition: left .5s;
        min-height: ${withoutFilters ? 'none' : '550px'};
      }

      .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 550px;
        width: 100%;
      }
    `
    /* yaspeller ignore:end */
  }
`;

/* Стилизованный блок с тегами продуктов */
export const StyledProductSelectorTags = styled.span`
  ${() => `
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      overflow-x: scroll;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      .tagButton__text {
        margin-right: 12px;
      }

      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;

/* Элемент для смещения карточек продукта влево или вправо */
export const StyledProductSelectorArrow = styled.div`
  ${
    ({ isLeft, show }: StyledProductSelectorArrowProps) =>
      // Считает, что ширина в box-shadow - повторяющееся слово...
      /* yaspeller ignore:start */
      `
      padding: 0 ${isLeft ? '2px' : '0'} 0 ${!isLeft ? '3px' : '0'};
      display: ${show ? 'flex' : 'none'};
      align-items: center;
      justify-content: center;
      background: ${defaultTheme.colors.white};
      border-radius: 50%;
      width: 50px;
      height: 50px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 358px;
      left: ${isLeft ? '9px' : 'auto'};
      right: ${!isLeft ? '9px' : 'auto'};
      cursor: pointer;
      z-index: 2;

      svg {
        transform: rotate(${isLeft ? 180 : 0}deg);
      }

      @media(min-width: ${desktop500}px) {
        padding-right: ${isLeft ? '1px' : '0'};
        width: 80px;
        height: 80px;
      }
    `
    /* yaspeller ignore:end */
  }
`;

/* Стилизованный блок с иконкой тегов */
export const StyledIcon = styled.span`
  ${({ icon, inactiveIcon, checked }: IconProps) => `
      background: url(${process.env.STATIC_SERVER}/${
    checked ? icon : inactiveIcon
  }) no-repeat center;
      width: 20px; 
      height: 21px;
    `}
`;
