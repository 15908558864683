/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Snoska, Text, defaultTheme } from 'cordis-core-ui-planeta';
import { format, isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** interfaces */
import { ResultProps } from '../../common/SidePageResult/types';

/** components */
import ToggleTabs from '../../common/ToggleTabs';
import OptionBlock from '../../common/OptionBlock';
import SidePageResult from '../../common/SidePageResult';

/** styles */
import { StyledViewControlConnectionWizard } from './styles';
import { StyledFooter } from '../../../styles';

/** api */
import {
  bindITvTimeshift,
  getITvTimeshiftBindAgreement,
  getITvTimeshiftUnbindAgreement,
  unbindITvTimeshift,
} from '~/api/apiPab2c';

/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/** constants */
import { MOUNTHS } from '~/components/Blocks/Templates/MyStoriesContest/constants';

/** props */
interface ViewControlConnectionWizardProps {
  isConnection?: boolean;
  result: ResultProps;
  setResult: Dispatch<SetStateAction<ResultProps>>;
}

/** Сайдпейдж подключения/отключения услуги "Управление просмотром" */
const ViewControlConnectionWizard: FC<ViewControlConnectionWizardProps> = ({
  isConnection,
  result,
  setResult,
}: ViewControlConnectionWizardProps) => {
  const {
    tvStore: { viewControlInfo, getViewControlInfo },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  /** Время отключения */
  const shutdownDate = useMemo(() => {
    const isAfter =
      viewControlInfo?.banTrimDate &&
      !isBefore(parseISO(viewControlInfo?.banTrimDate), new Date());
    const date = isAfter
      ? new Date(parseISO(viewControlInfo?.banTrimDate))
      : new Date();
    return date
      ? `${date.getDate().toString()} ${MOUNTHS[date.getMonth()]} ${
          !isAfter ? '' : `в ${format(date, 'HH:mm')}`
        }`
      : '';
  }, [viewControlInfo]);

  /** JSX времени отключения */
  const shutdownTime = () => (
    <div className="view-control-connection__shutdown-time">
      <OptionBlock title="Отключение произойдёт" body={shutdownDate} />
    </div>
  );

  /** disable кнопки */
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  /** Загрузка кнопки */
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(true);

  /** Соглашение */
  const [agreement, setAgreement] = useState<string>(null);

  /** Получает соглашение для прикрепления услуги "Управление просмотром" */
  const getBindAgreement = async () => {
    try {
      const res = await getITvTimeshiftBindAgreement();
      setAgreement(res);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  /** Получает соглашение для открепления услуги "Управление просмотром" */
  const getUnbindAgreement = async () => {
    try {
      const res = await getITvTimeshiftUnbindAgreement();
      setAgreement(res);
    } catch (e) {
      setIsDisableButton(true);
    } finally {
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    if (isConnection) {
      getBindAgreement();
    } else {
      getUnbindAgreement();
    }
  }, [isConnection]);

  /** Подключает услугу "Управление просмотром" */
  const bindTimeshift = async () => {
    try {
      await bindITvTimeshift();
      getViewControlInfo();
      setResult({
        isResult: true,
        isCorrect: true,
        title: 'Сервис Управление просмотром подключён',
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'}так...`,
      });
    }
  };

  /** Отключает услугу "Управление просмотром" */
  const unbindTimeshift = async () => {
    try {
      await unbindITvTimeshift();
      getViewControlInfo();
      setResult({
        isResult: true,
        isCorrect: true,
        title: 'Сервис Управление просмотром будет отключён',
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
        title: `Что-то пошло не${'\u00A0'}так...`,
      });
    }
  };

  /** Событие подключения/отключения услуги */
  const connectViewControl = () => {
    if (isConnection) {
      bindTimeshift();
    } else {
      unbindTimeshift();
    }
  };

  return (
    <StyledViewControlConnectionWizard>
      {!result.isResult ? (
        <>
          {isConnection ? (
            <>
              <div className="view-control-connection__wrapper">
                <div className="view-control-connection__wrapper__toggle-tabs">
                  <ToggleTabs cost={viewControlInfo?.price} />
                </div>
                <OptionBlock
                  title="Минимальный срок подключения"
                  body="30 дней"
                />
              </div>
              {agreement && (
                <div className="view-control-connection__block">
                  <Text lineHeight="24px" fontWeightBold>
                    Согласие на&nbsp;подключение сервиса «Управление просмотром»
                  </Text>
                  <Text lineHeight="24px">{parseHtml(agreement)}</Text>
                </div>
              )}
              <StyledFooter>
                <Button
                  onClick={connectViewControl}
                  disabled={isDisableButton || isTemporaryTokenAuth}
                  loading={isLoadingButton}
                >
                  Подключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            </>
          ) : (
            <>
              {shutdownTime()}
              {agreement && (
                <div className="view-control-connection__block">
                  <Text lineHeight="24px" fontWeightBold>
                    Согласие на&nbsp;отключение сервиса «Управление просмотром»
                  </Text>
                  <Text lineHeight="24px">{parseHtml(agreement)}</Text>
                </div>
              )}
              <StyledFooter>
                <Button
                  onClick={connectViewControl}
                  disabled={isDisableButton}
                  loading={isLoadingButton}
                >
                  Отключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            </>
          )}
        </>
      ) : (
        <SidePageResult result={result}>
          {!isConnection && result.isCorrect && shutdownTime()}
        </SidePageResult>
      )}
    </StyledViewControlConnectionWizard>
  );
};

export default observer(ViewControlConnectionWizard);
