import { DEFAULT_RESULT } from '~/constants/common';
import { defaultModelState } from '~/stores/models/createApiPathModel';

export const TABS = ['Местные', 'Россия', 'Международные', 'Экстренные'];
export const SELECTS = [
  {
    label: 'Местные',
    value: '0',
  },
  { label: 'По России', value: '1' },
  { label: 'Международные', value: '2' },
  { label: 'Экстренные службы', value: '3' },
];

export enum FORWARDING_STATES {
  /** Безусловная */
  Unconditional = 'Безусловная переадресация',
  /** Линия занята */
  Busy = 'Линия занята',
  /** Нет ответа */
  NoAnswer = 'Нет ответа',
  /** Линия недоступна */
  Unreachable = 'Линия недоступна',
}

export enum DAY_OF_THE_WEEK {
  monday = 'ПН',
  tuesday = 'ВТ',
  wednesday = 'СР',
  thursday = 'ЧТ',
  friday = 'ПТ',
  saturday = 'СБ',
  sunday = 'ВС',
}

export const LOCAL = 'local';
export const FOREIGN = 'foreign';

export enum DIRECTION {
  external = 'external',
  internal = 'internal',
}

export enum ERRORS {
  PHONE_UNBIND_LIMIT = 'PhoneUnbindExceededTryLimitException',
}

export enum SatelliteCodes {
  EKB = -1,
  TAGIL = 1,
  KAMENSK = 2,
}

export const initialAutocomplete = {
  value: '',
  forSend: '',
};

export enum StateCondition {
  Unconditional = 'Unconditional',
  Busy = 'Busy',
  NoAnswer = 'NoAnswer',
}

export const StateConditionNames = {
  Unconditional: {
    key: 'Unconditional',
    name: 'Безусловная переадресация',
  },
  Busy: {
    key: 'Busy',
    name: 'Линия занята',
  },
  NoAnswer: {
    key: 'NoAnswer',
    name: 'Нет ответа',
  },
};

export const HOURS = [];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 2; j++) {
    const hour =
      j === 0
        ? {
            label: `${i.toString().padStart(2, '0')}:00`,
            value: `0001-01-01T${i.toString().padStart(2, '0')}:00:00`,
          }
        : {
            label: `${i.toString().padStart(2, '0')}:30`,
            value: `0001-01-01T${i.toString().padStart(2, '0')}:30:00`,
          };
    HOURS.push(hour);
  }
}

export const HOURS_END = [...HOURS];
HOURS_END.shift();
HOURS_END.push(HOURS[0]);

export const MIDNIGHT = {
  label: '00:00',
  value: '0001-01-01T00:00:00',
};

export enum SaveRuleError {
  number = 'Правило переадресации/маршрутизации имеет конфликт с ранее созданным.',
  samePhone = 'Для этого номера уже настроена переадресация',
}

export enum Weeks {
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}

export const KEYS = Object.keys(Weeks).filter((v) => Number.isNaN(Number(v)));

export const DEFAULT_STATE = {
  requestsState: {
    getPhoneStatistics: defaultModelState,
    getForwarding: defaultModelState,
    getTariffVoicePrice: defaultModelState,
  },
  agreement: '',
  statisticsData: [],
  beginningData: null,
  endData: null,
  isShowStatistics: false,
  isShowConnectNumber: false,
  resultData: DEFAULT_RESULT,
  isShowPassword: false,
  isCreatePassword: false,
  phonePassword: null,
  forwardingData: [],
  editableRuleData: null,
  isLoadingConnectNumber: false,
  isShowNumberDeactivation: false,
  isShowCreatingRule: false,
  isCreate: false,
  isEdit: false,
  sim: null,
  isShowCancelNumberDeactivation: false,
  prices: [],
};

enum VOICE_CHECK_BIND_ERROR_TYPES {
  NOT_ENOUGH_MONEY = 'PhoneBindNotEnoughMoneyException',
}

export const VOICE_ERROR_MESSAGES: Record<
  VOICE_CHECK_BIND_ERROR_TYPES,
  string
> = {
  [VOICE_CHECK_BIND_ERROR_TYPES.NOT_ENOUGH_MONEY]:
    'На вашем Лицевом Счёте недостаточно средств для заказа номера',
};
