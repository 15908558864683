/* Библиотеки */
import * as React from 'react';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Tag,
  defaultTheme,
  H3,
  Text,
  LeadingText,
  Button,
  ButtonStyleTypes,
  TabsStyleTypes,
  Tabs,
  LinkButton,
  Loader,
  Icons,
} from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledProductSelectorItem,
  StyledTags,
} from './ProductSelectorItem.style';
/** components */
import LinkWrapper from '~/components/LinkWrapper';
import SummarySausages from '../../SummarySausages/SummarySausages';
/* Интерфейсы */
import {
  ProductSelectorItemProps,
  TabNames,
} from './ProductSelectorItem.interfaces';
import { TagsProps, PriceInfoProps } from '../ProductTemplate.interfaces';
import {
  AllowedTariffProps,
  TariffProps,
} from '~/components/Blocks/Templates/ProductSwitcher/interfaces';
/* Утилиты */
import { formatNumber, pluralizeAll } from '~/utils/utils';
/* Константы */
import { CHANNELS_VARIANTS } from './ProductSelectItem.constants';
import { SERIES_CODE } from '~/constants/common';
import {
  StorageTypes,
  PRODUCT_ABBREVIATION,
  UnitTypes,
} from '~/components/Blocks/Templates/Summary/Summary.types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useContactsAndNotificationsStore from '../../ContactsAndNotifications/store/useContactsAndNotificationsStore';
import useProductSwitcherWizardStore from '../../ProductSwitcherWizard/store/useProductSwitcherWizardStore';

/* Элементы переключения цены на продуктах с дневной оплатой */
const productSelectorItemTabs = (priceInfo: PriceInfoProps): JSX.Element => {
  // Стоимость обслуживания в день и за 30 дней
  const { daily, thirtyDays } = priceInfo;
  /* Варианты вкладок таба */
  const tabsValue = [...Object.values(TabNames)];
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<string>(
    `${formatNumber(daily)}\u0020\u20bd`,
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setActiveTabValue(`${formatNumber(daily)}\u0020\u20bd`);
        break;
      case 1:
        setActiveTabValue(`${formatNumber(thirtyDays)}\u0020\u20bd`);
        break;
      default:
        setActiveTabValue(`${formatNumber(daily)}\u0020\u20bd`);
        break;
    }
  };
  return (
    <>
      <Tabs
        value={tabsValue}
        onChange={onChangeTab}
        activeTabIndex={activeTabIndex}
        styleType={TabsStyleTypes.SECONDARY}
      />
      <H3>{activeTabValue}</H3>
    </>
  );
};

const discountPrices = (discount: number, price: number): JSX.Element => {
  return (
    <div className="action-discount">
      <Text color={defaultTheme.colors.planeta}>
        &#8722;&nbsp;{`${discount}%`}
      </Text>
      <Text color={defaultTheme.colors.gray}>{`${price}\u0020\u20bd`}</Text>
    </div>
  );
};

/* Дочерний компонент с карточкой продукта */
const ProductSelectorItem: React.FC<ProductSelectorItemProps> = ({
  tags,
  channelsInfo,
  isAnnual,
  marketingName,
  priceInfo,
  productFeedLink,
  marketingGroupCode,
  seriesName,
  speedBaseText,
  isBusiness,
  tariffId,
  withoutFilters,
  action,
  tariff,
  mobilesInfo,
  features,
  stripColor,
}: ProductSelectorItemProps) => {
  const {
    connectionTariffStore: { toggleChangeTariffWizardVisible },
    summaryDataStore: { seriesCode, annualPriceInfo },
    allowedTariffStore: { allowedTariff },
    authStore: { isAuth, auth, isTemporaryTokenAuth },
  } = useRootStore();
  const {
    haveVerifiedPhone,
    addingContactStore: { setIsShowAddingContact },
  } = useContactsAndNotificationsStore();
  /** Контекст подключения продукта */
  const {
    toggleConnectionWizardVisible,
    connectionWizardAuto,
  } = useConnectionStore();
  const { getAgreement, isLoadingAgreement } = useProductSwitcherWizardStore();

  // Общее количество каналов
  const { channelCount } = { ...channelsInfo };
  // Годовая стоимость обслуживания
  const { migrationCostFirst } = priceInfo;

  const annualPrice = useMemo(() => {
    if (action?.newPrice) return action.newPrice;
    /* Авторизованным пользователям показываем цену подключения годовых
     * с учётом пояса обслуживания
     */
    if (
      (seriesCode === SERIES_CODE.ONLINE_SUPER &&
        tariff.seriesCode === SERIES_CODE.ONLINE_SUPER_2_0) ||
      (seriesCode === SERIES_CODE.HIT_SUPER &&
        tariff.seriesCode === SERIES_CODE.HIT_SUPER_2_0)
    )
      return priceInfo.annual - annualPriceInfo;
    const costToMigration = allowedTariff.find(
      (item) => item.tariffTo.tariffId === tariffId,
    )?.price;

    return costToMigration || migrationCostFirst;
  }, [annualPriceInfo, priceInfo, seriesCode]);

  /** Узнаем возможность подключения оптического продукта, для Product Switcher */
  const blockSwitchToFtth = useMemo(() => {
    return isAuth && allowedTariff
      ? ((allowedTariff as unknown) as AllowedTariffProps[])?.find(
          (item) => item?.tariffTo?.seriesCode === tariff.seriesCode,
        )?.tariffTo?.tariffMigrationRefuseReason === 'FtthError'
      : true;
  }, [isAuth, allowedTariff]);

  const isTransformer = tariff.seriesCode.includes('transformer');

  /** СП заявки на подключение */
  const connect = () => {
    return (
      <Button
        onClick={toggleConnectionWizardVisible}
        styleType={ButtonStyleTypes.SECONDARY}
      >
        Выбрать
      </Button>
    );
  };

  /** Продукт недоступен для перехода */
  const isNotAvailableProduct = useMemo(() => {
    if (!allowedTariff?.length) return false;
    return !((allowedTariff as unknown) as AllowedTariffProps[]).find(
      (item) => tariff.seriesCode === item.tariffTo.seriesCode,
    );
  }, [allowedTariff, tariff]);

  /** Обработчик кнопки смены типа подключения на оптику */
  const switchToFtthButtonHandler = async () => {
    if (isTemporaryTokenAuth) return;
    const havePhone = await haveVerifiedPhone();
    if (!havePhone) {
      setIsShowAddingContact(true, () =>
        connectionWizardAuto(
          tariff.seriesCode,
          tariff.seriesName,
          isAuth,
          auth,
        ),
      );
      return;
    }
    connectionWizardAuto(tariff.seriesCode, tariff.seriesName, isAuth, auth);
  };

  /** Обработчик кнопки смены продукта */
  const changeTariffButtonHandler = () => {
    getAgreement(
      tariff as TariffProps,
      tariffId,
      toggleChangeTariffWizardVisible,
      auth.contractName,
    );
  };

  /** Подключение продукта */
  const connectButton = useMemo(() => {
    if (!isAuth) {
      return connect();
    }
    if (!allowedTariff) {
      return <Loader small />;
    }

    if (blockSwitchToFtth) {
      return (
        <Text
          className="ftth-text"
          lineHeight="24px"
          color={defaultTheme.colors.shadow}
        >
          <LinkButton
            onClick={switchToFtthButtonHandler}
            color={
              isTemporaryTokenAuth
                ? defaultTheme.colors.disable
                : defaultTheme.colors.planeta
            }
          >
            Подать заявку
          </LinkButton>
          <br />
          на подключение оптики
        </Text>
      );
    }
    return (
      <Button
        styleType={ButtonStyleTypes.SECONDARY}
        onClick={changeTariffButtonHandler}
        loading={isLoadingAgreement}
      >
        Сменить
      </Button>
    );
  }, [
    isAuth,
    blockSwitchToFtth,
    allowedTariff,
    isTemporaryTokenAuth,
    isNotAvailableProduct,
    isLoadingAgreement,
    tariff,
  ]);

  const mobileText = useMemo(() => {
    if (!mobilesInfo?.storageBalances) return null;
    if (isBusiness) {
      const minutes = mobilesInfo.storageBalances.find(
        (item) => item.storageType === StorageTypes.OutGoingCall,
      );
      if (!minutes) return null;
      return `${minutes.maxQuantity} ${PRODUCT_ABBREVIATION.MINUTES}`;
    }
    return mobilesInfo.storageBalances
      .filter((item) =>
        [StorageTypes.MobileInternet, StorageTypes.OutGoingCall].includes(
          item.storageType,
        ),
      )
      .map((item) => {
        const name = () => {
          switch (item.unitType) {
            case UnitTypes.gigabyte:
              return PRODUCT_ABBREVIATION.GB;
            case UnitTypes.minute:
              return PRODUCT_ABBREVIATION.MINUTES;
            default:
              return '';
          }
        };
        return `${item.maxQuantity} ${name()}`;
      })
      .sort((a, b) => {
        return a === PRODUCT_ABBREVIATION.GB ? 1 : -1;
      })
      .join(' и ');
  }, [mobilesInfo]);

  return (
    <StyledProductSelectorItem
      isBusiness={isBusiness}
      withoutFilters={withoutFilters}
      stripColor={stripColor}
    >
      <StyledTags>
        {tags?.length > 0 &&
          tags.map((item: TagsProps) => (
            <Tag
              key={item.name}
              colorTag={defaultTheme.colors.pink}
              color={defaultTheme.colors.planeta}
            >
              {item.name.toUpperCase()}
            </Tag>
          ))}
      </StyledTags>
      <LinkWrapper href={productFeedLink} target="_blank">
        <LeadingText color={defaultTheme.colors.black}>
          {seriesName}
        </LeadingText>
      </LinkWrapper>
      <LinkWrapper href={marketingGroupCode} target="_blank">
        <Text color={defaultTheme.colors.gray}>{marketingName}</Text>
      </LinkWrapper>
      <Text lineHeight="24px">Интернет {speedBaseText}</Text>
      <div>
        {channelsInfo && (
          <Text lineHeight="24px">
            {isTransformer
              ? `Любые\nиз ${channelCount} телеканалов`
              : pluralizeAll(channelCount, CHANNELS_VARIANTS)}
          </Text>
        )}
      </div>
      <div>
        <Text
          lineHeight="24px"
          color={
            mobileText ? defaultTheme.colors.gray : defaultTheme.colors.black
          }
        />
        {mobileText && (
          <div className="mobile-text">
            <Icons.SimCardIcon />
            <Text lineHeight="24px">{mobileText}</Text>
          </div>
        )}
      </div>
      <div>
        {features?.length > 0 && (
          <SummarySausages className="sausages" features={[features[0]]} />
        )}
      </div>
      <div className="price">
        {isAnnual ? (
          <>
            <Text>
              {tariff.priceInfo.annual === annualPrice
                ? 'Подключение на год'
                : 'Апгрейд продукта'}
              {action && <sup>*</sup>}
            </Text>
            <span className="price__annual">
              {action &&
                !isAuth &&
                discountPrices(action.discount, migrationCostFirst)}
              <H3>{`${formatNumber(annualPrice)}\u0020\u20bd`}</H3>
            </span>
          </>
        ) : (
          productSelectorItemTabs(priceInfo)
        )}
      </div>
      {connectButton}
    </StyledProductSelectorItem>
  );
};

export default observer(ProductSelectorItem);
