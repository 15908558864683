/** libraries */
import { FC, useEffect, useRef, useState, useMemo } from 'react';
import {
  Autocomplete,
  defaultTheme,
  Icons,
  LeadingText,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** constants */
import { desktop940 } from '~/components/Grid/constants';
import {
  CHANNEL,
  PACKAGE_NOMINATIVE,
  STORE_TYPE,
  TV_CHANNEL,
} from '../../constants';

/** utils */
import { pluralizeAll } from '~/utils/utils';
import { getStore } from '../../utils';

/** components */
import ChannelPackageCard from '../Cards/ChannelPackageCard/ChannelPackageCard';
import ChannelCard from '../Cards/ChannelCard/ChannelCard';

/** interfaces */
import { Channel, ChannelPackage } from '../../interfaces';
import { IMyTVX2ModelStore } from '../../stores/MyTvX2Store';
import { IMyTVModelStore } from '../../stores/MyTvStore';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useMyTvStore } from '../../stores/useMyTvStore';

interface SearchProps {
  /** Пакеты включённые в продукт на витрине */
  packagesIncludedInProduct?: ChannelPackage[];
  /** Каналы включённые в продукт */
  allChannelsIncludedInProduct?: Channel[];
  /** Трансформер в блоке Телевидение */
  isTransformer?: boolean;
}

const Search: FC<SearchProps> = ({
  packagesIncludedInProduct,
  allChannelsIncludedInProduct,
  isTransformer,
}: SearchProps) => {
  const {
    tvStore: { tvChannelPackages, channelsList },
    authStore: { isAuth },
  } = useRootStore();

  const { storeType } = useMyTvStore();

  const {
    filteredPackages: packages,
    channelsList: allChannels,
    popularChannels,
    searchMode,
    setSearchMode,
    onClickPackageCard,
    onClickChannelCard,
    popularPackages,
  } = getStore(storeType) as IMyTVModelStore;

  const { getPopularChannels, getPopularPackages } = getStore(
    storeType,
  ) as IMyTVX2ModelStore;

  const isTelevision = storeType === STORE_TYPE.TELEVISION;

  /** Каналы */
  const channels: Channel[] = useMemo(
    () => (isAuth ? channelsList : allChannels) as Channel[],
    [isAuth, channelsList, allChannels],
  );
  /** Пакеты */
  const packets = useMemo(
    () =>
      isAuth && !isTransformer && !isTelevision ? tvChannelPackages : packages,
    [isAuth, tvChannelPackages, packages, isTransformer, isTelevision],
  ) as ChannelPackage[];
  /** Популярные каналы */
  const popularChannelsList: Channel[] = useMemo(
    () =>
      (isTelevision
        ? popularChannels
        : getPopularChannels(channelsList)) as Channel[],
    [isTelevision, channelsList, popularChannels],
  );
  /** Популярные пакеты */
  const popularPackagesList: ChannelPackage[] = useMemo(
    () =>
      (isTelevision
        ? popularPackages
        : getPopularPackages(tvChannelPackages)) as ChannelPackage[],
    [isTelevision, tvChannelPackages, popularPackages],
  );
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  /** Значение поля поиска */
  const [searchValue, setSearchValue] = useState<string>('');
  /** Ссылка на input */
  const searchInput = useRef(null);

  /** Отфильтрованные по поиску каналы */
  const [filteredChannelsList, setFilteredChannelsList] = useState([]);

  /** Отфильтрованные по поиску пакеты */
  const [filteredPackageList, setFilteredPackageList] = useState([]);

  /** Обработчик ввода поиска */
  const onChangeSearch = (value) => {
    setSearchValue(value);
    const channelFilteredList = channels.filter((item) => {
      return item.name
        .trim()
        .toLowerCase()
        .includes(value.trim().toLowerCase());
    });
    const packageFilteredList = packets.filter((item) => {
      return item.channelPackName
        .trim()
        .toLowerCase()
        .includes(value.trim().toLowerCase());
    });

    setFilteredChannelsList(channelFilteredList);
    setFilteredPackageList(packageFilteredList);
  };

  useEffect(() => {
    /** Фокус на input */
    if (searchInput.current) searchInput.current.focus();
  }, [searchInput.current, searchMode]);

  useEffect(() => {
    if (!searchValue.length) {
      setFilteredChannelsList([]);
      setFilteredPackageList([]);
    }
  }, [searchValue]);

  return (
    <div className="my-tv__search">
      <div className="my-tv__search__header">
        <Autocomplete
          forwardedRef={searchInput}
          showSearchIcon
          items={[]}
          text={searchValue}
          placeholder={isDesktop940 ? 'Поиск телеканалов и пакетов' : 'Поиск'}
          onInputChange={(value) => onChangeSearch(value)}
          hideOptions
        />
        <div
          className="my-tv__search__header__close-icon"
          onClick={() => {
            setSearchMode(false);
            setSearchValue('');
          }}
        >
          <Icons.CloseIcon />
        </div>
      </div>
      <div className="my-tv__tab-block">
        <div className="my-tv__tab-block__block">
          {filteredPackageList.length || filteredChannelsList.length ? (
            <>
              <div className="my-tv__tab-block__block__count">
                <LeadingText
                  className="my-tv__tab-block__block__category"
                  color={defaultTheme.colors.black}
                >
                  Найдено «{searchValue}»
                </LeadingText>
                <LeadingText
                  className="my-tv__tab-block__block__count"
                  color={defaultTheme.colors.shadow}
                >
                  {filteredPackageList.length
                    ? pluralizeAll(
                        filteredPackageList.length,
                        PACKAGE_NOMINATIVE,
                      )
                    : ''}
                  {filteredPackageList.length && filteredChannelsList.length
                    ? ' и '
                    : ''}
                  {filteredChannelsList.length
                    ? pluralizeAll(filteredChannelsList.length, CHANNEL)
                    : ''}
                </LeadingText>
              </div>
              <div className="my-tv__tab-block__block__cards">
                {filteredPackageList.map((item) => {
                  return (
                    <ChannelPackageCard
                      key={item.channelPackName}
                      packet={item}
                      onClick={() => onClickPackageCard(item)}
                      highlight={searchValue}
                      packagesIncludedInProduct={packagesIncludedInProduct}
                      isTransformer={isTransformer}
                    />
                  );
                })}
                {filteredChannelsList.map((item) => {
                  return (
                    <ChannelCard
                      key={item.name}
                      channel={item}
                      onClick={() => onClickChannelCard(item)}
                      highlight={searchValue}
                      allChannelsIncludedInProduct={
                        allChannelsIncludedInProduct
                      }
                      isTransformer={isTransformer}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <>
              {searchValue.length ? (
                <div className="my-tv__tab-block__block__not-found">
                  <LeadingText
                    className="my-tv__tab-block__block__not-found__first-text"
                    color={defaultTheme.colors.black}
                  >
                    Не найдено «{searchValue}»
                  </LeadingText>
                  <Text
                    className="my-tv__tab-block__block__not-found__third-text"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    К сожалению, ничего не нашлось, но среди{' '}
                    {pluralizeAll(channels.length, TV_CHANNEL)} всегда есть
                    что-то интересное
                  </Text>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {!filteredPackageList.length && !filteredChannelsList.length ? (
            <div className="my-tv__tab-block__block">
              <div className="my-tv__tab-block__block__count">
                <LeadingText
                  className="my-tv__tab-block__block__category"
                  color={defaultTheme.colors.black}
                >
                  Популярные пакеты и каналы
                </LeadingText>
              </div>
              <div className="my-tv__tab-block__block__cards">
                {popularPackagesList?.length > 0 &&
                  popularPackagesList.map((item) => {
                    return (
                      <ChannelPackageCard
                        key={item.channelPackName}
                        packet={item}
                        onClick={() => onClickPackageCard(item)}
                        packagesIncludedInProduct={packagesIncludedInProduct}
                        isTransformer={isTransformer}
                      />
                    );
                  })}
                {popularChannelsList.map((item: Channel) => (
                  <ChannelCard
                    key={item.name}
                    channel={item}
                    onClick={() => onClickChannelCard(item)}
                    allChannelsIncludedInProduct={allChannelsIncludedInProduct}
                    isTransformer={isTransformer}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Search);
