import { createContext, useContext } from 'react';
import PinWizardModel, { IPinWizardStore } from './PinWizardModel';

const store = PinWizardModel.create({
  isShowPinSidePage: false,
  contractNumberForPin: '',
  token: '',
});

const PinWizardStoreContext = createContext<IPinWizardStore>(store);

const usePinWizardStore = (): IPinWizardStore => {
  return useContext(PinWizardStoreContext);
};

export default usePinWizardStore;
