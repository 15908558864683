/** библиотеки */
import { useMemo } from 'react';
import NextImage from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import { ru } from 'date-fns/locale';
import { format, differenceInDays, parseISO } from 'date-fns';
/** константы */
import {
  SOFTLINE_PROMO_TAGS,
  ANNUAL_SUBSCRIPTION,
  CASHBACK_TEXT,
  COLORS,
  daysUntilSubscriptionEnds,
} from '../constants';
import { desktop940 } from '~/components/Grid/constants';
import { OPERATING_STATE } from '~/constants/common';
/** утилиты */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** стили */
import { StyledSoftlineCard } from './styles';
/** компоненты библиотеки */
import {
  defaultTheme,
  H3,
  Tag,
  Text,
  Button,
  Carousel,
  SliderButtons,
} from 'cordis-core-ui-planeta';
/** интерфейсы */
import { SoftlineCardProps } from '../types';
/** компоненты */
import SoftlineTabs from '../SoftlineTabs/SoftlineTabs';
/** store */
import { useRootStore } from '~/stores/RootStore';
import useSoftlineStateModelStore from '../stores/useSoftlineStateStore';

/**
 * Карточка антивируса
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=633941427
 */
const SoftlineCard = ({ item }: SoftlineCardProps): JSX.Element => {
  const {
    authStore: { isAuth },
    contractStateStore: { contractState },
  } = useRootStore();

  const {
    durationDays,
    name,
    trialInfo,
    chatlingCashback,
    deviceCount,
    pendingUnbindDate,
    imageInfo,
    actions,
  } = item;

  const { onSoftlineCardClick } = useSoftlineStateModelStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Подписка подключена и активна */
  const isActiveSubscription = useMemo(
    () => contractState === OPERATING_STATE.ON,
    [contractState],
  );

  /** Пользователь авторизован и подписка подключена */
  const isMySubscriptions = !!(isAuth && item?.statusString);

  /** Годовая подписка */
  const isAnnual = durationDays === ANNUAL_SUBSCRIPTION;

  /** Цвета карточки */
  const colorStyles = useMemo(() => {
    if (!isMySubscriptions)
      return {
        background: COLORS.REGULAR,
        border: COLORS.REGULAR,
      };
    const diffBetweenDates = pendingUnbindDate
      ? differenceInDays(new Date(pendingUnbindDate), new Date())
      : null;
    if (
      (isAnnual && diffBetweenDates <= daysUntilSubscriptionEnds) ||
      !isActiveSubscription
    )
      return {
        background: COLORS.BACK_WARNING,
        border: COLORS.BORDER_WARNING,
      };
    return {
      background: COLORS.BACK_MY_SUBSCRIPTION,
      border: COLORS.BORDER_MY_SUBSCRIPTION,
    };
  }, [isAuth, isActiveSubscription]);

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Тег для подписки */
  const softlineTag = useMemo((): SOFTLINE_PROMO_TAGS[] => {
    const tags = [];
    if (trialInfo?.isEnabled || trialInfo?.trialDays > 0)
      tags.push(SOFTLINE_PROMO_TAGS.PROMO);
    if (durationDays === ANNUAL_SUBSCRIPTION)
      tags.push(SOFTLINE_PROMO_TAGS.ANNUAL);
    return tags.length ? tags : null;
  }, [item]);

  /** Текст числа устройств для подписки */
  const deviceCountText = useMemo(() => {
    switch (deviceCount) {
      case 1:
        return 'для 1 устройства';
      /** По договорённости с бэкендом: null - все устройства */
      case null:
        return 'для всех устройств';
      default:
        return `для ${deviceCount} устройств`;
    }
  }, [deviceCount]);

  /** Подпись для подключённых подписок */
  const infoText = useMemo(() => {
    if (isMySubscriptions && isAnnual && isActiveSubscription) {
      const unbindDate = format(parseISO(pendingUnbindDate), 'dd MMMM Y года', {
        locale: ru,
      });
      return `Подписка подключена\nдо ${unbindDate}`;
    }
    if (isMySubscriptions && !isActiveSubscription)
      return 'Подписка приостановлена';
    if (isMySubscriptions && pendingUnbindDate && !isAnnual) {
      const unbindDate = format(parseISO(pendingUnbindDate), 'dd MMMM Y года', {
        locale: ru,
      });
      return `Отключение\nс ${unbindDate}`;
    }
    if (isMySubscriptions) return 'Подписка подключена';
    return '';
  }, [item, isActiveSubscription]);

  const swipeCashback = () => {
    return (
      <div className="description-block__carousel-wrapper__slide">
        {/** При наличии тэга показываем его только в первом слайде */}
        {chatlingCashback && (
          <>
            <div className="description-block__carousel-wrapper__tags">
              <Tag
                color={defaultTheme.colors.planeta}
                colorTag={defaultTheme.colors.pink}
              >
                {CASHBACK_TEXT.value(chatlingCashback.cashbackPercent)}
              </Tag>
            </div>
            {actions?.chatlingCashback && (
              <Text lineHeight="24px">
                {parseHtml(actions?.chatlingCashback)}
              </Text>
            )}
          </>
        )}
      </div>
    );
  };

  /** Листалка преимуществ */
  const swipeItems = useMemo(() => {
    if (!item?.description?.descriptionSlider) return [];
    const items = item.description.descriptionSlider.map((slide) => {
      return (
        <div className="description-block__carousel-wrapper__slide">
          <Text lineHeight="24px">{parseHtml(slide)}</Text>
        </div>
      );
    });

    return items;
  }, [item]);

  return (
    <StyledSoftlineCard
      $backgroundColor={colorStyles.background}
      $borderColor={colorStyles.border}
    >
      <div className="logo-block">
        {softlineTag && (
          <div className="logo-block__tags">
            {softlineTag.map((tag) => (
              <Tag
                key={tag}
                color={defaultTheme.colors.planeta}
                colorTag={defaultTheme.colors.pink}
              >
                {tag}
              </Tag>
            ))}
          </div>
        )}
        {imageInfo && (
          <div className="logo-block__image-wrapper">
            <NextImage
              loader={imageLoader}
              src={imageInfo.url}
              alt="imageSrc"
              width={imageInfo.width}
              height={imageInfo.height}
              quality={100}
            />
          </div>
        )}
        <H3
          className="logo-block__name"
          dangerouslySetInnerHTML={{ __html: name }}
        />
        {isMySubscriptions && (
          <Text color={defaultTheme.colors.shadow}>{infoText}</Text>
        )}
      </div>
      <div className="x2-col">
        <div className="description-block">
          <div className="description-block__license">
            <div className="description-block__license__col">
              <Text color={defaultTheme.colors.shadow}>Лицензия</Text>
              <H3>{deviceCountText}</H3>
            </div>
            {item?.operatingSystem && (
              <div className="description-block__license__col">
                <Text color={defaultTheme.colors.shadow}>
                  Операционная система
                </Text>
                <Text>{parseHtml(item?.operatingSystem)}</Text>
              </div>
            )}
          </div>
          {isDesktop940 && (
            <div className="description-block__carousel-wrapper">
              {swipeItems.length > 0 && (
                <Carousel duration={1000} widgets={[SliderButtons]}>
                  {!isMySubscriptions && chatlingCashback
                    ? [swipeCashback(), ...swipeItems]
                    : swipeItems}
                </Carousel>
              )}
            </div>
          )}
        </div>
        <div className="price-block">
          <SoftlineTabs item={item} />
          <div className="price-block__button">
            {isMySubscriptions ? (
              <Button
                onClick={() => onSoftlineCardClick(item)}
                background={defaultTheme.colors[colorStyles.background]}
                border={`${defaultTheme.colors.black} 1px solid`}
                color={defaultTheme.colors.black}
              >
                Управлять
              </Button>
            ) : (
              <Button onClick={() => onSoftlineCardClick(item)}>
                Подключить
              </Button>
            )}
          </div>
        </div>
      </div>
    </StyledSoftlineCard>
  );
};

export default observer(SoftlineCard);
