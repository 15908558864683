/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  H1,
  Icon,
  Icons,
  Input,
  LeadingText,
  OptionProp,
  OutsideClickHelper,
  Select,
  SidePage,
  TagButton,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { isBefore, parseISO } from 'date-fns';
import { observer } from 'mobx-react';
/** styles */
import { StyledCreatingRule, StyledResult } from './styles';
/** interfaces */
import { InputValue, ResultProps } from '../../interfaces';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR, DEFAULT_RESULT } from '~/constants/common';
import {
  HOURS,
  HOURS_END,
  KEYS,
  MIDNIGHT,
  SaveRuleError,
  StateCondition,
  StateConditionNames,
  initialAutocomplete,
} from '../../constants';
/** api */
import { deleteForwardingRule, saveForwardingRule } from '~/api/apiPab2c';
/** components */
import CreatingRuleFooter from './CreatingRuleFooter';
/** utils */
import { maskPhone } from '~/utils/utils';
import { maskHomePhone } from '../../utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from '../../store/useVoiceStore';

/** Подключить номер */
export const CreatingRuleSidePage: FC = () => {
  const {
    pab2cVoiceStore: { phones },
  } = useRootStore();
  const {
    forwarding,
    getForwarding,
    editableRule,
    setEditableRule,
    isShowCreatingRule,
    setIsShowCreatingRule,
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
  } = useVoiceStore();

  /** Результат сайдпейджа */
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Ошибка сохранения/удаления правила */
  const [saveError, setSaveError] = useState<string>('');
  const resultJSX = () => {
    if (!result.isResult) return <></>;
    if (result.isCorrect) {
      return (
        <StyledResult>
          <Icons.OkBigIcon />
          <H1>{isEdit ? 'Правило изменено' : 'Правило создано'}</H1>
        </StyledResult>
      );
    }
    return (
      <StyledResult>
        <Icons.NotOkBigIcon />
        <H1>{result.text ?? DEFAULT_ERROR}</H1>
      </StyledResult>
    );
  };

  const zeroing = () => {
    setIsEdit(false);
    setIsCreate(false);
    setEditableRule(null);
    setPhoneNumberError('');
    setIsShowCreatingRule(false);
    setIsAlways(true);
    setIsDisabledWeekday(true);
    setIsDisabledTime(true);
    setSelectedDaysOfWeek([1, 2, 3, 4, 5, 6, 7]);
    setIsAroundClock(true);
    setIsDisabledTime(true);
    setStartSelect(HOURS[0]);
    setEndSelect(HOURS[0]);
    setResult(DEFAULT_RESULT);
  };
  /** Редактируемый телефон */
  const editablePhone = useMemo(() => {
    if (!editableRule)
      return {
        label: '',
        value: '',
      };
    const phone = phones.find((item) => item.sim === editableRule.sim);
    return {
      label: maskHomePhone(phone),
      value: phone?.sim,
    };
  }, [phones, editableRule]);

  /** Редактируемое условие */
  const editableCondition = useMemo(() => {
    if (!editableRule)
      return {
        label: '',
        value: '',
      };
    return {
      label: Object.values(StateConditionNames).find(
        (state) => state.key === editableRule.state,
      ).name,
      value: editableRule.state,
    };
  }, [editableRule]);

  /** Редактируемый телефон */
  const editablePhoneNumber = useMemo(() => {
    if (!editableRule)
      return {
        value: '',
        forSend: '',
      };
    return {
      value: maskPhone(editableRule.forwardNumber),
      forSend: editableRule.forwardNumber.replace('8', '+7'),
    };
  }, [editableRule]);

  /** Круглосуточно */
  const [isAroundClock, setIsAroundClock] = useState<boolean>(true);

  /** Селект начало */
  const [startSelect, setStartSelect] = useState<OptionProp>(HOURS[0]);
  useEffect(() => {
    setCandidateForStartSelect(startSelect);
  }, [startSelect]);
  /** Вид блока с select начала в мобильной версии */
  const [
    isMobileStartSelectOpen,
    setIsMobileStartSelectOpen,
  ] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый select начала в мобильной версии
  const [
    candidateForStartSelect,
    setCandidateForStartSelect,
  ] = useState<OptionProp>(HOURS[0]);
  const applyMobileStartSelect = () => {
    setIsMobileStartSelectOpen(false);
    setStartSelect(candidateForStartSelect);
  };

  /** Селект конец */
  const [endSelect, setEndSelect] = useState<OptionProp>(HOURS_END[0]);
  useEffect(() => {
    setCandidateForEndSelect(endSelect);
  }, [endSelect]);

  /** Установить селект конец на 0:30, при отключении селектора Круглосуточно.
   * Сместить селект конец на + 0:30, при установке большего селекта начала.
   */
  useEffect(() => {
    if (isAroundClock) {
      setStartSelect(HOURS[0]);
      setEndSelect(HOURS[0]);
    }
    if (
      (isBefore(parseISO(endSelect.value), parseISO(startSelect.value)) &&
        !isAroundClock) ||
      (startSelect === endSelect && !isAroundClock)
    ) {
      const index = HOURS.findIndex(
        (item) => !isBefore(parseISO(item.value), parseISO(startSelect.value)),
      );
      setEndSelect(HOURS_END[index]);
    }
  }, [isAroundClock, startSelect]);

  /** Вид блока с select конец в мобильной версии */
  const [isMobileEndSelectOpen, setIsMobileEndSelectOpen] = useState<boolean>(
    false,
  );
  // Выбираемый, но не подтверждённый select конца в мобильной версии
  const [
    candidateForEndSelect,
    setCandidateForEndSelect,
  ] = useState<OptionProp>(HOURS[0]);
  const applyMobileEndSelect = () => {
    setIsMobileEndSelectOpen(false);
    setEndSelect(candidateForEndSelect);
  };

  /** Варианты select для начала периода */
  const startSelects = useMemo(() => {
    return HOURS;
  }, [endSelect]);

  /** Варианты select для конца периода */
  const endSelects = useMemo(() => {
    const endDates = HOURS.filter(
      (item) => !isBefore(parseISO(item.value), parseISO(startSelect.value)),
    );
    endDates.shift();
    endDates.push(HOURS[0]);
    return startSelect.value ? endDates : HOURS_END;
  }, [startSelect]);

  const selectPhones: OptionProp[] = useMemo(() => {
    return phones.map((item) => {
      return {
        label: maskHomePhone(item),
        value: item.sim,
      };
    });
  }, [phones]);
  /** Селект номера */
  const [numberSelect, setNumberSelect] = useState<OptionProp>(
    isEdit ? editablePhone : selectPhones[0],
  );
  useEffect(() => {
    setNumberSelect(isEdit ? editablePhone : selectPhones[0]);
    setCandidateForNumberSelect(isEdit ? editablePhone : selectPhones[0]);
    setSaveError('');
  }, [selectPhones, isEdit, editablePhone]);

  /** Вид блока с select номера в мобильной версии */
  const [
    isMobileNumberSelectOpen,
    setIsMobileNumberSelectOpen,
  ] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый select номера в мобильной версии
  const [
    candidateForNumberSelect,
    setCandidateForNumberSelect,
  ] = useState<OptionProp>(selectPhones[0]);
  const applyMobileNumberSelect = () => {
    setIsMobileNumberSelectOpen(false);
    setNumberSelect(candidateForNumberSelect);
  };

  const selectConditions = useMemo(() => {
    return (Object.keys(StateCondition) as Array<
      keyof typeof StateCondition
    >).map((item) => {
      return {
        label: Object.values(StateConditionNames).find(
          (state) => state.key === item,
        ).name,
        value: item,
      };
    });
  }, [phones]);
  /** Селект условия */
  const [conditionSelect, setConditionSelect] = useState<OptionProp>(
    isEdit ? editableCondition : selectConditions[0],
  );
  useEffect(() => {
    setConditionSelect(isEdit ? editableCondition : selectConditions[0]);
    setCandidateForConditionSelect(
      isEdit ? editableCondition : selectConditions[0],
    );
    setSaveError('');
  }, [selectConditions, isEdit, editableCondition]);
  /** Вид блока с select условия в мобильной версии */
  const [
    isMobileConditionSelectOpen,
    setIsMobileConditionSelectOpen,
  ] = useState<boolean>(false);
  // Выбираемый, но не подтверждённый select условия в мобильной версии
  const [
    candidateForConditionSelect,
    setCandidateForConditionSelect,
  ] = useState<OptionProp>(selectConditions[0]);
  const applyMobileConditionSelect = () => {
    setIsMobileConditionSelectOpen(false);
    setConditionSelect(candidateForConditionSelect);
  };

  /** Мобильный select условия */
  const mobileSelect = (
    name: string,
    isMobileSelectOpen: boolean,
    setIsMobileSelect: Dispatch<SetStateAction<boolean>>,
    select: OptionProp,
    candidateForSelect: OptionProp,
    setCandidateForSelect: Dispatch<SetStateAction<OptionProp>>,
    selectData: OptionProp[],
    apply: () => void,
  ) => {
    return (
      <StyledCreatingRule>
        <div className="rules__mobile-select">
          <div className="rules__mobile-select__header">
            {name && (
              <LeadingText color={defaultTheme.colors.black}>
                {name}
              </LeadingText>
            )}
            <Icon
              icon={<Icons.CloseIcon />}
              onClick={() => {
                setIsMobileSelect(!isMobileSelectOpen);
                setCandidateForSelect(select);
              }}
              highlight
            />
          </div>
          {selectData.map((item) => {
            return (
              <TagButton
                key={item.label}
                className="rules__mobile-select__tag-button"
                onChange={() => setCandidateForSelect(item)}
                checked={item.value === candidateForSelect.value}
              >
                {item.label}
              </TagButton>
            );
          })}
          <Button onClick={apply}>Применить</Button>
        </div>
      </StyledCreatingRule>
    );
  };

  const [phoneNumber, setPhoneNumber] = useState<InputValue>(
    isEdit ? editablePhoneNumber : initialAutocomplete,
  );
  useEffect(() => {
    setPhoneNumber(isEdit ? editablePhoneNumber : initialAutocomplete);
  }, [isEdit, editablePhoneNumber]);
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  /** Номер телефона */
  const onChangePhoneNumber = (event) => {
    const { value, forSend, errorText } = event;
    setPhoneNumber({ value, forSend });
    setPhoneNumberError(errorText ? 'Введите корректный номер телефона ' : '');
  };

  /** Всегда */
  const [isAlways, setIsAlways] = useState<boolean>(true);
  /** Дни недели */
  const daysOfWeek = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<number[]>([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
  ]);
  /** Disable weekday */
  const [isDisabledWeekday, setIsDisabledWeekday] = useState<boolean>(true);

  const [isDisabledTime, setIsDisabledTime] = useState<boolean>(true);

  /** Редактируемые дни недели */
  const editableDays = useMemo(() => {
    if (!editableRule) return [];
    return Object.keys(editableRule.timetable).reduce((acc, table) => {
      if (!editableRule.timetable[table]) return acc;
      acc.push(KEYS.findIndex((day) => day === table) + 1);
      return acc;
    }, []);
  }, [editableRule]);

  const dayString = useMemo(() => {
    return KEYS.find((item, index) => index + 1 === editableDays[0]);
  }, [editableDays]);

  const editableStart = useMemo(() => {
    if (!editableRule)
      return {
        label: '',
        value: '',
      };
    return editableDays.length !== 7
      ? {
          label: editableRule.timetable[dayString].interval.begin,
          value: editableRule.timetable[dayString].interval.fromDate,
        }
      : MIDNIGHT;
  }, [editableRule, editableDays, dayString]);

  const editableEnd = useMemo(() => {
    if (!editableRule)
      return {
        label: '',
        value: '',
      };
    return editableDays.length !== 7
      ? {
          label: editableRule.timetable[dayString].interval.end,
          value: `0001-01-01${editableRule.timetable[
            dayString
          ].interval.trimDate.substring(10)}`,
        }
      : MIDNIGHT;
  }, [editableRule, editableDays, dayString]);

  /** Редактируемые дни недели и время */
  const editableWeeks = () => {
    const { timetable } = editableRule;
    const always =
      !!timetable.monday &&
      !!timetable.tuesday &&
      !!timetable.wednesday &&
      !!timetable.thursday &&
      !!timetable.friday &&
      !!timetable.saturday &&
      !!timetable.sunday;
    setIsAlways(always);
    setIsDisabledWeekday(always);
    const aroundClock =
      editableStart.label === MIDNIGHT.label &&
      editableEnd.label === MIDNIGHT.label;
    setIsAroundClock(aroundClock);
    setIsDisabledTime(aroundClock);
    setStartSelect(editableStart);
    setEndSelect(editableEnd);
    setSelectedDaysOfWeek(editableDays);
  };

  useEffect(() => {
    if (editableRule) editableWeeks();
  }, [editableRule]);

  const aroundClock = () => {
    return (
      <Checkbox
        className="rules__time__block__checkbox"
        checked={isAroundClock}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setIsAroundClock(e.target.checked);
          if (e.target.checked) {
            setIsDisabledTime(true);
            setStartSelect(HOURS[0]);
            setEndSelect(HOURS[0]);
          } else {
            setIsDisabledTime(false);
          }
        }}
        disabled={isDisabledWeekday}
      >
        Круглосуточно
      </Checkbox>
    );
  };

  /** Загрузка сохранения правила */
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  /** Загрузка удаления правила */
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  /** Вкл/Выкл правило */
  const [isEnableRule, setIsEnableRule] = useState<boolean>(
    isEdit ? editableRule.isEnabled : true,
  );
  useEffect(() => {
    setIsEnableRule(isEdit ? editableRule.isEnabled : true);
  }, [isEdit, editableRule]);

  /** disable кнопки "изменить" */
  const isDisabledSaveRule = useMemo((): boolean => {
    if (!editableRule) return false;
    if (
      numberSelect.value === editablePhone.value &&
      isEnableRule === editableRule.isEnabled &&
      conditionSelect.value === editableCondition.value &&
      phoneNumber.forSend === editablePhoneNumber.forSend &&
      selectedDaysOfWeek.length === editableDays.length &&
      selectedDaysOfWeek.every((element) => {
        return editableDays.includes(element);
      }) &&
      startSelect.value === editableStart.value &&
      endSelect.value === editableEnd.value
    )
      return true;
    return false;
  }, [
    editableRule,
    editablePhone,
    numberSelect,
    isEnableRule,
    conditionSelect,
    editableCondition,
    phoneNumber,
    editablePhoneNumber,
    selectedDaysOfWeek,
    editableDays,
    editableStart,
    startSelect,
    endSelect,
    editableEnd,
  ]);

  const saveRules = async () => {
    setIsLoadingSave(true);
    setSaveError('');
    if (phoneNumber.value.length < 16 || phoneNumberError.length) {
      setPhoneNumberError('Введите корректный номер телефона');
      setIsLoadingSave(false);
      return;
    }
    /* Проверка на правило 1 номер - 1 переадресация. Временное решение */
    const isRuleExistForThisPhone = forwarding.find(
      (item) => item.sim === numberSelect.value,
    );
    if (isRuleExistForThisPhone && !isEdit) {
      setSaveError('Для этого номера уже настроена переадресация');
      setIsLoadingSave(false);
      return;
    }

    try {
      await saveForwardingRule(
        numberSelect.value,
        isEnableRule,
        conditionSelect.value,
        phoneNumber.forSend,
        60,
        isEdit ? editableRule.id : null,
        selectedDaysOfWeek,
        startSelect.value,
        endSelect.value,
      );
      getForwarding();
      setResult({
        isResult: true,
        isCorrect: true,
      });
    } catch (e) {
      setSaveError(DEFAULT_ERROR);
    } finally {
      setIsLoadingSave(false);
    }
  };

  const deleteRule = async () => {
    setIsLoadingDelete(true);
    setSaveError('');
    try {
      await deleteForwardingRule(editableRule.id);
      getForwarding();
      setIsShowCreatingRule(false);
      zeroing();
    } catch (e) {
      setSaveError(DEFAULT_ERROR);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const content = () => {
    if (isMobileNumberSelectOpen && !isDesktop940)
      return mobileSelect(
        'Переводить с номера',
        isMobileNumberSelectOpen,
        setIsMobileNumberSelectOpen,
        numberSelect,
        candidateForNumberSelect,
        setCandidateForNumberSelect,
        selectPhones,
        applyMobileNumberSelect,
      );
    if (isMobileConditionSelectOpen && !isDesktop940)
      return mobileSelect(
        'Условие',
        isMobileConditionSelectOpen,
        setIsMobileConditionSelectOpen,
        conditionSelect,
        candidateForConditionSelect,
        setCandidateForConditionSelect,
        selectConditions,
        applyMobileConditionSelect,
      );

    if (isMobileStartSelectOpen && !isDesktop940)
      return mobileSelect(
        'Начало',
        isMobileStartSelectOpen,
        setIsMobileStartSelectOpen,
        startSelect,
        candidateForStartSelect,
        setCandidateForStartSelect,
        startSelects,
        applyMobileStartSelect,
      );
    if (isMobileEndSelectOpen && !isDesktop940)
      return mobileSelect(
        'Конец',
        isMobileEndSelectOpen,
        setIsMobileEndSelectOpen,
        endSelect,
        candidateForEndSelect,
        setCandidateForEndSelect,
        endSelects,
        applyMobileEndSelect,
      );
    if (!result.isResult)
      return (
        <>
          <div className="rules__info-block">
            <div className="rules__info-block__block">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Переводить с номера
              </Text>
              <div
                onClick={() => {
                  if (!isDesktop940) {
                    setIsMobileNumberSelectOpen(true);
                  }
                }}
              >
                <Select
                  width="330px"
                  onOptionClick={(option) => setNumberSelect(option)}
                  value={numberSelect?.value}
                  outsideClickHelper={OutsideClickHelper()}
                  data={selectPhones}
                />
                {saveError === SaveRuleError.samePhone && (
                  <div className="error">
                    <Text
                      style={{ marginTop: '16px' }}
                      lineHeight="24px"
                      color={defaultTheme.colors.planeta}
                    >
                      {saveError}
                    </Text>
                  </div>
                )}
              </div>
            </div>
            <div className="rules__info-block__block">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Условие
              </Text>
              <div
                onClick={() => {
                  if (!isDesktop940) {
                    setIsMobileConditionSelectOpen(true);
                  }
                }}
              >
                <Select
                  width="330px"
                  onOptionClick={(option) => setConditionSelect(option)}
                  value={conditionSelect?.value}
                  outsideClickHelper={OutsideClickHelper()}
                  data={selectConditions}
                />
              </div>
            </div>
            <div className="rules__info-block__block">
              <Text lineHeight="24px">Переводить звонки на</Text>
              <Input
                type="phone"
                placeholder="Введите номер"
                value={phoneNumber.value}
                error={phoneNumberError}
                onChangeCustomInput={onChangePhoneNumber}
              />
            </div>
          </div>
          <Checkbox
            className="rules__always-checkbox"
            checked={isAlways}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setIsDisabledWeekday(e.target.checked);
              setIsAlways(e.target.checked);
              setSelectedDaysOfWeek([1, 2, 3, 4, 5, 6, 7]);
              setIsAroundClock(true);
              setIsDisabledTime(true);
              setStartSelect(HOURS[0]);
              setEndSelect(HOURS[0]);
            }}
          >
            Всегда
          </Checkbox>
          <div className="rules__checkbox-block">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Дни
            </Text>
            <div className="rules__checkbox-block__block">
              {daysOfWeek.map((item, index) => {
                const dayOfWeek = selectedDaysOfWeek.find(
                  (day) => day === index + 1,
                );
                return (
                  <Checkbox
                    className="rules__checkbox-block__block__checkbox"
                    checked={!!dayOfWeek}
                    onChange={() => {
                      const selectedDay = dayOfWeek
                        ? selectedDaysOfWeek.filter((day) => day !== dayOfWeek)
                        : selectedDaysOfWeek.concat([index + 1]);
                      setSelectedDaysOfWeek(selectedDay);
                    }}
                    disabled={isDisabledWeekday}
                    key={item}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
          </div>
          {!isDesktop940 && aroundClock()}
          <div className="rules__time">
            <Text
              lineHeight="24px"
              color={
                isDisabledWeekday
                  ? defaultTheme.colors.disable
                  : defaultTheme.colors.shadow
              }
            >
              Время
            </Text>
            <div className="rules__time__block">
              <div className="rules__time__block__selects">
                <div
                  onClick={() => {
                    if (
                      !isDesktop940 &&
                      !(isDisabledWeekday || isDisabledTime)
                    ) {
                      setIsMobileStartSelectOpen(true);
                    }
                  }}
                >
                  <Select
                    width="330px"
                    onOptionClick={(option) => setStartSelect(option)}
                    value={startSelect?.value}
                    outsideClickHelper={OutsideClickHelper()}
                    data={startSelects}
                    disabled={isDisabledWeekday || isDisabledTime}
                  />
                </div>
                <Text lineHeight="24px">—</Text>
                <div
                  onClick={() => {
                    if (
                      !isDesktop940 &&
                      !(isDisabledWeekday || isDisabledTime)
                    ) {
                      setIsMobileEndSelectOpen(true);
                    }
                  }}
                >
                  <Select
                    width="330px"
                    onOptionClick={(option) => setEndSelect(option)}
                    value={endSelect?.value}
                    outsideClickHelper={OutsideClickHelper()}
                    data={endSelects}
                    disabled={isDisabledWeekday || isDisabledTime}
                  />
                </div>
              </div>
              {isDesktop940 && aroundClock()}
            </div>
          </div>
        </>
      );
    return resultJSX();
  };

  const headerText = () => {
    if (result.isResult) return '';
    if (isCreate) return 'Создание правила';
    if (isEdit) return 'Редактирование правила';
    return '';
  };

  return (
    <StyledCreatingRule>
      <SidePage
        show={isShowCreatingRule}
        width="832px"
        headerText={headerText()}
        onCloseClick={zeroing}
        footerContainer={
          !result.isResult &&
          !(
            (isMobileNumberSelectOpen ||
              isMobileConditionSelectOpen ||
              isMobileStartSelectOpen ||
              isMobileEndSelectOpen) &&
            !isDesktop940
          ) && (
            <CreatingRuleFooter
              saveRule={saveRules}
              isLoading={isLoadingSave}
              saveError={saveError}
              deleteRule={deleteRule}
              isLoadingDelete={isLoadingDelete}
              isEdit={isEdit}
              isDisabled={isDisabledSaveRule}
            />
          )
        }
        removeScrollBar
      >
        {content()}
      </SidePage>
    </StyledCreatingRule>
  );
};

export default observer(CreatingRuleSidePage);
