/** libraries */
import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
/** components */
import { defaultTheme, Text, Button } from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';
/** interfaces */
import { ZombieInfoProps } from '../../Subscriptions/interfaces';
/** constants */
import { PAYMENT_SLUG } from '~/constants/common';
import { desktop1280 } from '~/components/Grid/constants';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  height: 100%;
  min-height: 220px;
`;

const CloseToStray: FC<ZombieInfoProps> = ({ daysMax, daysLeft }) => {
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  return (
    <Root>
      <Text color={defaultTheme.colors.white}>
        Демонтаж произойдёт через{' '}
        <Text color={defaultTheme.colors.white} fontWeightBold>
          {daysLeft}
          {'\u00A0'}дней.
        </Text>
        <br />
        {isDesktop1280 && <br />}
        Оператор демонтирует линию связи через{' '}
        <Text color={defaultTheme.colors.white} fontWeightBold>
          {daysMax}
          {'\u00A0'}дней
        </Text>{' '}
        приостановки обслуживания за{'\u00A0'}неуплату.
      </Text>
      <LinkWrapper href={`/${PAYMENT_SLUG}`}>
        <Button>
          {isDesktop1280 ? 'Пополнить лицевой счёт' : 'Пополнить'}
        </Button>
      </LinkWrapper>
    </Root>
  );
};

export default memo(CloseToStray);
